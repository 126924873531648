import { Component, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, Subscription } from 'rxjs';
import { ProgressSpinnerService } from '../../../shared/services/progress-spinner.service';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss'],
})
export class ProgressSpinnerComponent implements OnInit, OnDestroy {
  public loading: boolean = false;
  public loadingSubscription: Subscription;

  constructor(private progressSpinnerService: ProgressSpinnerService) {}

  public ngOnDestroy() {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
  }

  public ngOnInit(): void {
    this.loadingSubscription = this.progressSpinnerService.loadingStatus.pipe(debounceTime(200)).subscribe(value => {
      this.loading = value;
    });
  }
}
