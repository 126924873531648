<div class="modal-header bulkupload-modal">
  <h4
    class="modal-title"
    id="modal-basic-title"
    >Bulk Upload</h4
  >
  <button
    data-testid="bu-dialog-close-button"
    (click)="onClose()"
    aria-label="Close"
    class="close"
    id="close-button"
    type="button"
  >
    <i class="material-icons close-icon">close</i>
  </button>
</div>
<div class="modal-body">
  <span class="bulkupload-text-title pl-0">Drop your file below:</span>
  <div
    (dragover)="onDragOver($event)"
    (drop)="onDropSuccess($event)"
    class="input-grid border-dashed-grey bulk-upload-dialog__upload-container"
    [class.bulk-upload-dialog__upload-container--error]="fileValidationMessage"
  >
    <mat-icon class="text-align-center bulk-upload-dialog__upload-icon">cloud_upload</mat-icon>
    <div class="bulkupload-text-info text-align-center">
      Drag and drop a document or
      <label
        class="link-primary"
        for="filePicker"
        role="button"
      >
        <input
          data-testid="bu-dialog-document-input"
          (change)="onSaveFile($event)"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          hidden="true"
          id="filePicker"
          required
          type="file"
        />
        browse</label
      >
    </div>
  </div>
  <div
    *ngIf="fileValidationMessage"
    class="error-message"
  >
    <mat-icon class="bulk-upload-dialog__error-icon">error</mat-icon> {{ fileValidationMessage }}
  </div>

  <div class="infoblock-color-light">
    <div class="bulk-upload-dialog__info-container">
      <div class="bulk-upload-dialog__info-text"> Download the pre-filled template, make necessary changes and re-upload using option above.            
        <p class="bulk-upload-dialog__generate-option">
          <mat-checkbox
          [attr.data-testid]="'template-download-checkbox'"
          (change)="onEmptyTemplateCheck()"
          [checked]="emptyTemplate"
          class="template-download__checkbox"
        ></mat-checkbox>    
          Generate an empty template.
        </p>
      </div>    
      <button
        data-testid="bu-dialog-download-template-button"
        (click)="onDownloadTemplate()"
        class="bulk-upload-dialog__info-button"
        color="accent"
        mat-flat-button
        type="button"
      >
        <mat-icon>file_download</mat-icon>
        Download Template
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-md-1 bulkupload-text-note"> Note: </div>
    <div class="col-md-11 bulkupload-text-info">
      Use bulk upload to add or edit multiple records at one go. Only one file can be used for every request. Requests would be queued and
      <button
        data-testid="bu-dialog-read-more-button"
        (click)="onReadMore(false)"
        *ngIf="showReadMore"
        class="btn bulkupload-text-note p-0 underline-hover"
        id="btnReadMore"
      >
        Read More...
      </button>
      <p
        *ngIf="!showReadMore"
        class="bulkupload-text-info"
        id="more"
      >
        picked sequentially for processing. If you wish to cancel your request, view the status or check the errors, navigate to the 'Queue' module
        from left navigation.
        <button
          data-testid="bu-dialog-read-less-button"
          (click)="onReadMore(true)"
          class="btn bulkupload-text-note p-0 underline-hover"
          id="btnReadLess"
        >
          Read Less
        </button>
      </p>
    </div>
  </div>
</div>
