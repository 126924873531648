import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { takeUntil, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent extends BaseComponent implements OnInit {
  public isVisible: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private modalService: NgbModal) {
    super();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(
        tap(params => {
          if (params.redirect === 'true') {
            this.navigateToLandingPage();
          } else {
            this.isVisible = true;
          }
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  navigateToLandingPage() {
    window.location.href = environment.config.redirectUri;
  }
}
