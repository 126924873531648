import { Injectable } from '@angular/core';
import { ApiEndPoints } from 'src/app/config/api-end-points';
import { MsApiService } from './ms-api.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private msApiService: MsApiService) {}

  public getClientList(emailId: any, adToken: string, tenantId: string) {
    const options = {
      userEmailAddress: emailId,
      adToken: adToken,
      tenantId: tenantId,
    };
    return this.msApiService.post(ApiEndPoints.LOGIN.clientListForLogin, options);
  }

  public extendUserSession() {
    return this.msApiService.get(ApiEndPoints.LOGIN.extendSession);
  }

  public logoutUserSession() {
    return this.msApiService.get(ApiEndPoints.LOGIN.logoutSession);
  }
}
