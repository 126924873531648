import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgressSpinnerService {
  public loading: boolean = false;
  public loadingStatus: Subject<boolean> = new Subject();

  constructor() {}

  public get Loading(): boolean {
    return this.loading;
  }

  public set Loading(value) {
    this.loading = value;
    this.loadingStatus.next(value);
  }

  public startLoading() {
    this.Loading = true;
  }

  public stopLoading() {
    this.Loading = false;
  }
}
