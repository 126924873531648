import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiResponse } from '../models/response';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class MsApiService {
  private MICROSERVICE_API_URL = environment.key.MICROSERVICE_API_URL;

  constructor(private httpClient: HttpClient, private httpService: HttpService) {}

  public post<T>(url: string, params?: any, addTenantHeader: boolean = false, skipLoader: boolean = false): Observable<ApiResponse<T>> {
    let headers: any = addTenantHeader ? { addTenantHeader: 'true' } : {};
    if (skipLoader) {
      headers.SkipProgressSpinner = 'true';
    }
    return this.httpClient.post<ApiResponse<T>>(this.MICROSERVICE_API_URL + url, params, { headers: headers });
  }

  public get<T>(path: string, params: { [key: string]: string } = null, addTenantHeader: boolean = false, skipLoader: boolean = false) {
    const headers: any = addTenantHeader ? { addTenantHeader: 'true' } : {};
    if (skipLoader) {
      headers.SkipProgressSpinner = 'true';
    }
    if (params != null && Object.keys(params).length > 0) {
      const query = Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      path = `${path}?${query}`;
    }

    return this.httpClient.get<ApiResponse<T>>(`${this.MICROSERVICE_API_URL}${path}`, { headers });
  }

  public getFileData(url: string, param: any, httpOptions: any): Observable<any> {
    return this.httpClient.post<any>(this.MICROSERVICE_API_URL + url, param, httpOptions);
  }

  public postFileData(url: string, param: any, httpOptions: any): Observable<any> {
    return this.httpClient.post<any>(this.MICROSERVICE_API_URL + url, param, httpOptions);
  }

  public getFileDataThroughGet(url: string, httpOptions: any): Observable<any> {
    return this.httpClient.get<any>(this.MICROSERVICE_API_URL + url, httpOptions);
  }

  public cachePost<T>(
    url: string,
    params?: any,
    addTenantHeader: boolean = false,
    skipLoader: boolean = false,
    forceUpdate?: boolean,
  ): Observable<ApiResponse<T>> {
    let cacheHttpClient = this.httpService.cache(forceUpdate);
    let headers: any = addTenantHeader ? { addTenantHeader: 'true' } : {};

    if (skipLoader) {
      headers.SkipProgressSpinner = 'true';
    }

    return cacheHttpClient.post<ApiResponse<T>>(this.MICROSERVICE_API_URL + url, params, { headers: headers });
  }

  public cacheGet<T>(path: string, params: { [key: string]: string } = null, addTenantHeader: boolean = false, forceUpdate?: boolean) {
    let cacheHttpClient = this.httpService.cache(forceUpdate);
    const headers = addTenantHeader ? { addTenantHeader: 'true' } : {};

    if (params != null && Object.keys(params).length > 0) {
      const query = Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      path = `${path}?${query}`;
    }

    return cacheHttpClient.get<ApiResponse<T>>(`${this.MICROSERVICE_API_URL}${path}`, { headers });
  }

  public cleanCache(expirationDate?: Date) {
    this.httpService.cleanCache(expirationDate);
  }

  public cleanCacheByUrl(url: string): void {
    this.httpService.cleanCacheByUrl(url);
  }
}
