import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataAccessGroupSelectDataModel } from 'src/app/modules/admin/data-access-groups/models/data-access-group-select-data.model';
import { DataAccessGroupSelectGuidDataModel } from 'src/app/modules/admin/data-access-groups/models/data-access-group-select-guid-data.model';
import { ICalendarConfigurationVersions } from 'src/app/modules/admin/master-data-setup/interfaces/calendar-configuration-versions.interface';

@Component({
  selector: 'app-common-item-list',
  templateUrl: './common-item-list.component.html',
  styleUrls: ['./common-item-list.component.scss'],
})
export class CommonItemListComponent {
  @Input() list: Array<DataAccessGroupSelectDataModel | DataAccessGroupSelectGuidDataModel | ICalendarConfigurationVersions>;

  @Input() disabled: boolean;

  @Input() keyList: Array<string>;

  @Input() searchValue: string;

  @Input() descriptionIcon: string;

  @Input() keyId: string;

  @Input() removeable: boolean;

  @Input() showStatus: boolean;

  @Input() selectable: boolean;

  @Input() activeIndex: number;

  @Input() ellipsis: number;

  @Output() removeClick = new EventEmitter<boolean>();

  @Output() itemClick = new EventEmitter<DataAccessGroupSelectDataModel | DataAccessGroupSelectGuidDataModel | ICalendarConfigurationVersions>();

  remove(item): void {
    let index = this.list.findIndex(element => item[this.keyId] == element[this.keyId]);
    this.list.splice(index, 1);
    this.removeClick.emit();
  }

  select(item, index): void {
    if (this.selectable) {
      this.activeIndex = index;
      this.itemClick.emit(item);
    }
  }
}
