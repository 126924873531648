import { CustomAttributeFilterResponseCollection } from '../../../shared/models/filter';
import { FilterResponseCollection } from '../../../shared/models/filter';

export interface GetServiceSubscriptionFiltersQueryResponse {
  serviceSubscriptionLocalDeliverableFilters: FilterResponseCollection<number>;
  serviceSubscriptionEngagementFilters: FilterResponseCollection<number>;
  serviceSubscriptionTypeFilters: FilterResponseCollection<number>;
  serviceSubscriptionEntityLegalNameFilters: FilterResponseCollection<number>;
  serviceSubscriptionCountryOfEstablishmentFilters: FilterResponseCollection<number>;
  serviceSubscriptionFrequencyFilters: FilterResponseCollection<number>;
  serviceSubscriptionStatusFilters: FilterResponseCollection<number>;
  serviceSubscriptionCustomAttributeFiltersCollections: CustomAttributeFilterResponseCollection<number>[];
}
