import {
  CommonFilter,
  CustomAttributeDateFilterParameters,
  CustomAttributeFilterParameters,
  CustomAttributeFilterResponse,
  CustomAttributeFilterResponseCollection,
  FilterData,
  FilterResponse,
  FilterResponseCollection,
} from '../models/filter';
import { AdminFilterResponse } from '../models/filter/admin-filter-response.model';
import { CustomAttributeFilterField } from '../enums/custom-attribute-filter-field.enum';
import { FilterDomain } from '../enums/filter-domain.enum';
import { FilterLabel } from '../enums/filter-label.enum';
import { FilterLogicOperator } from '../enums/filter-logic-operator.enum';
import { FilterOperator } from '../enums/filter-operator.enum';
import { FilteredDateItemsDataModel } from '../../core/model/filtered-date-items-data.model';
import { FilteredStandardItemsDataModel } from '../../core/model/filtered-standard-items-data.model';
import { Injectable } from '@angular/core';
import { QueryFilter, QueryFilterComplex, QueryFilterRule } from '../models/request';
import { CustomAttributesService } from './custom-attributes.service';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  constructor(private customAttributeService: CustomAttributesService) {}

  /**
   * @depracated If overview component uses this method then it means that BE payload is obsolete and should be adjusted
   */
  public adminFilterResponseToCommonFilter(response: AdminFilterResponse): CommonFilter {
    return {
      filterCatalog: response.filterCatalog,
      filterID: response.parameterValue,
    };
  }

  public applyDateFilters(dateFilterItems: FilteredDateItemsDataModel, filter: QueryFilter): void {
    for (const displayNameKey in dateFilterItems) {
      const attributeParameters: CustomAttributeDateFilterParameters[] = dateFilterItems[displayNameKey];
      attributeParameters.forEach((parameter: CustomAttributeDateFilterParameters) => {
        if (parameter.startDate != null && parameter.endDate != null) {
          const startDateRules: QueryFilterRule[] = this.prepareQueryFilterRules(
            CustomAttributeFilterField.StartDate,
            [parameter.startDate.format('YYYY-MM-DD')],
            FilterOperator.GreaterThanOrEqual,
          );
          const endDateRules: QueryFilterRule[] = this.prepareQueryFilterRules(
            CustomAttributeFilterField.EndDate,
            [parameter.endDate.format('YYYY-MM-DD')],
            FilterOperator.LowerThanOrEqual,
          );
          const fieldRules: QueryFilterRule[] = this.prepareQueryFilterRules(CustomAttributeFilterField.DisplayName, [parameter.filterType]);

          this.saveQueryFilterRules(filter, [...startDateRules, ...endDateRules, ...fieldRules], FilterLogicOperator.And);
        }
      });
    }
  }

  public applyStandardCustomAttributeFilters(filter: QueryFilter, data: FilteredStandardItemsDataModel): void {
    const ids: string[] = [];
    let filters: CustomAttributeFilterParameters[];

    Object.keys(data).forEach((key: string) => {
      if ((filters = data[key]).length !== 0) {
        ids.push(
          ...filters.map(filter => {
            return +filter.customAttributeValueID < 0 ? '0' : filter.customAttributeValueID;
          }),
        );
      }
    });

    this.saveMultipleFilterRules(filter, [this.prepareQueryFilterRules(CustomAttributeFilterField.Value, ids)]);
  }

  public applyTextSearchFilter(fieldName: string, filter: QueryFilter, searchText: string): void {
    if (searchText?.trim()) {
      this.saveMultipleFilterRules(filter, [this.prepareQueryFilterRules(fieldName, [searchText], FilterOperator.Contains)]);
    }
  }

  public applyTextSearchFilterFromQuery(fieldName: string, filter: QueryFilter, searchTextFilter: QueryFilterComplex): void {
    const searchText: string = (searchTextFilter?.filterRules[0].value as string) ?? null;
    this.applyTextSearchFilter(fieldName, filter, searchText);
  }

  public filterResponseToCommonFilter(response: FilterResponse<any> | CustomAttributeFilterResponse<any>): CommonFilter {
    return {
      filterCatalog: response.filterLabel,
      filterID: response.filterValue,
    };
  }

  public findQueryFilterComplexByFieldName(filter: QueryFilter, fieldName: string): QueryFilterComplex {
    return filter.filterComplexes?.find((complexes: QueryFilterComplex) =>
      complexes.filterRules.find((rule: QueryFilterRule) => rule.field === fieldName),
    );
  }

  public filterQueryFilterCustomAttributesOnly(filter: QueryFilter): QueryFilter {
    const caFilters: string[] = Object.values(CustomAttributeFilterField);
    const complex = filter.filterComplexes?.filter((complexes: QueryFilterComplex) =>
      complexes.filterRules.find((rule: QueryFilterRule) => caFilters.includes(rule.field)),
    );

    return complex ? { logicOperator: filter.logicOperator, filterComplexes: complex } : null;
  }

  public prepareCustomAttributeFilterData(collections: CustomAttributeFilterResponseCollection<number>[]): FilterData[] {
    return collections.map((collection: CustomAttributeFilterResponseCollection<number>) => {
      let i: number = 0;
      const filterData: FilterData = {
        type: collection.customAttributeControlType,
        isDate: collection.customAttributeControlType === 'Date',
        label: collection.filterCatalogGroup,
        data: collection.customAttributeFilterResponses?.map(response => this.filterResponseToCommonFilter(response)),
      };

      filterData.data = filterData.data?.map((filter: CommonFilter) => ({
        ...filter,
        filterID: filter.filterID === '0' ? `${--i}` : filter.filterID,
      }));

      return filterData;
    });
  }

  /**
   * @depracated We don't have unified filter payload, it's better to create standard filter fields manually
   */
  public prepareFilerData(
    type: string,
    label: FilterLabel,
    data: FilterResponseCollection<any>,
    defaultValue: string = null,
    domain: FilterDomain = null,
  ): FilterData {
    const customAttributesList = this.customAttributeService?.customAttributes;
    const isHidden = Array.isArray(customAttributesList) && customAttributesList.some(x => x.key === `${domain}/${label}` && x.isHidden);
    return {
      type: type,
      label: label,
      data: data.filterResponses.map(response => this.filterResponseToCommonFilter(response)),
      defaultValue: defaultValue,
      domain: domain,
      isHidden: isHidden,
    };
  }

  public prepareQueryFilterRules(fieldName: string, data: any[], operator: FilterOperator = FilterOperator.Equal): QueryFilterRule[] {
    if (!data) {
      return [];
    }

    return data.map((value: string) => ({
      field: fieldName,
      value: value,
      operator: operator,
    }));
  }

  public saveMultipleFilterRules(
    filter: QueryFilter,
    rulesCollection: QueryFilterRule[][],
    operator: FilterLogicOperator = FilterLogicOperator.Or,
  ): void {
    if (!rulesCollection || rulesCollection.length === 0) {
      return;
    }

    rulesCollection.forEach((rules: QueryFilterRule[]) => this.saveQueryFilterRules(filter, rules, operator));
  }

  /**
   * @depracated Use saveMultipleFilterRules()
   */
  public saveQueryFilterRules(filter: QueryFilter, rules: QueryFilterRule[], operator: FilterLogicOperator = FilterLogicOperator.Or): void {
    if (!rules || rules.length === 0) {
      return;
    }

    filter.filterComplexes.push({
      logicOperator: operator,
      filterRules: rules,
    });
  }

  public switchOrSaveSingleRuleValue(
    filter: QueryFilter,
    fieldName: string,
    value: any[],
    filterOperator: FilterOperator = FilterOperator.Equal,
  ): void {
    const result: QueryFilterComplex = this.findQueryFilterComplexByFieldName(filter, fieldName);

    if (!result) {
      const hierarchyRules: QueryFilterRule[] = this.prepareQueryFilterRules(fieldName, value, filterOperator);
      this.saveQueryFilterRules(filter, hierarchyRules);
    } else {
      result.filterRules[0].value = value[0];
    }
  }
}
