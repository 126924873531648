import { Component, DoCheck, Input, ViewChild } from '@angular/core';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { DataAccessGroupSelectDataModel } from 'src/app/modules/admin/data-access-groups/models/data-access-group-select-data.model';
import { DataAccessGroupSelectGuidDataModel } from 'src/app/modules/admin/data-access-groups/models/data-access-group-select-guid-data.model';
import { FilterPipe } from '../../pipe/filter-list.pipe';
import { ServiceSubscriptionIrModel } from 'src/app/modules/portal/subscription/interfaces/service-subscription-ir.interface';
import { ActiveInactiveStatus } from '../../constants/active-inactive-status.enum';

@Component({
  selector: 'app-selection-item-list',
  templateUrl: './selection-item-list.component.html',
  styleUrls: ['./selection-item-list.component.scss'],
  providers: [FilterPipe],
})
export class SelectionItemListComponent implements DoCheck {
  @Input() list: Array<DataAccessGroupSelectDataModel | DataAccessGroupSelectGuidDataModel | ServiceSubscriptionIrModel>;

  @Input() disabled: boolean;

  @Input() keyList: Array<string>;

  @Input() searchValue: string;

  @Input() descriptionIcon: string[];

  @Input() keyId: string;

  @Input() showStatus: boolean;

  @ViewChild('selection') public selection: MatSelectionList;
  @ViewChild('all') private all: MatListOption;

  private selectedItems: any = [];
  public readonly defaultStatusName: string = 'New';
  public readonly activeInactiveStatus: typeof ActiveInactiveStatus = ActiveInactiveStatus;

  constructor(private filterPipe: FilterPipe) {}

  ngDoCheck() {
    if (this.selection && this.selectedItems.length > 0) {
      if (this.all) {
        this.all.selected = false;
        let selectedLength = this.selection.selectedOptions.selected.filter(e => e.value).length;
        let filteredLength = this.filterPipe.transform(this.list, this.searchValue, this.keyList).length;
        if (filteredLength && selectedLength == filteredLength) {
          this.all.selected = true;
        }
      }
      this.selection._value = this.selectedItems.map(e => e.value);
    }
  }

  public onClickOption(listItem: MatListOption): void {
    if (this.all) {
      this.all.selected = false;
      if (this.selection.selectedOptions.selected.filter(e => e.value).length == this.list.length) {
        this.all.selected = true;
      }
    }
    this.selectItem(listItem);
  }

  public onClickAll(): void {
    if (this.all.selected) {
      this.selection.selectAll();
    } else {
      this.selection.deselectAll();
    }
    this.selection.options.filter(e => e.value).forEach(item => this.selectItem(item));
  }

  private selectItem(listItem: MatListOption) {
    let index = this.selectedItems.findIndex(item => JSON.stringify(item.value) === JSON.stringify(listItem.value));
    if (listItem.selected && index === -1) {
      this.selectedItems.push(listItem);
    } else if (!listItem.selected) {
      this.selectedItems.splice(index, 1);
    }
  }
}
