import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'disableEntityStatus',
})
export class DisableEntityStatusPipe implements PipeTransform {
  transform(status: string, entityStatus: string, transitionMapping: { [key in string]: string[] }): boolean {
    return !transitionMapping[entityStatus]?.includes(status);
  }
}
