export enum FilterOperator {
  Equal = 0,
  NotEqual = 1,
  LowerThan = 2,
  LowerThanOrEqual = 3,
  GreaterThan = 4,
  GreaterThanOrEqual = 5,
  StartsWith = 6,
  EndsWith = 7,
  Contains = 8,
  NotContains = 9,
}
