import { Directive, HostListener, Input, ElementRef, Renderer2, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appDisableButton]',
})
export class DisableButtonDirective implements OnDestroy {
  @Input() disableTime?: number = 3000;
  @Input() disablePermanently?: boolean = false;

  private isDisabled: boolean = false;
  private timeoutId: any;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  public ngOnDestroy(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  private setRenderer = (disable: boolean) => this.renderer.setProperty(this.el.nativeElement, 'disabled', disable);

  @HostListener('keydown.enter', ['$event'])
  @HostListener('click', ['$event'])
  public onClick(event: Event) {
    this.preventMultipleInvokes();
  }

  private preventMultipleInvokes() {
    if (this.isDisabled) {
      return;
    }

    const disableDuration = this.disableTime;
    this.isDisabled = true;
    this.setRenderer(this.isDisabled);

    if (this.disablePermanently) {
      return;
    }

    this.timeoutId = setTimeout(() => {
      this.isDisabled = !this.isDisabled;
      this.setRenderer(this.isDisabled);
    }, disableDuration);
  }
}
