import { ActiveInactivePipe } from './pipe/active-inactive.pipe';
import { BulkUploadDialogComponent } from './components/bulk-upload-dialog/bulk-upload-dialog.component';
import { ButtonClearOptionDirective } from './directives/button-clear-option.directives';
import { ChangeStatusModalComponent } from './components/change-status-modal/change-status-modal.component';
import { ChipsInputComponent } from './components/chips-input/chips-input.component';
import { ComboboxInputComponent } from './components/combobox-input/combobox-input.component';
import { CommonItemListComponent } from './components/common-item-list/common-item-list.component';
import { CommonModule } from '@angular/common';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { ConfirmDeleteModalComponent } from './components/confirm-delete-modal/confirm-delete-modal.component';
import { ConfirmEditModalComponent } from './components/confirm-edit-modal/confirm-edit-modal.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { CountSortComponent } from './components/count-sort/count-sort.component';
import { CrDetailsComponent } from './components/cr-details/cr-details.component';
import { CustomAttributesAccessorComponent } from './components/custom-attributes-accessor/custom-attributes-accessor.component';
import { CustomParametersComponent } from './components/custom-parameters/custom-parameters.component';
import { DataGridComponent } from './components/data-grid/data-grid.component';
import { DataSourceAndSinkComponent } from './components/data-source-and-sink/data-source-and-sink.component';
import { DeleteActionDirective } from './directives/delete-action.directive';
import { DisableButtonDirective } from './directives/disable-button.directive';
import { DisableControlDirective } from './directives/disable-control.directive';
import { DisableEntityStatusPipe } from './pipe/entity-status-option.pipe';
import { DropdownListComponent } from './components/lazy-loaded-dropdown/dropdown-list.component';
import { DueDateTextPipe } from './pipe/due-date-text.pipe';
import { EditActionDirective } from './directives/edit-action.directive';
import { EnabledModulePipe } from './pipe/enabled-module.pipe';
import { ExportButtonComponent } from './components/export-button/export-button.component';
import { ExportButtonOptionComponent } from './components/export-button-option/export-button-option.component';
import { ExtendSessionModalComponent } from './components/extend-session-modal/extend-session-modal.component';
import { FilterByAttributeTypeIdPipe } from './pipe/filter-by-attribute-type-id.pipe';
import { FilterComponent } from './components/filter/filter.component';
import { FilterCountTitlePipe } from './pipe/filter-count-title.pipe';
import { FilterPipe } from './pipe/filter-list.pipe';
import { FormattedDatePipe } from './pipe/formatted-date.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { InputPrefixDirective } from './directives/input-prefix.directive';
import { IsHiddenDirective } from './directives/is-hidden.directives';
import { KillSessionModalComponent } from './components/kill-session-modal/kill-session-modal.component';
import { ListItemComponent } from './components/list-item/list-item.component';
import { MaterialModule } from './material.module';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { NgModule } from '@angular/core';
import { OverviewSearchComponent } from './components/overview-search/overview-search.component';
import { PageContentComponent } from './components/page-content/page-content.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PageOverviewComponent } from './components/page-overview/page-overview.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { RouterModule } from '@angular/router';
import { SearchableDropdownComponent } from './components/searchable-dropdown/searchable-dropdown.component';
import { SearchItemListComponent } from './components/search-item-list/search-item-list.component';
import { SelectedFiltersPipe } from './pipe/selected-filters.pipe';
import { SelectionItemListComponent } from './components/selection-item-list/selection-item-list.component';
import { SelectListDialogComponent } from './components/select-list-dialog/select-list-dialog.component';
import { SelectListIrsDialogComponent } from './components/select-list-irs-dialog/select-list-irs-dialog.component';
import { SessionModalComponent } from './components/session-modal/session-modal.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { TranslateModule } from '@ngx-translate/core';
import { UnicodeValidatorDirective } from './directives/unicode-validation.directive';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { SkeletonDirective } from './directives/skeleton.directive';
import { SkeletonComponent } from './components/skeleton/skeleton.component';
import { CancelActionDirective } from './directives/cancel-action.directive';

@NgModule({
  declarations: [
    ActiveInactivePipe,
    BulkUploadDialogComponent,
    ButtonClearOptionDirective,
    CancelActionDirective,
    ChangeStatusModalComponent,
    ChipsInputComponent,
    ComboboxInputComponent,
    CommonItemListComponent,
    ConfirmationModalComponent,
    ConfirmDeleteModalComponent,
    ConfirmEditModalComponent,
    ConfirmModalComponent,
    ContextMenuComponent,
    CountSortComponent,
    CrDetailsComponent,
    CustomAttributesAccessorComponent,
    CustomParametersComponent,
    CustomParametersComponent,
    DataGridComponent,
    DataSourceAndSinkComponent,
    DeleteActionDirective,
    DisableButtonDirective,
    DisableControlDirective,
    DisableEntityStatusPipe,
    DropdownListComponent,
    DueDateTextPipe,
    EditActionDirective,
    EnabledModulePipe,
    ExportButtonComponent,
    ExportButtonOptionComponent,
    ExtendSessionModalComponent,
    FilterByAttributeTypeIdPipe,
    FilterComponent,
    FilterCountTitlePipe,
    FilterPipe,
    FormattedDatePipe,
    HasPermissionDirective,
    InputPrefixDirective,
    IsHiddenDirective,
    KillSessionModalComponent,
    ListItemComponent,
    OverviewSearchComponent,
    PageContentComponent,
    PageHeaderComponent,
    PageOverviewComponent,
    PageTitleComponent,
    SearchableDropdownComponent,
    SearchItemListComponent,
    SelectedFiltersPipe,
    SelectionItemListComponent,
    SelectListDialogComponent,
    SelectListIrsDialogComponent,
    SessionModalComponent,
    SkeletonComponent,
    SkeletonDirective,
    SpinnerComponent,
    ToggleComponent,
    UnicodeValidatorDirective,
    ValidationMessageComponent,
  ],
  exports: [
    BulkUploadDialogComponent,
    ButtonClearOptionDirective,
    CancelActionDirective,
    ChangeStatusModalComponent,
    CommonItemListComponent,
    CommonModule,
    ConfirmationModalComponent,
    ConfirmDeleteModalComponent,
    ConfirmEditModalComponent,
    ConfirmModalComponent,
    ContextMenuComponent,
    CountSortComponent,
    CrDetailsComponent,
    CustomAttributesAccessorComponent,
    CustomParametersComponent,
    DataGridComponent,
    DataSourceAndSinkComponent,
    DeleteActionDirective,
    DisableButtonDirective,
    DisableControlDirective,
    DisableEntityStatusPipe,
    DropdownListComponent,
    DueDateTextPipe,
    EditActionDirective,
    EnabledModulePipe,
    ExportButtonComponent,
    ExtendSessionModalComponent,
    FilterComponent,
    FilterPipe,
    FormattedDatePipe,
    FormsModule,
    HasPermissionDirective,
    InputPrefixDirective,
    IsHiddenDirective,
    ListItemComponent,
    MaterialModule,
    OverviewSearchComponent,
    PageContentComponent,
    PageHeaderComponent,
    PageOverviewComponent,
    PageTitleComponent,
    SearchableDropdownComponent,
    SearchItemListComponent,
    SelectionItemListComponent,
    SelectListDialogComponent,
    SelectListIrsDialogComponent,
    SessionModalComponent,
    SkeletonComponent,
    SkeletonDirective,
    SpinnerComponent,
    ToggleComponent,
    UnicodeValidatorDirective,
    ValidationMessageComponent,
  ],
  imports: [CommonModule, FormsModule, InfiniteScrollModule, MaterialModule, MomentDateModule, ReactiveFormsModule, RouterModule, TranslateModule],
})
export class SharedModule {}
