<mat-form-field>
  <mat-label data-testid="custom-attributes-combo-box-label">{{ this.label }}</mat-label>
  <input
    type="text"
    matInput
    [required]="required"
    [matAutocomplete]="auto"
    [formControl]="formControl"
    data-testid="custom-attributes-combo-box-formcontrol"
  />
  <mat-autocomplete
  autoActiveFirstOption
  #auto="matAutocomplete"
  >
  <ng-container *ngIf="!formControl.disabled">
      <mat-option
        *ngFor="let opt of filteredOptions$ | async; trackBy: trackById"
        [value]="opt.value"
        [title]="opt.id"
        >
        {{ opt.value }}
      </mat-option>
    </ng-container>
    </mat-autocomplete>
  <mat-error>
    <app-validation-message [field]="this.formControl"></app-validation-message>
  </mat-error>
</mat-form-field>
