<app-overview-search
  (searchByString)="onSearch($event)"
  [maxLength]="searchMaxLength"
  [searchPlaceHolder]="searchPlaceHolder"
  *ngIf="showSearchField"
  data-testid="overview-search"
>
</app-overview-search>

<app-count-sort
  (selectedSortingDetails)="onSort($event)"
  [gridCount]="dataSource.data?.length"
  [isSortRequired]="isSortable"
  [sortDefaultAction]="sortDefaultAction"
  [totalCount]="dataSource.total"
  class="data-grid__count-sort"
  data-testid="count-sort"
>
</app-count-sort>

<div
  (scrolled)="onScroll()"
  [alwaysCallback]="true"
  [infiniteScrollDisabled]="infiniteScrollDisabled"
  [infiniteScrollDistance]="scrollDistance"
  [infiniteScrollThrottle]="200"
  [scrollWindow]="false"
  class="data-grid__scroll-surface"
  data-testid="data-grid"
  infinite-scroll
>
  <ng-container *ngFor="let dataSourceItem of dataSource.data; let listItemIndex = index">
    <ng-container
      [ngTemplateOutlet]="itemTemplate"
      [ngTemplateOutletContext]="{ $implicit: dataSourceItem, index: listItemIndex }"
    ></ng-container>
  </ng-container>
</div>
