import { BaseComponent } from '../base-component';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HttpStatusCode } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil, tap } from 'rxjs';
import { ToastService } from '../../services/toast.service';
import { UploadFileService } from '../../services/upload-file-service';
import { ValidationPattern } from '../../constants/ValidationPattern';

@Component({
  selector: 'app-bulk-upload-dialog',
  templateUrl: './bulk-upload-dialog.component.html',
  styleUrls: ['./bulk-upload-dialog.component.scss'],
})
export class BulkUploadDialogComponent extends BaseComponent {
  @Input() public featureName: string;
  @Output() public downloadTemplate: EventEmitter<void> = new EventEmitter<void>();

  public errorMessage: string;
  public fileValidationMessage: string = '';
  public showReadMore: boolean = true;

  constructor(private uploadFileService: UploadFileService, private toastService: ToastService, private activeModal: NgbActiveModal) {
    super();
  }

  public onClose() {
    this.activeModal.dismiss();
  }

  public onDownloadTemplate() {
    this.downloadTemplate.emit();
  }

  public onDragOver(event: any) {
    event.preventDefault();
  }

  public onDropSuccess(event: any) {
    event.preventDefault();
    this.processFile(event.dataTransfer.files[0]);
  }

  public onReadMore(shouldShow: boolean) {
    this.showReadMore = shouldShow;
  }

  public onSaveFile(event: any): void {
    this.processFile(event.target.files[0]);

    if (event.target instanceof HTMLInputElement) {
      event.target.value = '';
      event.target.files = null;
    }
  }

  public validateSelectedFile(file: any): boolean {
    const { name: fileName, size: fileSize } = file;
    const fileSizeInMb = fileSize / 1024 ** 2;
    const fileExtension = fileName.split('.').pop();

    this.fileValidationMessage = '';

    if (this.hasMultipleExtensions(fileName)) {
      this.fileValidationMessage = 'Multiple file extensions are not allowed. Please select a file with only one extension.';
      return false;
    }

    if (!this.checkFileName(fileName)) {
      this.fileValidationMessage = 'Invalid characters detected. Only these special characters are allowed: _ -.';
      return false;
    }

    const allowedExtensions = ['xlsx'];

    if (!allowedExtensions.includes(fileExtension)) {
      this.fileValidationMessage = 'File type not allowed.';
      return false;
    }

    const fileSizeLimit = 50;

    if (fileSizeInMb > fileSizeLimit) {
      this.fileValidationMessage = 'Uploaded file exceeds the allowed file size of 50MB';
      return false;
    }

    return true;
  }

  private checkFileName(fileName: string): boolean {
    const regex = ValidationPattern.UploadFileNameAllowedCharacters;
    return regex.test(fileName);
  }

  private hasMultipleExtensions(fileName: string): boolean {
    return fileName.split('.').length > 2;
  }

  private processFile(file: any) {
    if (this.validateSelectedFile(file)) {
      this.uploadFileService
        .uploadFile(file, this.featureName)
        .pipe(
          tap((result: any) => {
            if (result.statusCode === HttpStatusCode.Ok) {
              this.errorMessage = 'Success';
              this.toastService.showSuccess('File uploaded successfully.');
            }
          }),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe();
    } else {
      this.toastService.showError(this.fileValidationMessage);
    }
  }
}
