import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExportOption } from '../../models/export-file';

@Component({
  selector: 'app-export-button-option',
  templateUrl: './export-button-option.component.html',
  styleUrls: ['./export-button-option.component.scss'],
})
export class ExportButtonOptionComponent {
  @Input()
  public option: ExportOption;

  @Output()
  public optionChange = new EventEmitter<ExportOption>();
}
