import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataAccessGroupSelectDataModel } from 'src/app/modules/admin/data-access-groups/models/data-access-group-select-data.model';
import { DataAccessGroupSelectGuidDataModel } from 'src/app/modules/admin/data-access-groups/models/data-access-group-select-guid-data.model';

@Component({
  selector: 'app-common-item-list',
  templateUrl: './common-item-list.component.html',
  styleUrls: ['./common-item-list.component.scss'],
})
export class CommonItemListComponent {
  @Input() list: Array<DataAccessGroupSelectDataModel | DataAccessGroupSelectGuidDataModel>;

  @Input() disabled: boolean;

  @Input() keyList: Array<string>;

  @Input() searchValue: string;

  @Input() descriptionIcon: string;

  @Input() keyId: string;

  @Input() removeable: boolean;

  @Output() removeClick = new EventEmitter<boolean>();

  remove(item): void {
    let index = this.list.findIndex(element => item[this.keyId] == element[this.keyId]);
    this.list.splice(index, 1);
    this.removeClick.emit();
  }
}
