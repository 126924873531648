import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { EncryptionService } from 'src/app/shared/services/encryption-service';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService extends StorageService {
  private storageObject = sessionStorage;

  constructor(encryptionService: EncryptionService) {
    super(encryptionService);
  }

  protected get storage(): Storage {
    return this.storageObject;
  }
}
