export enum Privileges {
  create = 'create',
  read = 'read',
  update = 'update',
  delete = 'delete',
  bulkUpload = 'bulkUpload',
  fileExport = 'fileExport',
  allTenantsRead = 'allTenantsRead',
  gatherIrCreate = 'gatherIrCreate',
}
