import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { BaseComponent } from '../base-component';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true,
    },
  ],
})
export class ToggleComponent extends BaseComponent implements ControlValueAccessor {
  @Input() public title: string;
  @Input() public leftLabel: string;
  @Input() public rightLabel: string;
  public onTouched: any;
  toggleControl: FormControl = new FormControl();

  public registerOnChange(fn: any): void {
    this.toggleControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(fn);
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(formValue: string): void {
    this.toggleControl.setValue(formValue);
  }

  public validate() {
    return this.toggleControl.valid ? null : { invalid: true };
  }

  public setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.toggleControl.disable();
    } else {
      this.toggleControl.enable();
    }
  }
}
