import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Pagination, SortBy } from '../../../config/constant';
import { DataSourceResult } from '../../models/response';

@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss'],
})
export class DataGridComponent {
  @ContentChild('itemTemplate') public itemTemplate: TemplateRef<any>;
  @Input() public dataSource: DataSourceResult<any>;
  @Input() public infiniteScrollDisabled: boolean = false;
  @Input() public isSortable: boolean = true;
  @Input() public scrollDistance: number = 2;
  @Input() public searchMaxLength: number = 50;
  @Input() public searchPlaceHolder: string;
  @Input() public searchValidationPattern: RegExp;
  @Input() public showSearchField: boolean = true;
  @Input() public skip: number = Pagination.skip;
  @Input() public sortDefaultAction: string;
  @Input() public take: number = Pagination.skip;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public scroll: EventEmitter<number> = new EventEmitter<number>();
  @Output() public search: EventEmitter<string> = new EventEmitter<string>();
  @Output() public sort: EventEmitter<SortBy> = new EventEmitter<SortBy>();

  public onScroll(): void {
    const skip = this.skip + this.take;
    if (skip <= this.dataSource.total) {
      this.scroll.emit(skip);
    }
  }

  public onSearch(event: string): void {
    this.search.emit(event);
  }

  public onSort(event: SortBy): void {
    this.sort.emit(event);
  }
}
