import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../base-component';
import { IConfirmationModalOptions } from '../../models/modal';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent extends BaseComponent {
  @Input()
  public modal;
  @Input()
  public options: IConfirmationModalOptions;

  @Output()
  public confirm = new EventEmitter<any>();

  constructor() {
    super();
  }

  public onConfirmClick() {
    this.confirm.emit(this.options.data);
    this.modal.dismiss('Cross click');
  }
}
