<div
  class="modal-header bulkupload-modal confirmation-modal confirm-modal__header"
  id="confirmationModal"
>
  <button
    data-testid="confirmation-close-button"
    (click)="modal.dismiss('Cross click')"
    aria-label="Close"
    class="close confirm-modal__close-button"
    type="button"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="modal-body confirm-modal__body">
  <p class="confirm-modal__info"> Are you sure you wish to delete this bulk upload request? </p>
  <p class="confirm-modal__warn-message"> Note: This operation CANNOT be undone. </p>
</div>
<div class="modal-footer confirm-modal__footer">
  <button
    data-testid="confirmation-confirm-button"
    (click)="deleteItem()"
    color="accent"
    mat-flat-button
    type="button"
  >
    Yes, I am sure
  </button>
  <button
    data-testid="confirmation-cancel-button"
    (click)="modal.dismiss('Cross click')"
    color="primary"
    mat-flat-button
    type="button"
  >
    Cancel
  </button>
</div>
