import { Injectable } from '@angular/core';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  appInsights: ApplicationInsights;

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.insights.instrumentationKey,
      },
    });
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
  }

  public logPageView(name?: string, url?: string) {
    this.appInsights.trackPageView({
      name: name,
      uri: url,
    });
  }

  public logEvent(name: string, properties?: { [key: string]: any }) {
    console.log('error', properties);
    this.appInsights.trackEvent({ name: name }, properties);
  }

  public logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  public logException(exception: Error) {
    if (typeof exception === 'string') {
      this.appInsights.trackException({ exception: new Error(exception), severityLevel: SeverityLevel.Error });
    } else if (exception) {
      this.appInsights.trackException({ exception: exception, severityLevel: SeverityLevel.Error });
    }
  }

  public logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }
}
