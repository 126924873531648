import { Directive, Input, SimpleChanges, TemplateRef, ViewContainerRef, OnChanges } from '@angular/core';
import { SkeletonComponent } from '../components/skeleton/skeleton.component';

/*
<div class="flex">
  <p>Some data:</p>
  <p *appSkeleton="isLoading; 
                height: '20px'; 
                width: '200px'">
    {{ data.foo }}
  </p>
</div>

<section class="flex">
  <ng-container *appSkeleton="isLoading; 
                           repeat: 5; 
                           className: 'mb-2'; 
                           height: '100px';">
    <div *ngFor="let num of [1,2, 3]">
      {{ num }}
    </div>
  </ng-container>
</section>

<ng-container *appSkeleton="isLoading; 
                         repeat: 5; 
                         className: 'mb-2'; 
                         height: '30px'; 
                         width: 'rand'">
  <div *ngFor="let num of [1,2, 3, 4, 5]">
    {{ num }}
  </div>
</ng-container>
*/

/* eslint-disable @angular-eslint/no-input-rename */
@Directive({ selector: '[appSkeleton]' })
export class SkeletonDirective implements OnChanges {
  @Input('appSkeletonClassName') public className: string;
  @Input('appSkeletonHeight') public height: string;
  @Input('appSkeleton') public isLoading = false;
  @Input('appSkeletonRepeat') public size = 1;
  @Input('appSkeletonWidth') public width: string;

  constructor(private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.isLoading) {
      this.viewContainerRef.clear();

      if (changes.isLoading.currentValue) {
        Array.from({ length: this.size }).forEach(() => {
          const ref = this.viewContainerRef.createComponent(SkeletonComponent);

          Object.assign(ref.instance, {
            width: this.width === 'rand' ? `${random(30, 90)}%` : this.width,
            height: this.height,
            className: this.className,
          });
        });
      } else {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    }
  }
}

function random(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
