import { EncryptionService } from 'src/app/shared/services/encryption-service';

export abstract class StorageService {
  constructor(private encryptionService: EncryptionService) {}

  protected abstract get storage(): Storage;

  public clear() {
    this.storage.clear();
  }

  public containsKey(key: string): boolean {
    return this.storage.getItem(key) !== null;
  }

  public getItem(key: string): string | null {
    return JSON.parse(this.encryptionService.decrypt(this.storage.getItem(key)));
  }

  public getItemAsObject<T>(key: string): T | null {
    try {
      const encryptedItem = this.storage.getItem(key);
      if (!encryptedItem) {
        return null;
      }
      const decryptedItem = this.encryptionService.decrypt(encryptedItem);
      return JSON.parse(decryptedItem) as T;
    } catch (error) {
      return null;
    }
  }

  public removeItem(key: string) {
    this.storage.removeItem(key);
  }

  public setItem(key: string, valueObject: any) {
    this.storage.setItem(key, this.encryptionService.encrypt(JSON.stringify(valueObject)));
  }
}
