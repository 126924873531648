import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { SessionService } from 'src/app/shared/services/session.service';
import { ApiEndPoints } from '../../config/api-end-points';
import { MsApiService } from '../../shared/services/ms-api.service';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  constructor(private sessionService: SessionService, private msApiService: MsApiService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.sessionService.resetTimeout();

    if (request.url.includes('rbac')) {
      return this.msApiService.get(ApiEndPoints.USER_SESSION.healthCheck).pipe(switchMap(() => next.handle(request)));
    }

    return next.handle(request);
  }
}
