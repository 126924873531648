import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InputValidationService {
  constructor() {}

  public alphanumericWithSpace = (text: string): boolean => {
    const pattern = /^[ A-Za-z0-9]*$/;

    return pattern.test(text);
  };

  public decimalOnly = (text: string): boolean => {
    const pattern = /^[-]?\d*[.]?\d*$/;

    return pattern.test(text);
  };
}
