import { Directive, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { startWith, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appButtonClearOption]',
})
export class ButtonClearOptionDirective implements OnInit, OnDestroy {
  private subscription: Subscription;

  @HostBinding('style.display') public display: string = 'none';
  @Input('appButtonClearOption') public formControlToClear: FormControl;

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    event.stopPropagation();
    this.formControlToClear.setValue(null);
    this.formControlToClear.markAsTouched();
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public ngOnInit(): void {
    this.subscription = this.formControlToClear.valueChanges
      .pipe(
        startWith(this.formControlToClear.value),
        tap(value => {
          this.display = this.shouldDisplay(value) ? 'initial' : 'none';
        }),
      )
      .subscribe();
  }

  private shouldDisplay(value: any): boolean {
    if (value == null || this.formControlToClear.disabled) {
      return false;
    }

    if (Array.isArray(value)) {
      return value.some(item => this.isNonEmptyObject(item));
    }

    if (typeof value === 'string') {
      return value.trim().length > 0;
    }

    return true;
  }

  private isNonEmptyObject(item: any): boolean {
    return item && typeof item === 'object' && !item.disabled && (!item.value || item.value?.trim().length > 0);
  }
}
