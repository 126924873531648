import {
  GetServiceSubscriptionFiltersQueryResponse,
  GetServiceSubscriptionsQueryResponse,
  ServiceSubscriptionQueryResponse,
  ServiceSubscriptionQueryResponseAdapter,
  ServiceSubscriptionRequestModel,
} from 'src/app/core/model/service-subscription';
import { ApiEndPoints } from 'src/app/config/api-end-points';
import { ApiResponse } from '../../../shared/models/response';
import { Observable, map } from 'rxjs';
import { CustomAttributeModel } from '../../../shared/models/custom-attribute';
import { DataSourceRequestModel } from '../../../shared/models/request';
import { DataSourceResult } from 'src/app/shared/models/data-source-result';
import { DefaultSortDirection } from '../../../shared/enums/default-sort-direction.enum';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsApiService } from 'src/app/shared/services/ms-api.service';
import { Parameter } from 'src/app/shared/models/custom-attribute/custom-parameters.model';
import { PeriodicityListModel } from '../service-instance/interfaces/periodicity-list.interface';
import { ReportingCalendarTypeListModel } from './interfaces/reporting-calendar-typelist.interface';
import { ServiceSubscriptionDetailsModel } from './interfaces/service-subscription-details.interface';
import { ServiceSubscriptionIrModel } from './interfaces/service-subscription-ir.interface';
import { ServiceSubscriptionSortRules } from './enums/service-subscription-sort-rules.enum';
import { ServiceSubscriptionPeriodRangeModel } from './interfaces/service-subscription-period-range.interface';
import { ChangeStatusModalModel } from 'src/app/shared/components/change-status-modal/change-status-modal.component';
import { ResponseModel } from 'src/app/shared/models/request-response.model';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor(private msApiService: MsApiService, private serviceSubscriptionQueryResponseAdapter: ServiceSubscriptionQueryResponseAdapter) {}

  public addServiceSubscription(param: ServiceSubscriptionDetailsModel) {
    const configUrl = ApiEndPoints.SERVICE_SUBSCRIPTION.serviceSubscriptionCreate;
    return this.msApiService.post(configUrl, param);
  }

  public deleteServiceInstanceParameter(param) {
    let endpoint = ApiEndPoints.SERVICE_INSTANCE.serviceInstanceParameterDelete;
    const configUrl = endpoint.replace('{id}', `${param.id}`).replace('{parameterId}', `${param.parameterId}`);
    return this.msApiService.post(configUrl, param);
  }

  public downloadTemplateServiceInstanceParameters(param): Observable<HttpResponse<Blob>> {
    const configUrl = ApiEndPoints.SERVICE_INSTANCE.serviceInstanceParametersDownloadTemplate;
    const httpOptions = {
      observe: 'response',
      responseType: 'blob',
    };
    return this.msApiService.getFileData(configUrl, param, httpOptions);
  }

  public downloadTemplateServiceSubscription(query: DataSourceRequestModel<ServiceSubscriptionSortRules, DefaultSortDirection>): Observable<any> {
    const httpOptions = {
      observe: 'response',
      responseType: 'blob',
    };
    return this.msApiService.getFileData(ApiEndPoints.SERVICE_SUBSCRIPTION.serviceSubscriptionDownloadTemplate, query, httpOptions);
  }

  public editStatus(param: ChangeStatusModalModel): Observable<ApiResponse<ChangeStatusModalModel>> {
    const configUrl = ApiEndPoints.SERVICE_SUBSCRIPTION.serviceSubscriptionEditStatus;
    return this.msApiService.post(configUrl, param);
  }

  public getCalendarTypes(): Observable<ApiResponse<ReportingCalendarTypeListModel[]>> {
    const configUrl = ApiEndPoints.CALENDAR_TYPE.getCalendarTypes;
    return this.msApiService.get(configUrl);
  }

  public getCountriesByEngagement(_engagementId: number): Observable<ApiResponse<ResponseModel>> {
    return this.msApiService.post(ApiEndPoints.SCOPE_PROFILES.engagements + `/${_engagementId}/countries`, { engagementId: _engagementId });
  }

  public getIrs(param: ServiceSubscriptionRequestModel): Observable<ApiResponse<ServiceSubscriptionIrModel[]>> {
    const configUrl = ApiEndPoints.SERVICE_SUBSCRIPTION.serviceSubscriptionGetIrs;
    return this.msApiService.post(configUrl, param);
  }

  public getPeriodicities(): Observable<ApiResponse<PeriodicityListModel[]>> {
    const configUrl = ApiEndPoints.PERIODICITY.getPeriodicities;
    return this.msApiService.get(configUrl);
  }

  public getServiceInstanceByID(serviceInstanceID: string) {
    const configUrl = ApiEndPoints.SERVICE_INSTANCE.serviceInstanceById;
    return this.msApiService.get(configUrl, { serviceInstanceID: serviceInstanceID });
  }

  public getServiceInstanceParameter(param): Observable<ApiResponse<Parameter>> {
    let endpoint = ApiEndPoints.SERVICE_INSTANCE.serviceInstanceParameter;
    const configUrl = endpoint.replace('{id}', `${param.id}`).replace('{parameterId}', `${param.parameterId}`);
    return this.msApiService.post<Parameter>(configUrl, param);
  }

  public getServiceInstanceParameterOverviewList(param) {
    let parametersEndpoint = ApiEndPoints.SERVICE_INSTANCE.serviceInstanceParameterOverview;
    const configUrl = parametersEndpoint.replace('{id}', `${param.serviceInstanceID}`);
    return this.msApiService.post(configUrl, param);
  }

  public getServiceSubscriptionCustomAttributes(): Observable<ApiResponse<CustomAttributeModel[]>> {
    const configUrl = ApiEndPoints.SERVICE_SUBSCRIPTION.serviceSubscriptionCustomAttributes;
    return this.msApiService.post<CustomAttributeModel[]>(configUrl);
  }

  public getServiceSubscriptionDetails(id: number): Observable<ApiResponse<ServiceSubscriptionDetailsModel>> {
    const configUrl = ApiEndPoints.SERVICE_SUBSCRIPTION.serviceSubscription;
    return this.msApiService.post(`${configUrl}${id}`, { id: `${id}` });
  }

  public getServiceSubscriptionFilters(
    query: DataSourceRequestModel<ServiceSubscriptionSortRules, DefaultSortDirection>,
  ): Observable<ApiResponse<GetServiceSubscriptionFiltersQueryResponse>> {
    return this.msApiService.post(ApiEndPoints.SERVICE_SUBSCRIPTION.getServiceSubscriptionsFilters, query);
  }

  public getServiceSubscriptions(
    query: DataSourceRequestModel<ServiceSubscriptionSortRules, DefaultSortDirection>,
  ): Observable<ApiResponse<DataSourceResult<ServiceSubscriptionQueryResponse>>> {
    return this.msApiService.post(ApiEndPoints.SERVICE_SUBSCRIPTION.getServiceSubscriptions, query).pipe(
      map((response: ApiResponse<GetServiceSubscriptionsQueryResponse>) => {
        return {
          ...response,
          data: {
            data: response.data.serviceSubscriptions.data.map(item => this.serviceSubscriptionQueryResponseAdapter.adapt(item)),
            total: response.data.serviceSubscriptions.total,
          },
        };
      }),
    );
  }

  public getServiceTypesByEngagement(param) {
    return this.msApiService.post(
      ApiEndPoints.SCOPE_PROFILES.engagements.concat(
        '/',
        param.engagementId,
        '/geographical-units/',
        param.geoUnitId,
        '/organizational-unit-hierarchies/',
        param.orgUnitHierarchyId,
        '/organizational-units/',
        param.orgUnitId,
        '/service-types',
      ),
      param,
    );
  }

  public getSubscriptionPeriodRange(): Observable<ApiResponse<ServiceSubscriptionPeriodRangeModel>> {
    const configUrl = ApiEndPoints.SERVICE_SUBSCRIPTION.serviceSubscriptionPeriodRange;
    return this.msApiService.post(`${configUrl}`, {});
  }

  public updateServiceInstance(param) {
    const configUrl = ApiEndPoints.SERVICE_INSTANCE.serviceInstanceUpdate;
    return this.msApiService.post(configUrl, param);
  }

  public updateServiceInstanceParameter(param) {
    let endpoint = ApiEndPoints.SERVICE_INSTANCE.serviceInstanceParameterUpdate;
    const configUrl = endpoint.replace('{id}', `${param.id}`).replace('{parameterId}', `${param.parameterId}`);
    return this.msApiService.post(configUrl, param);
  }

  public updateServiceSubscription(param: ServiceSubscriptionDetailsModel) {
    const configUrl = ApiEndPoints.SERVICE_SUBSCRIPTION.serviceSubscriptionUpdate;
    return this.msApiService.post(configUrl, param);
  }

  public validateServiceSubscriptionIfDuplicate(param) {
    const configUrl = ApiEndPoints.SERVICE_SUBSCRIPTION.serviceSubscriptionValidation;
    return this.msApiService.post(configUrl, param);
  }
}
