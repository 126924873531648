<div class="row">
  <div class="col-7">
    <div class="row left-side-container">
    </div>
  </div>
  <div class="col-5 right-side-container">
    <div class="user-name-welcome-container">
      <img
        width="70px"
        src="../../../../assets/images/ey-logo-beam.png"
      />
      <span *ngIf="userExistsInCommonDatabase">Hi {{ user }} ! </span>
      <span *ngIf="!userExistsInCommonDatabase">Hi There ! </span>
      <span class="dcms-mat-display-4"> {{ heroText }}</span>
    </div>

    <div class="client-select">
      <mat-form-field>
        <mat-label data-testid="login-select-client-label">Select Client</mat-label>
        <mat-select
          data-testid="login-client-name-select"
          (selectionChange)="clientDropdownChange($event.value)"
          [(ngModel)]="clientID"
          [disabled]="!userExistsInCommonDatabase || !userHasTenants"
          class="login__arrow"
        >
          <mat-option
            *ngFor="let client of clientList"
            [attr.data-testid]="'login-client-' + client.name"
            [title]="client.name"
            [value]="client.tenantId"
          >
            {{ client.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <button
      data-testid="login-next-button"
      (click)="clientSelected()"
      [disabled]="!userExistsInCommonDatabase || !userHasTenants"
      [ngClass]="!userExistsInCommonDatabase ? 'disable-button' : ''"
      class="login__submit-btn"
      color="accent"
      mat-flat-button
      >Next >
    </button>
    <div
      *ngIf="!userExistsInCommonDatabase || !userExistsInTenantDatabase || !userHasTenants"
      class="error-message"
    >
      <i class="bi bi-exclamation-circle-fill"></i>
      <div class="error-message-text"
        >You do not have access to this application. Contact the <b>administrator</b> to get registered in the application.</div
      >
    </div>
  </div>
</div>
