<ng-container *ngIf="isBackButtonVisible">
  <ng-container *ngIf="useRouterLink; then linkButtonTemplate; else clickButtonTemplate"></ng-container>

  <ng-template #linkButtonTemplate>
    <button
      [queryParams]="backButtonLink?.queryParams"
      [queryParamsHandling]="backButtonLink?.queryParamsHandling"
      [routerLink]="backButtonLink?.routerLink"
      class="page-title__back-button"
      data-testid="page-title-back-button"
      mat-icon-button
      matTooltip="Go back"
      type="button"
    >
      <mat-icon>arrow_back_ios</mat-icon>
    </button>
  </ng-template>
  <ng-template #clickButtonTemplate>
    <button
      (click)="onBackButtonClicked($event)"
      class="page-title__back-button"
      data-testid="page-title-back-button"
      mat-icon-button
      matTooltip="Go back"
      type="button"
    >
      <mat-icon>arrow_back_ios</mat-icon>
    </button>
  </ng-template>
</ng-container>

<h4
  class="page-title__title"
  data-testid="page-title"
  [title]="title"
>
  {{ title }}
</h4>
