import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Response } from '../models/response';
import { ApiResponse } from '../models/response';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private BASE_URL = environment.key.BASE_API_URL;
  private MICROSERVICE_URL = environment.key.MICROSERVICE_API_URL;

  constructor(private httpClient: HttpClient) {}

  /*--------------GENERIC API FOR GET METHOD-------------*/
  public invokeGetAPI(url: string, addTenantHeader: boolean = false, isMicroService: boolean = false): Observable<Response> {
    const headers = addTenantHeader ? { addTenantHeader: 'true' } : {};
    return this.httpClient.get<Response>((isMicroService ? this.MICROSERVICE_URL : this.BASE_URL) + url, { headers }).pipe(tap());
  }

  /*--------------GENERIC API FOR POST METHOD-------------*/
  public invokePostAPI<T>(url: string, body?: any, addTenantHeader: boolean = false, isMicroService: boolean = false, useMsalToken: boolean = false) {
    let headers: { [header: string]: string | string[] } = addTenantHeader ? { addTenantHeader: 'true' } : {};
    if (useMsalToken) {
      headers = { ...headers, useMsalToken: 'true' };
    }
    return this.httpClient.post<T>((isMicroService ? this.MICROSERVICE_URL : this.BASE_URL) + url, body, { headers }).pipe(tap());
  }

  /*--------------API FOR POST METHOD-------------*/
  public post<T>(url: string, body?: any, addTenantHeader: boolean = false): Observable<ApiResponse<T>> {
    const headers = addTenantHeader ? { addTenantHeader: 'true' } : {};
    return this.httpClient.post<ApiResponse<T>>(this.BASE_URL + url, body, { headers }).pipe(tap());
  }

  /*--------------API FOR GET METHOD-------------*/
  public get<T>(path: string, params: { [key: string]: string } = null, addTenantHeader: boolean = false) {
    const headers = addTenantHeader ? { addTenantHeader: 'true' } : {};
    if (params != null && Object.keys(params).length > 0) {
      const query = Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      path = `${path}?${query}`;
    }

    return this.httpClient.get<ApiResponse<T>>(`${this.BASE_URL}${path}`, { headers });
  }

  /*--------------GENERIC API FOR PUT METHOD-------------*/
  public invokePutAPI(url: string, params?: any, addTenantHeader: boolean = false, isMicroService: boolean = false) {
    const headers = addTenantHeader ? { addTenantHeader: 'true' } : {};
    return this.httpClient.put((isMicroService ? this.MICROSERVICE_URL : this.BASE_URL) + url, params, { headers }).pipe(tap());
  }

  /*--------------GENERIC API FOR DELETE METHOD-------------*/

  public invokeDeleteAPI(url: string, addTenantHeader: boolean = false, isMicroService: boolean = false): Observable<Response> {
    const headers = addTenantHeader ? { addTenantHeader: 'true' } : {};
    return this.httpClient.delete<Response>((isMicroService ? this.MICROSERVICE_URL : this.BASE_URL) + url, { headers }).pipe(tap());
  }
}
