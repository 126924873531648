import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, ObservableInput, switchMap, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpStatusCode } from '@angular/common/http';
import { SuccessMessageService } from '../../shared/services/success-message.service';
import { EncryptionService } from '../../shared/services/encryption-service';
import { Router } from '@angular/router';
import { TokenService } from '../services/token-service';
import { LoginStatusService } from '../../shared/services/login-status.service';
import { dateToEpochTimeString, epochTimeStringToDate } from 'src/app/shared/services/date.service';
import { TokenResponse } from '../model/token-response.interface';
import { LocalStorageService } from '../services/local-storage.service';
import { ApplicationStorageItem, StorageItem, TenantStorageItem, UserStorageItem } from '../model/storage-item';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  private selectedClient: string;
  private addTenantHeader = false;
  private refreshToken: string;
  private roleAccessToken: string;

  constructor(
    private encryptionService: EncryptionService,
    private successMessageService: SuccessMessageService,
    private router: Router,
    private tokenService: TokenService,
    private loginStatusService: LoginStatusService,
    private localStorageService: LocalStorageService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.refreshToken = this.localStorageService.getItemAsObject<ApplicationStorageItem>(StorageItem.Application)?.refreshToken;

    if (req.headers.get('addTenantHeader') === 'true') {
      this.addTenantHeader = true;
    } else {
      this.addTenantHeader = false;
    }

    if (this.addTenantHeader && (this.selectedClient !== null || this.selectedClient !== undefined)) {
      this.selectedClient = this.localStorageService.getItemAsObject<TenantStorageItem>(StorageItem.Tenant)?.id;
    }

    if (req.method === 'GET') {
      req = req.clone({
        headers: req.headers.set('Cache-Control', 'no-cache').set('Pragma', 'no-cache'),
      });
    }

    if (req.headers.get('dontSetContentType') !== 'true') {
      req = req.clone({
        headers: req.headers.set('Content-Type', 'application/json'),
      });
    }

    if (req.url.endsWith('refresh-token')) {
      if (!this.refreshToken) {
        return next.handle(req);
      }
      req = this.cloneRequest(req, this.refreshToken);
      return next.handle(req);
    }

    let authToken;
    this.roleAccessToken = this.localStorageService.getItemAsObject<UserStorageItem>(StorageItem.User)?.roleAccessToken;
    if (this.roleAccessToken) {
      authToken = this.roleAccessToken;
    }
    if (req.headers.get('useMsalToken') === 'true') {
      authToken = this.localStorageService.getItem('msalToken');
    }

    req = this.cloneRequest(req, authToken);

    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body && event.body.statusCode === HttpStatusCode.Ok) {
            this.successMessageService.show(event.body.resultCode);
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleResponseError(error, req, next);
      }),
    );
  }

  private cloneRequest(req: HttpRequest<any>, authorizationToken: string): HttpRequest<any> {
    const profileID = this.localStorageService.getItemAsObject<UserStorageItem>(StorageItem.User)?.profileId;
    const tenantName = this.localStorageService.getItemAsObject<TenantStorageItem>(StorageItem.Tenant)?.name;
    req = req.clone({
      setHeaders: {
        'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
        Pragma: 'no-cache',
        'Ocp-Apim-Subscription-Key': environment.key.OCP_API_SUBSCRIPTION_KEY,
        'Identity-Access-Token': 'token',
        Authorization: `Bearer ${authorizationToken}`,
        'Strict-Transport-Security': 'max-age=31536000;includeSubDomains;preload',
        ...(profileID && { 'Profile-Id': profileID }),
        ...(tenantName && { 'Tenant-Name': tenantName }),
        ...(this.addTenantHeader && { RequestFrom: 'DataOperator' }),
        ...(this.addTenantHeader && this.selectedClient && { TenantId: this.selectedClient }),
      },
    });
    return req;
  }

  handleResponseError(error: HttpErrorResponse, request?, next?): ObservableInput<any> {
    if (error.status !== 401) {
      return throwError(error);
    }

    // Invalid token error
    else if (error.status === 401) {
      let refreshTokenExpiryTime = this.retrieveRefreshTokenExpiryTime();

      if (refreshTokenExpiryTime && refreshTokenExpiryTime < new Date()) {
        throwError(error);
        return next.handle(request);
      }

      if (this.refreshToken && this.roleAccessToken) {
        return this.tokenService
          .getTokens({
            refreshToken: this.refreshToken,
            accessToken: this.roleAccessToken,
          })
          .pipe(
            tap((tokens: TokenResponse) => {
              const user = this.localStorageService.getItemAsObject<UserStorageItem>(StorageItem.User) || {};
              user.roleAccessToken = tokens.accessToken;
              this.localStorageService.setItem(StorageItem.User, user);

              const application = this.localStorageService.getItemAsObject<ApplicationStorageItem>(StorageItem.Application) || {};
              application.refreshToken = tokens.refreshToken;
              application.refreshAccessTokenExpTime = this.dateToCacheValue(tokens.refreshTokenValidTo);
              this.localStorageService.setItem(StorageItem.Application, application);

              this.roleAccessToken = tokens.accessToken;
              this.refreshToken = tokens.refreshToken;
              if (tokens.userHasAnotherActiveTransaction) {
                this.loginStatusService.clearSessionInfo();
                this.router.navigate(['/logout']);
              }
            }),
            switchMap(() => {
              request = this.addAuthHeader(request);
              return next.handle(request);
            }),
            catchError(e => {
              if (e.status !== 401) {
                return this.handleResponseError(e);
              } else {
                this.router.navigate(['/logout']);
                this.loginStatusService.clearSessionInfo();
                return next.handle(request);
              }
            }),
          );
      } else {
        // Cannot refresh token: no refresh token or no old access token set
        throwError(error);
        return next.handle(request);
      }
    }
  }

  private addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.localStorageService.getItemAsObject<UserStorageItem>(StorageItem.User)?.roleAccessToken}`,
      },
    });
  }

  private dateToCacheValue(date: Date): string {
    return this.encryptionService.encrypt(dateToEpochTimeString(date));
  }

  private retrieveRefreshTokenExpiryTime(): Date {
    let refreshTokenExpirationTime = this.localStorageService.getItemAsObject<ApplicationStorageItem>(
      StorageItem.Application,
    )?.refreshAccessTokenExpTime;
    if (refreshTokenExpirationTime) {
      return epochTimeStringToDate(refreshTokenExpirationTime);
    }
    return null;
  }
}
