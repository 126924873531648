import { IConfirmationSettings } from '../interfaces/confirmation-settings.interface';

export class ConfirmationSettingsData {
  public static generalSettings: IConfirmationSettings = {
    mainContent: 'Changes you are trying to make will impact the derived data e.g. Service Subscriptions, Service Instances, etc.',
    importantContent: 'If you proceed, then derived data will be refreshed to reflect the new changes.',
    warningContent: 'Note: This operation CANNOT be undone. Please confirm if you still wish to proceed.',
  };
  public static roleSettings: IConfirmationSettings = {
    mainContent: "Changes you are trying to make may impact the users' access levels within the application.",
    importantContent: 'If you proceed with this edit operation the concerned users would have updated privileges as per the roles they inherit.',
    warningContent: 'Note: This operation CANNOT be undone. Please confirm if you still wish to proceed.',
  };
  public static userSettings: IConfirmationSettings = {
    mainContent: "Changes you are trying to make may impact the users' access levels within the application.",
    importantContent:
      'If you proceed with this edit operation the concerned users would have updated privileges as per the user groups they inherit.',
    warningContent: 'Note: This operation CANNOT be undone. Please confirm if you still wish to proceed.',
  };
}
