import { ConfirmEditModalComponent } from '../components/confirm-edit-modal/confirm-edit-modal.component';
import { DialogDismissalReason, DialogDismissalReasonType } from 'src/app/config/constant';
import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IConfirmationSettings } from '../interfaces/confirmation-settings.interface';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, filter, switchMap, tap } from 'rxjs';

@Directive({
  selector: '[appEditAction]',
})
export class EditActionDirective implements OnInit, OnDestroy {
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    backdropClass: 'customBackdrop',
  };
  private subscription: Subscription;
  private trigger: Subject<void> = new Subject();

  @Input() public confirmationSettings: IConfirmationSettings;
  @Output() public confirmed: EventEmitter<void> = new EventEmitter();

  constructor(private modalService: NgbModal) {}

  @HostListener('click', ['$event'])
  public clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.trigger.next();
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public ngOnInit() {
    this.subscription = this.trigger
      .asObservable()
      .pipe(
        switchMap(() => {
          const modalRef = this.modalService.open(ConfirmEditModalComponent, this.modalOptions);
          if (this.confirmationSettings) {
            (modalRef.componentInstance as ConfirmEditModalComponent).settings = this.confirmationSettings;
          }
          return modalRef.dismissed;
        }),
        filter((reason: DialogDismissalReasonType) => reason === DialogDismissalReason.confirm),
        tap(() => this.confirmed.emit()),
      )
      .subscribe();
  }
}
