import { BaseComponent } from '../base-component';
import { Component, Input } from '@angular/core';
import { ConfirmationSettingsData } from '../../constants/confirmation-settings-data';
import { DialogDismissalReason } from 'src/app/config/constant';
import { IConfirmationSettings } from '../../interfaces/confirmation-settings.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-edit-modal',
  templateUrl: './confirm-edit-modal.component.html',
  styleUrls: ['./confirm-edit-modal.component.scss'],
})
export class ConfirmEditModalComponent extends BaseComponent {
  @Input() public settings: IConfirmationSettings = ConfirmationSettingsData.generalSettings;

  constructor(private activeModal: NgbActiveModal) {
    super();
  }

  public cancel() {
    this.activeModal.dismiss(DialogDismissalReason.cancel);
  }

  public confirm() {
    this.activeModal.dismiss(DialogDismissalReason.confirm);
  }
}
