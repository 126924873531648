import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '../base-component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchItemListComponent } from '../search-item-list/search-item-list.component';
import { DataAccessGroupSelectDataModel } from 'src/app/modules/admin/data-access-groups/models/data-access-group-select-data.model';
import { DataAccessGroupSelectGuidDataModel } from 'src/app/modules/admin/data-access-groups/models/data-access-group-select-guid-data.model';
import { GetUserGroupQueryResponse } from 'src/app/modules/admin/models/user-group.model';
import { ServiceSubscriptionIrModel } from 'src/app/modules/portal/subscription/interfaces/service-subscription-ir.interface';

@Component({
  selector: 'app-select-list-dialog',
  templateUrl: './select-list-dialog.component.html',
  styleUrls: ['./select-list-dialog.component.scss'],
})
export class SelectListDialogComponent extends BaseComponent {
  @Input() public title: string;
  @Input() public list: Array<DataAccessGroupSelectDataModel | DataAccessGroupSelectGuidDataModel | ServiceSubscriptionIrModel>;
  @Input() public disabled: boolean;
  @Input() public descriptionIcon: string[];
  @Input() public searchPlaceHolder: string;
  @Input() public keyList: Array<string>;
  @Input() public keyId: string;
  @Input() public removeable: boolean;
  @Input() public filterList: Array<
    DataAccessGroupSelectDataModel | DataAccessGroupSelectGuidDataModel | GetUserGroupQueryResponse | ServiceSubscriptionIrModel
  >;
  @Input() public filterPlaceholder: string;
  @Input() public filterByField: string;

  @ViewChild(SearchItemListComponent) public searchItemList: SearchItemListComponent;

  constructor(private activeModal: NgbActiveModal) {
    super();
  }

  public close(): void {
    this.activeModal.dismiss();
  }

  public add(): void {
    this.activeModal.close(this.searchItemList.selectList.selection.selectedOptions.selected.filter(i => i.value).map(o => o.value));
  }
}
