export class ErrorModel {
  message: string;
  description: string[];
  resutCode: string;

  constructor() {
    this.message = '';
    this.description = [];
    this.resutCode = '';
  }
}
