import {
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  OnChanges,
  SimpleChanges,
  Inject,
  Injector,
  INJECTOR,
  TemplateRef,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ITranslateDefaultValue } from 'src/app/modules/translations/translate-default-value';
import { translateDefaultValue } from 'src/app/modules/translations/missing-attribute-translation-handler';
import { DropdownOption } from '../../models/searchable-dropdown/dropdown-option.model';
import { BaseComponent } from '../base-component';

@Component({
  selector: 'app-multiselect-dropdown-list',
  templateUrl: './multi-select-dropdown-list.component.html',
  styleUrls: ['./multi-select-dropdown-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleDropdownListComponent),
      multi: true,
    },
  ],
})
export class MultipleDropdownListComponent extends BaseComponent implements OnInit, OnDestroy, ControlValueAccessor, OnChanges {
  public readonly control: FormControl = new FormControl([]);
  public readonly translateDefaultValue: (defaultValue: string) => ITranslateDefaultValue = translateDefaultValue;

  @Input() public compareWith: Function;
  @Input() public dropdownOptions: DropdownOption[];
  @Input() public labelTranslate: string = '';
  @Input() public labelTranslateParam: string = '';
  @Input() public optionTemplate: TemplateRef<any>;
  @Input() public testIdArea: string = 'area-not-provided';
  @Input() public testIdControl: string = 'undefined';

  public filteredOptions: ReplaySubject<DropdownOption[]> = new ReplaySubject<DropdownOption[]>(1);
  public optionFilterCtrl: FormControl<string> = new FormControl<string>('');

  constructor(@Inject(INJECTOR) private injector: Injector) {
    super();
  }

  public get required(): boolean {
    return this.injector.get(NgControl).control?.hasValidator(Validators.required);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.dropdownOptions && changes.dropdownOptions.currentValue) {
      this.filteredOptions.next(this.dropdownOptions.slice());
    }
  }

  public ngOnInit() {
    this.filteredOptions.next(this.dropdownOptions.slice());

    this.optionFilterCtrl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.filterDropdownOptions();
    });
  }

  public onSelectionChange(): void {
    this.control.updateValueAndValidity();
    this._onTouched();
  }

  public registerOnChange(callback: (data: DropdownOption[]) => void): void {
    this.control.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selected: DropdownOption[]) => {
      callback(selected);
    });
  }

  public registerOnTouched(callback: (data: any) => void): void {
    this.control.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => callback(this.control.touched));
  }

  public setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable({ emitEvent: false });
      this.control.reset();
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  public trackByFn(index: number, item: DropdownOption): number {
    return item.value;
  }

  public writeValue(value: any[]): void {
    this.control.setValue(value);
  }

  protected filterDropdownOptions(): void {
    if (!this.dropdownOptions || this.dropdownOptions.length === 0) {
      this.filteredOptions.next([]);
      return;
    }

    const search = this.optionFilterCtrl.value?.toLowerCase() || '';

    if (!search) {
      this.filteredOptions.next(this.dropdownOptions.slice());
      return;
    }

    const filtered = search ? this.filterOptions(search) : this.dropdownOptions.slice();
    this.filteredOptions.next(filtered);
  }

  private _onTouched = () => {};

  private filterOptions(searchTerm: string): DropdownOption[] {
    return this.dropdownOptions.filter(option => option.label.toLowerCase().includes(searchTerm));
  }
}
