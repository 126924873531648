<mat-form-field>
  <mat-label
    [attr.data-testid]="testIdArea + '-' + testIdControl + '-label'"
    [translate]="labelTranslate"
    [translateParams]="this.translateDefaultValue(labelTranslateParam)"
  ></mat-label>
  <mat-select
    [attr.data-testid]="testIdArea + '-' + testIdControl + '-select'"
    [formControl]="control"
    [multiple]="true"
    [required]="required"
    [compareWith]="compareWith"
    (selectionChange)="onSelectionChange()"
  >
    <mat-select-trigger>
      <ng-container *ngFor="let option of control.value">
        {{ option.label }}
      </ng-container>
    </mat-select-trigger>
    <mat-option
      *ngIf="dropdownOptions.length > 6"
      [attr.data-testid]="testIdArea + '-' + testIdControl + '-search-option'"
    >
      <ngx-mat-select-search
        noEntriesFoundLabel="No matching results"
        placeholderLabel="Search"
        [formControl]="optionFilterCtrl"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option
      [attr.data-testid]="testIdArea + '-' + testIdControl + '-option-' + filteredOption.label"
      *ngFor="let filteredOption of filteredOptions | async; trackBy: trackByFn"
      [value]="filteredOption"
    >
      <ng-container
        [ngTemplateOutlet]="optionTemplate"
        [ngTemplateOutletContext]="{ $implicit: filteredOption }"
      ></ng-container>
    </mat-option>
  </mat-select>
  <button
    [appButtonClearOption]="control"
    aria-label="Clear"
    [attr.data-testid]="testIdArea + '-' + testIdControl + '-clear-button'"
    mat-icon-button
    matSuffix
    type="button"
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-error>
    <app-validation-message [field]="control"></app-validation-message>
  </mat-error>
</mat-form-field>
