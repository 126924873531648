<mat-icon *ngIf="changeOrder" class="spacing">drag_indicator</mat-icon>
<div class="list-item__menu-container">
  <ng-content select="[listItemMenu]"></ng-content>
</div>

<div class="list-item__title-container">
  <p class="list-item__main-title-wrapper">
    <span
      class="list-item__main-title"
      data-testid="li-title-label"
    >
      {{ title }}
    </span>
  </p>

  <ul class="list-item__info">
    <li
      class="list-item__info-item"
      *ngIf="showIcon || status"
    >
      <mat-icon
        *ngIf="showIcon"
        attr.data-testid="li-sub-status-icon"
        class="list-item__info-icon"
        [class.text-success]="activeStatusOn"
        >{{ activeStatusOn ? 'check_circle' : 'cancel' }}</mat-icon
      >
      <span
        attr.data-testid="li-sub-status-name-label"
        class="list-item__info-label"
        >{{ status | activeInactive }}</span
      >
    </li>
    <li
      class="list-item__info-item"
      [ngClass]="getCssClass(info)"
      *ngFor="let info of infoList"
    >
      <mat-icon
        [ngClass]="getCssIconClass(info)"
        attr.data-testid="li-sub-{{ info.icon }}-icon"
        class="list-item__info-icon"
        >{{ info.icon }}</mat-icon
      >
      <span
        attr.data-testid="li-sub-{{ info.icon }}-label"
        class="list-item__info-label"
        >{{ info.label }}</span
      >
    </li>
  </ul>
</div>

<div class="list-item__action-container">
  <ng-content select="[listItemAction]"></ng-content>
</div>
