import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ContextMenuAction } from '../../../config/constant';
import { Privileges } from '../../constants/privileges.enum';
import { Modules } from '../../constants/modules.enum';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent {
  @Input() public data: any;
  @Input() public disableEdit: boolean = false;
  @Input() public module: Modules;
  @Input() public noDelete: boolean = false;
  @Input() public onlyView: boolean = false;
  @Input() public showChangeStatusOption: boolean = false;
  @Output() public openPopUp = new EventEmitter<any>();
  @Output() public setSelectedId = new EventEmitter<any>();

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  public contextMenu: typeof ContextMenuAction = ContextMenuAction;
  public privileges: typeof Privileges = Privileges;

  private open(actionType: string) {
    this.openPopUp.emit(actionType);
  }

  private setID(dataId: string) {
    this.setSelectedId.emit(dataId);
  }

  private onOptionClick(event: any): void {
    this.trigger.closeMenu();
    (event.target as HTMLElement).focus();
  }

  protected handleMenuEvent(actionType: string, dataId: string, event: any) {
    this.onOptionClick(event);
    this.open(actionType);
    this.setID(dataId);
  }
}
