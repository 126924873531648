import { Component, Input } from '@angular/core';
import { ListItemInfo } from '../../models/list-item-info.interface';

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent {
  @Input() public activeStatusOn: boolean;
  @Input() public infoList: Array<ListItemInfo>;
  @Input() public showIcon: boolean;
  @Input() public statusName: string;
  @Input() public title: string;
  @Input() public changeOrder: boolean = false;

  public get status(): string | boolean {
    return this.statusName ?? this.activeStatusOn;
  }

  public getCssIconClass(item: ListItemInfo): { [key: string]: boolean } {
    return {
      [item.cssIconClass]: !!item.cssIconClass?.length,
    };
  }

  public getCssClass(item: ListItemInfo): { [key: string]: boolean } {
    return {
      [item.cssClass]: !!item.cssClass?.length,
    };
  }
}
