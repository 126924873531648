import { CustomAttributesConstants } from '../constants/custom-attributes-constants';
import { CustomAttributesControlTypeModel, CustomAttributesOptionModel } from '../models/custom-attribute';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CustomAttributesHelperService {
  private readonly dictionaryCustomAttributeTypes: CustomAttributesConstants[] = [
    CustomAttributesConstants.CONTROL_DROPDOWN_MULTI_OPTION,
    CustomAttributesConstants.CONTROL_DROPDOWN_TWO_OPTION,
  ];

  constructor() {}

  public convertBooleanDropdownValue(
    value: number | boolean,
    options: CustomAttributesOptionModel[],
    direction: 'fromRequest' | 'toResponse',
  ): number | boolean {
    switch (direction) {
      case 'toResponse':
        return options.find((option: CustomAttributesOptionModel): boolean => option.value === ((value as boolean) ? '1' : '0')).id;
      case 'fromRequest':
        return options.find((option: CustomAttributesOptionModel): boolean => option.id === (value as number)).value === '1';
    }
  }

  public getAttributePropertyAccessor(
    controlType: CustomAttributesControlTypeModel,
    treatAsDictionaryAttribute?: CustomAttributesConstants[],
  ): 'valueOptionId' | 'value' {
    return (treatAsDictionaryAttribute
      ? this.dictionaryCustomAttributeTypes.concat(treatAsDictionaryAttribute)
      : this.dictionaryCustomAttributeTypes
    ).includes(controlType.name)
      ? 'valueOptionId'
      : 'value';
  }

  public getComparisonOperator(operator: CustomAttributesConstants): string {
    switch (operator) {
      case CustomAttributesConstants.RELATIONAL_OPERATOR_EQ:
        return '==';
      case CustomAttributesConstants.RELATIONAL_OPERATOR_NE:
        return '!=';
      case CustomAttributesConstants.RELATIONAL_OPERATOR_LT:
        return '<';
      case CustomAttributesConstants.RELATIONAL_OPERATOR_LE:
        return '<=';
      case CustomAttributesConstants.RELATIONAL_OPERATOR_GT:
        return '>';
      case CustomAttributesConstants.RELATIONAL_OPERATOR_GE:
        return '>=';
    }
  }

  public getLogicalOperator(operator: CustomAttributesConstants): string {
    switch (operator) {
      case CustomAttributesConstants.LOGICAL_OPERATOR_AND:
        return '&&';
      case CustomAttributesConstants.LOGICAL_OPERATOR_OR:
        return '||';
    }
  }
}
