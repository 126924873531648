import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorModel } from '../models/error-model';
import { ErrorsContainer } from '../constants/errors-ui-mapper';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessageService {
  public getClientErrorMessage(error: Error): string {
    return ErrorsContainer.errGeneric.message;
  }

  public getServerErrorMessage(error: HttpErrorResponse): ErrorModel {
    let errObj: ErrorModel = new ErrorModel();

    if (!navigator.onLine) {
      return ErrorsContainer.errNoInternetConnection;
    }
    errObj.message = error?.error ? error.error.message : error?.message;
    for (let key in error?.error?.errors) {
      errObj.description.push(error?.error?.errors[key].join('<br>'));
    }
    return errObj;
  }
}
