import { CustomAttributeFilterModel } from '../../../shared/models/filter';

export interface ServiceSubscriptionTemplateFilters {
  commonSearch?: string;
  customAttributeFilter: CustomAttributeFilterModel;
  pageIndex: number;
  periodicity?: Array<String>;
  serviceOffering?: Array<String>;
  serviceType?: Array<String>;
  sortColumn?: string;
  sortOrder?: string;
  status?: Array<String>;
}
