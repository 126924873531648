import { Component, EventEmitter, Input, Optional, Output, forwardRef } from '@angular/core';
import { OrganizationService } from '../../../modules/portal/organization/organization.service';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { ValidationPattern } from 'src/app/shared/constants/ValidationPattern';
import { uniqueDataSourceNameValidator } from './validators/unique-datasource-name.validator';
import { DataSourceValidationInput } from './interfaces/data-source-validation-input.interface';
import { map, takeUntil, tap } from 'rxjs';
import { BaseComponent } from '../base-component';
import { translateDefaultValue } from 'src/app/modules/translations/missing-attribute-translation-handler';
import { DataSourceAndSinkModel } from '../../../core/model/organizational-unit/data-source-and-sink.model';
import { EntityStatusModel } from 'src/app/shared/models/entity-status.model';
import { ContextMenuAction } from 'src/app/config/constant';
import { EntityStatusService } from '../../services/entity-status.service';
import { ReferencedTableName } from 'src/app/shared/enums/referenced-table-name.enum';
import { filterNonSuccessfullAndEmptyResponses } from '../../operators/api-response-operators';

@Component({
  selector: 'app-data-source-and-sink',
  templateUrl: './data-source-and-sink.component.html',
  styleUrls: ['./data-source-and-sink.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DataSourceAndSinkComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DataSourceAndSinkComponent),
      multi: true,
    },
  ],
})
export class DataSourceAndSinkComponent extends BaseComponent implements ControlValueAccessor {
  @Input() actionType: string;
  @Input() public alwaysDisabled: boolean = false;
  @Input() dataSourceAndSinkModel: DataSourceAndSinkModel = new DataSourceAndSinkModel();
  @Input() public itemNumber: number;
  @Input() public dataSourceList: Array<DataSourceAndSinkModel>;
  @Output() dataSourceAndSinkModelEmitter = new EventEmitter<DataSourceAndSinkModel>();
  public onTouched: any;
  public systemTypeList = [];
  public statusList: EntityStatusModel[] = [];
  public dataSourceValidationInput: DataSourceValidationInput;
  public form: FormGroup;
  public translateDefaultValue = translateDefaultValue;

  constructor(private organizationService: OrganizationService, private formBuilder: FormBuilder, private entityStatusService: EntityStatusService) {
    super();
  }

  public registerOnChange(fn: any): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map(formValue => ({
          systemName: formValue.systemName,
          systemCode: formValue.systemCode,
          systemTypeId: formValue.systemTypeId,
          sinkName: formValue.sinkName,
          statusID: formValue.statusID,
        })),
      )
      .subscribe(fn);
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {}

  ngOnInit(): void {
    this.getSystemTypesListData();
    this.dataSourceValidationInput = {
      itemNumber: this.itemNumber,
      dataSourceList: this.dataSourceList,
    };
    this.initForm();
    this.checkSystemNameRequiredValidation();
    this.initFormSubscription();
    this.getStatuses();
  }

  public onInputChange(): void {
    this.dataSourceAndSinkModel.systemName = this.form.get('systemName').value;
    this.dataSourceAndSinkModel.systemCode = this.form.get('systemCode').value;
    this.dataSourceAndSinkModel.systemTypeId = this.form.get('systemTypeId').value;
    this.dataSourceAndSinkModel.sinkName = this.form.get('sinkName').value;
    this.dataSourceAndSinkModel.statusID = this.form.get('statusID').value;
    this.dataSourceAndSinkModelEmitter.emit(this.dataSourceAndSinkModel);
  }

  public get isEditOrDeleteMode(): boolean {
    return this.actionType === ContextMenuAction.view || this.actionType === ContextMenuAction.delete || this.alwaysDisabled;
  }

  private getSystemTypesListData() {
    this.organizationService.getSystemTypes().subscribe((data: any) => {
      this.systemTypeList = data.data;
    });
  }

  private getStatuses(): void {
    this.entityStatusService
      .getEntityStatuses(ReferencedTableName.DataSource)
      .pipe(
        filterNonSuccessfullAndEmptyResponses(),
        tap(response => {
          this.statusList = response.data;
          if (this.actionType === ContextMenuAction.create || this.dataSourceAndSinkModel.statusID.toString() === '') {
            this.form.get('statusID').setValue(this.statusList.find(item => item.isDefault).id);
          }
        }),
      )
      .subscribe();
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      systemName: [
        this.dataSourceAndSinkModel?.systemName ?? '',
        [
          Validators.maxLength(500),
          uniqueDataSourceNameValidator(this.dataSourceValidationInput),
        ],
      ],
      systemCode: [
        this.dataSourceAndSinkModel?.systemCode ?? '',
        [Validators.maxLength(100)],
      ],
      systemTypeId: [this.dataSourceAndSinkModel?.systemTypeId ?? ''],
      sinkName: [
        this.dataSourceAndSinkModel?.sinkName ?? '',
        [Validators.maxLength(1000)],
      ],
      statusID: [{ value: this.dataSourceAndSinkModel?.statusID ?? '', disabled: this.isEditOrDeleteMode }],
    });
  }

  private initFormSubscription(): void {
    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.checkSystemNameRequiredValidation();
    });
  }

  private checkSystemNameRequiredValidation() {
    if (this.form.get('systemCode').value || this.form.get('systemTypeId').value || this.form.get('sinkName').value) {
      this.form.get('systemName').addValidators(Validators.required);
      this.form.get('statusID').addValidators(Validators.required);
    } else {
      this.form.get('systemName').removeValidators(Validators.required);
      this.form.get('statusID').removeValidators(Validators.required);
    }
    this.form.get('systemName').updateValueAndValidity({ onlySelf: true });
    this.form.get('statusID').updateValueAndValidity({ onlySelf: true });
    this.onInputChange();
  }

  public setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  public validate() {
    return this.form.get('systemName').valid &&
      this.form.get('systemCode').valid &&
      this.form.get('systemTypeId').valid &&
      this.form.get('sinkName').valid
      ? null
      : { invalid: true };
  }
}
