import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { MsalGuard } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { LoginStatusService } from 'src/app/shared/services/login-status.service';

@Injectable()
export class AuthGuard  {
  constructor(private router: Router, private msal: MsalGuard, private loginStatusService: LoginStatusService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.loginStatusService.userLoggedInStatus$.getValue().loginStatus || !this.msal.canActivate(route, state)) {
      window.location.href = environment.config.redirectUri;
      return false;
    }
    return true;
  }
}
