import { ApiEndPoints } from 'src/app/config/api-end-points';
import { ApiResponse } from '../models/response/api-response';
import { CustomFieldDetailedItemResponse } from 'src/app/modules/admin/custom-fields-creator/interfaces/custom-field-detailed-item-response.interface';
import { Customization, CustomizationAdapter } from '../models/customization';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { MsApiService } from './ms-api.service';
import { Observable, map, take, tap } from 'rxjs';
import { CustomAttributeFilterResponseCollection } from '../models/filter';
import { ApplicationStorageItem, StorageItem } from 'src/app/core/model/storage-item';

@Injectable({
  providedIn: 'root',
})
export class CustomAttributesService {
  private _customAttributes: Array<Customization> = null;

  public get customAttributes(): Array<Customization> {
    if (this._customAttributes == null) {
      try {
        const customizations = this.localStorageService.getItemAsObject<ApplicationStorageItem>(StorageItem.Application)?.customizations;
        if (!customizations?.length) {
          this.loadCustomization();
        }

        this._customAttributes = customizations;
      } catch (err) {
        this._customAttributes = [];
      }
    }

    return this._customAttributes;
  }

  constructor(
    private apiService: MsApiService,
    private localStorageService: LocalStorageService,
    private customizationAdapter: CustomizationAdapter,
  ) {}

  public getCustomDictionaryFields(param: any): Observable<ApiResponse<any>> {
    const configUrl = ApiEndPoints.CUSTOM_ATTRIBUTES.externalService;
    return this.apiService.post<CustomFieldDetailedItemResponse>(configUrl, param);
  }

  public getCustomAttributeFreeTextFilters(param: any): Observable<ApiResponse<any>> {
    const configUrl = ApiEndPoints.CUSTOM_ATTRIBUTES.getFreeTextFilters;
    return this.apiService.post<ApiResponse<CustomAttributeFilterResponseCollection<number>[]>>(configUrl, param);
  }

  public loadCustomization(): void {
    const url = ApiEndPoints.FORM.customization;

    this.apiService
      .cachePost(url)
      .pipe(
        map((response: ApiResponse<Array<Customization>>) =>
          response.data.map((customization: Customization) => this.customizationAdapter.adapt(customization)),
        ),
        tap((customizations: Array<Customization>) => {
          if (customizations?.length) {
            const application = this.localStorageService.getItemAsObject<ApplicationStorageItem>(StorageItem.Application) || {};
            application.customizations = customizations;
            this.localStorageService.setItem(StorageItem.Application, application);
          }
        }),
        take(1),
      )
      .subscribe();
  }
}
