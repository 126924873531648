<form [formGroup]="form">
  <div class="row">
    <div
      class="col-md-6"
      *appIsHidden="'Org Unit/Source_System_Name'"
    >
      <mat-form-field>
        <mat-label
          data-testid="dsa-sink-system-name-label"
          [translate]="'Org Unit/Source_System_Name'"
          [translateParams]="translateDefaultValue('Source System Name')"
        ></mat-label>
        <input
          data-testid="dsa-sink-system-name-input"
          formControlName="systemName"
          matInput
        />
        <mat-error>
          <app-validation-message [field]="form.get('systemName')"></app-validation-message>
        </mat-error>
      </mat-form-field>
    </div>
    <div
      class="col-md-6"
      *appIsHidden="'Org Unit/System_Code'"
    >
      <mat-form-field>
        <mat-label
          data-testid="dsa-sink-system-code-label"
          [translate]="'Org Unit/System_Code'"
          [translateParams]="translateDefaultValue('System Code')"
        ></mat-label>
        <input
          data-testid="dsa-sink-system-code-input"
          formControlName="systemCode"
          matInput
        />
        <mat-error>
          <app-validation-message [field]="form.get('systemCode')"></app-validation-message>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row input-grid">
    <div
      class="col-md-6"
      *appIsHidden="'Org Unit/System_Type'"
    >
      <mat-form-field>
        <button
          data-testid="dsa-sink-clear-system-type-button"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          [appButtonClearOption]="form.controls.systemTypeId"
        >
          <mat-icon>close</mat-icon>
        </button>
        <mat-label
          data-testid="dsa-sink-system-type-label"
          [translate]="'Org Unit/System_Type'"
          [translateParams]="translateDefaultValue('System Type')"
        ></mat-label>
        <mat-select
          data-testid="dsa-sink-system-type-select"
          formControlName="systemTypeId"
        >
          <mat-option
            *ngFor="let systemType of systemTypeList"
            [attr.data-testid]="'dsa-sink-system-type-option-' + systemType.typeName"
            [title]="systemType.typeName"
            [value]="systemType.id"
          >
            {{ systemType.typeName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      class="col-md-6"
      *appIsHidden="'Org Unit/Status'"
    >
      <mat-form-field>
        <mat-label
          [translateParams]="translateDefaultValue('Status default')"
          [translate]="'Org Unit/Status'"
          data-testid="dsa-detalis-status-label"
        >
        </mat-label>
        <mat-select
          [appDisableControl]="isEditOrDeleteMode"
          data-testid="dsa-detalis-status-select"
          formControlName="statusID"
        >
          <mat-option
            *ngFor="let status of statusList"
            [title]="status.name"
            [value]="status.id"
            data-testid="dsa-detalis-status-option"
          >
            {{ status.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          <app-validation-message [field]="form.get('statusID')"></app-validation-message>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row input-grid">
    <div
      class="col-md-6"
      *appIsHidden="'Org Unit/Sink_Name'"
    >
      <mat-form-field>
        <mat-label
          data-testid="dsa-sink-name-label"
          [translate]="'Org Unit/Sink_Name'"
          [translateParams]="translateDefaultValue('Sink Name')"
        ></mat-label>
        <input
          data-testid="dsa-sink-name-input"
          formControlName="sinkName"
          matInput
        />
        <mat-error>
          <app-validation-message [field]="form.get('sinkName')"></app-validation-message>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
