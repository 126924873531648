import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ToastService } from 'src/app/shared/services/toast.service';
import { AttributeDisplayName } from 'src/app/core/model/attribute-display-name';
import { ApiService } from 'src/app/shared/services/api-service';
import { ApiEndPoints } from 'src/app/config/api-end-points';
import { ResponseModel } from '../../shared/models/request-response.model';
import { DefaultField } from '../../shared/models/default-field';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HttpLoader {
  constructor(private apiService: ApiService, private toastService: ToastService) {}

  public getAttributes(url: string) {
    return this.apiService.invokePostAPI<ResponseModel>(url, false, true);
  }

  public getTranslation(lang: string): Observable<any> {
    const url = ApiEndPoints.FORM.fieldCustomizations;

    return this.getAttributes(url).pipe(
      map((response: ResponseModel) => {
        const data: Array<DefaultField> = response.data;

        if (!data || data.length === 0) {
          return this.emptyStore();
        }

        return this.transform(this.mapResponse(data));
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status !== 401) {this.toastService.showError(`Error while loading custom fields", ${error?.message}`);}

        return this.emptyStore();
      }),
    );
  }

  private mapResponse(defaultFields: Array<DefaultField>): Array<AttributeDisplayName> {
    return defaultFields.map(f => {
      return { domain: f.domain, key: f.label, value: f.displayName };
    });
  }

  private emptyStore(): Observable<any> {
    return of({});
  }

  private transform = (attributes: Array<AttributeDisplayName>) => {
    return Object.assign({}, ...attributes.map((a: AttributeDisplayName) => ({ [`${a.domain}/${a.key}`]: a.value })));
  };
}
