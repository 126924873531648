import { Injectable } from '@angular/core';
import { ModulePrivileges, Permission } from '../models/rbac';
import { Privileges } from '../constants/privileges.enum';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { StorageItem, UserStorageItem } from 'src/app/core/model/storage-item';

@Injectable({
  providedIn: 'root',
})
export class UserRolesService {
  constructor(private localStorageService: LocalStorageService) {}

  public get userModulesPrivileges(): Array<ModulePrivileges> {
    return (
      (JSON.parse(this.localStorageService.getItemAsObject<UserStorageItem>(StorageItem.User)?.modulesPrivileges) as Array<ModulePrivileges>) || []
    );
  }

  public set userModulesPrivileges(modulesPrivileges: Array<ModulePrivileges>) {
    const user = this.localStorageService.getItemAsObject<UserStorageItem>(StorageItem.User) || {};
    user.modulesPrivileges = JSON.stringify(modulesPrivileges);
    this.localStorageService.setItem(StorageItem.User, user);
  }

  public get userPermissions(): Array<Permission> {
    return [].concat.apply(
      [],
      this.userModulesPrivileges.map((modulePermissions: ModulePrivileges) => {
        return modulePermissions.privileges.map(
          (privilege: Privileges) =>
            <Permission>{
              module: modulePermissions.module,
              privilege: privilege,
            },
        );
      }),
    );
  }

  public arePermissionsEqualPredicate(comparedTo: Permission): (requiredPermissions: Permission) => boolean {
    return (requiredPermissions: Permission) =>
      requiredPermissions.module === comparedTo.module && requiredPermissions.privilege === comparedTo.privilege;
  }

  public hasPermission(permissions: Array<Permission>): boolean {
    const hasPermission: boolean = !!this.userPermissions.filter((permission: Permission) =>
      permissions.some(this.arePermissionsEqualPredicate(permission)),
    ).length;
    return hasPermission;
  }

  public hasAllTenantsReadPrivilege(): boolean {
    return !!this.userPermissions.find((privilege: Permission) => privilege.privilege === Privileges.allTenantsRead);
  }
}
