<div class="page-content__data-grid-container">
  <ng-content select="[pageContentDataGrid]"></ng-content>
</div>

<div
  *ngIf="isFilterVisible"
  class="page-content__filter-container"
>
  <ng-content select="[pageContentFilter]"></ng-content>
</div>
