<form [formGroup]="form">
  <div class="row input-grid">
    <h5>CR Details</h5>
    <div class="col-md-6">
      <mat-form-field>
        <mat-label
          data-testid="cr-details-change-number-label"
          [translate]="this.domain + '/Change_Number'"
          [translateParams]="this.translateDefaultValue('Change Number')"
        ></mat-label>
        <input
          data-testid="cr-details-change-number-input"
          formControlName="changeRequestNumberField"
          matInput
          appInputPrefix="CHG"
        />
        <mat-error>
          <app-validation-message [field]="form.get('changeRequestNumberField')"></app-validation-message>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-6">
      <mat-form-field>
        <mat-label
          data-testid="cr-details-change-raised-on-label"
          [translate]="this.domain + '/Change_Raised_On'"
          [translateParams]="this.translateDefaultValue('Change Raised On')"
        ></mat-label>
        <input
          data-testid="cr-details-change-raised-on-input"
          (click)="crRaisedOnDatePicker.open()"
          (keypress)="(false)"
          formControlName="changeRaisedOnField"
          [matDatepicker]="crRaisedOnDatePicker"
          matInput
          class="input-cr-raised-on"
          name="crCreatedOn"
        />
        <mat-datepicker-toggle
          [for]="crRaisedOnDatePicker"
          matSuffix
        >
        </mat-datepicker-toggle>
        <mat-datepicker #crRaisedOnDatePicker></mat-datepicker>
        <mat-error>
          <app-validation-message [field]="form.get('changeRaisedOnField')"></app-validation-message>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row input-grid">
    <div class="col-md-12">
      <mat-form-field>
        <mat-label
          [translate]="domain + '/Change_Raised_By'"
          data-testid="cr-details-change-raised-by-label"
          [translateParams]="translateDefaultValue('Change Raised By')"
        ></mat-label>
        <input
          formControlName="crRequestedByField"
          data-testid="cr-details-change-raised-by-input"
          matInput
          appUnicode
        />
        <mat-error>
          <app-validation-message [field]="form.get('crRequestedByField')"></app-validation-message>
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div
    class="row input-grid"
    *ngIf="!hideDescriptionField"
  >
    <div class="col-md-12">
      <mat-form-field>
        <mat-label
          [translate]="domain + '/Change_Description'"
          data-testid="cr-details-change-description-label"
          [translateParams]="translateDefaultValue('Change Description')"
        ></mat-label>
        <input
          formControlName="comment"
          data-testid="cr-details-change-description-input"
          matInput
          appUnicode
        />
        <mat-error>
          <app-validation-message [field]="form.get('comment')"></app-validation-message>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
