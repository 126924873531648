<button
  data-testid="context-menu-more-vert-button"
  [matMenuTriggerFor]="menu"
  class="context-menu__menu-btn"
  mat-icon-button
  type="button"
>
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button
    data-testid="context-menu-view-button"
    (click)="handleMenuEvent(contextMenu.view, data.id, $event)"
    mat-menu-item
  >
    <i class="bi bi-eye-fill context-menu__icon"></i> View
  </button>
  <ng-container *ngIf="!onlyView">
    <button
      (click)="handleMenuEvent(contextMenu.edit, data.id, $event)"
      [disabled]="disableEdit"
      *appHasPermission="[{ module: this.module, privilege: privileges.update }]"
      data-testid="context-menu-edit-button"
      mat-menu-item
    >
      <i class="bi bi-pencil-fill context-menu__icon"></i> Edit
    </button>
  </ng-container>
  <ng-container *ngIf="!onlyView && !noDelete">
    <button
      data-testid="context-menu-delete-button"
      (click)="handleMenuEvent(contextMenu.delete, data.id, $event)"
      *appHasPermission="[{ module: this.module, privilege: privileges.delete }]"
      mat-menu-item
    >
      <i class="bi bi-trash3-fill context-menu__icon"></i> Delete
    </button>
  </ng-container>
  <ng-container *ngIf="showChangeStatusOption">
    <button
      data-testid="context-menu-change-status-button"
      (click)="handleMenuEvent(contextMenu.changeStatus, data.id, $event)"
      *appHasPermission="[{ module: this.module, privilege: privileges.update }]"
      mat-menu-item
    >
      <i class="bi bi-arrow-repeat context-menu__icon"></i> Change Status
    </button>
  </ng-container>
</mat-menu>
