<div class="confirm-modal-box">
  <div
    class="modal-header bulkupload-modal confirmation-edit-modal"
    id="confirmationModal"
  >
    <h4
      class="modal-title"
      id="modal-basic-title"
    >
      Are You Sure You Wish To Proceed With The Change?
    </h4>
    <button
      data-testid="confirmation-close-button"
      type="button"
      id="close-button"
      class="close"
      aria-label="Close"
      (click)="cancel()"
      (keydown.enter)="cancel()"
    >
      <i class="material-icons close-icon">close</i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row justify-content-center confirmation-message">
      <span
        >{{ settings.mainContent }}
        <strong>{{ settings.importantContent }}</strong>
        <span>{{ settings.warningContent }}</span>
      </span>
    </div>
    <div class="buttons-container">
      <button
        data-testid="confirmation-continue-button"
        type="button"
        mat-flat-button
        color="accent"
        appDisableButton
        (click)="confirm()"
        (keydown.enter)="confirm()"
      >
        Continue Anyway
      </button>
      <button
        data-testid="confirmation-cancel-button"
        type="button"
        mat-flat-button
        color="primary"
        (click)="cancel()"
        (keydown.enter)="cancel()"
      >
        Cancel
      </button>
    </div>
  </div>
  <div class="modal-footer"> </div>
</div>
