import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectionItemListComponent } from '../selection-item-list/selection-item-list.component';
import { translateDefaultValue } from 'src/app/modules/translations/missing-attribute-translation-handler';
import { DataAccessGroupSelectDataModel } from 'src/app/modules/admin/data-access-groups/models/data-access-group-select-data.model';
import { DataAccessGroupSelectGuidDataModel } from 'src/app/modules/admin/data-access-groups/models/data-access-group-select-guid-data.model';
import { GetUserGroupQueryResponse } from 'src/app/modules/admin/models/user-group.model';
import { SortSelectionType } from 'src/app/config/constant';
import { ServiceSubscriptionIrModel } from 'src/app/modules/portal/subscription/interfaces/service-subscription-ir.interface';

@Component({
  selector: 'app-search-item-list',
  templateUrl: './search-item-list.component.html',
  styleUrls: ['./search-item-list.component.scss'],
})
export class SearchItemListComponent implements OnInit {
  @Input() list: Array<DataAccessGroupSelectDataModel | DataAccessGroupSelectGuidDataModel | ServiceSubscriptionIrModel>;

  @Input() disabled: boolean;

  @Input() descriptionIcon: string;

  @Input() searchPlaceHolder: string;

  @Input() keyList: Array<string>;

  @Input() keyId: string;

  @Input() removeable: boolean = true;

  @Input() selection: boolean;

  @Input() filterList: Array<DataAccessGroupSelectDataModel | DataAccessGroupSelectGuidDataModel | GetUserGroupQueryResponse>;

  @Input() filterPlaceholder: string;

  @Input() filterByField: string;

  @Input() sortable: boolean;

  @Input() showStatus: boolean;

  @Output() removeClick = new EventEmitter<boolean>();

  public searchControl = new FormControl('');
  public filterControl = new FormControl('');
  public sortControl = new FormControl(SortSelectionType.atoz);
  public translateDefaultValue = translateDefaultValue;
  private listItem: Array<any> = [];
  public sortList: SortSelectionType[] = [SortSelectionType.atoz, SortSelectionType.ztoa];

  @ViewChild(SelectionItemListComponent) public selectList: SelectionItemListComponent;

  public ngOnInit(): void {
    this.listItem = this.list;
    this.sort();
  }

  public ngOnChanges(): void {
    if (this.sortable) {
      this.sort();
    }
  }

  public reset(): void {
    this.searchControl.setValue('');
  }

  public filter(): void {
    this.list = this.listItem.filter(item => item[this.filterByField].includes(this.filterControl.value));
  }

  public resetFilter(): void {
    this.list = this.listItem;
  }

  public sort(): void {
    switch (this.sortControl.value) {
      case SortSelectionType.atoz:
        this.list.sort((a, b) => (a[this.keyList[0]] < b[this.keyList[0]] ? -1 : 1));
        break;
      case SortSelectionType.ztoa:
        this.list.sort((a, b) => (a[this.keyList[0]] > b[this.keyList[0]] ? -1 : 1));
        break;
    }
  }
}
