export class CustomAttributesConstants {
  static readonly CONTROL_DATE_PICKER = 'Date';
  static readonly CONTROL_DROPDOWN_BOOLEAN = 'Drop Down, Boolean';
  static readonly CONTROL_DROPDOWN_MULTI_OPTION = 'Drop Down, Multiple';
  static readonly CONTROL_DROPDOWN_TWO_OPTION = 'Drop Down, Two options';
  static readonly CONTROL_INPUT_TEXT = 'Free Text';
  static readonly CONTROL_INPUT_EMAIL = 'Group Id';
  static readonly CONTROL_COMBO_BOX = 'Combo Box';
  static readonly CONTROL_CHIPS = 'Chips';

  static readonly DATA_BOOLEAN = 'Boolean';
  static readonly DATA_DATE = 'Date';
  static readonly DATA_STRING = 'String';

  static readonly LOGICAL_OPERATOR_AND = 'and';
  static readonly LOGICAL_OPERATOR_OR = 'or';

  static readonly RELATIONAL_OPERATOR_EQ = 'eq';
  static readonly RELATIONAL_OPERATOR_NE = 'ne';
  static readonly RELATIONAL_OPERATOR_LE = 'le';
  static readonly RELATIONAL_OPERATOR_LT = 'lt';
  static readonly RELATIONAL_OPERATOR_GE = 'ge';
  static readonly RELATIONAL_OPERATOR_GT = 'gt';

  static readonly RULE_ACCESS_OPERATOR = 'rules';
  static readonly RULE_OPERATOR_LOOKUP = 'lookup';
  static readonly RULE_OPERATOR_EXTERNAl_SERVICE = 'external';
  static readonly OPTIONS_ACCESS_OPERATOR = 'options';
  static readonly RULE_MATCH_MODE = 'strict';
  static readonly RULE_MAX_LENGTH = 'maxLength';

  static readonly DICTIONARY_VALUE = 'valueOptionId';
  static readonly STANDARD_VALUE = 'value';
  static readonly VALUE_ID = 'valueId';
}
