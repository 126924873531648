import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../shared/services/api-service';
import { ApiEndPoints } from 'src/app/config/api-end-points';
import { AuthenticationResponse } from '../../shared/models/rbac';
import { PrivilegesResponse } from '../../shared/models/rbac';
import { TokenRequest } from '../model/token-request.interface';
import { TokenResponse } from '../model/token-response.interface';
import { LocalStorageService } from './local-storage.service';
import { SessionStorageService } from './session-storage.service';
import { StorageItem, UserStorageItem } from '../model/storage-item';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
  ) {}

  public authorizeUser(): Observable<AuthenticationResponse> {
    return this.apiService.invokePostAPI<AuthenticationResponse>(ApiEndPoints.TOKEN.authorizeUser, null, true, false, true);
  }

  public getTokens(params: TokenRequest): Observable<TokenResponse> {
    return this.apiService.invokePostAPI<TokenResponse>(ApiEndPoints.TOKEN.allTokens, params, true, false, true);
  }

  public authorizeUserRoles(clientId: string): Observable<PrivilegesResponse> {
    return this.apiService.invokePostAPI<PrivilegesResponse>(
      ApiEndPoints.TOKEN.authorizeUserRoles,
      {
        TenantId: clientId,
      },
      false,
    );
  }

  public setUserProfile(data: AuthenticationResponse) {
    const user = this.localStorageService.getItemAsObject<UserStorageItem>(StorageItem.User) || {};
    user.profileId = data.userGuid;
    user.email = data.email;
    this.localStorageService.setItem(StorageItem.User, user);

    this.sessionStorageService.setItem('userName', data.fullName);
  }
}
