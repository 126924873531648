import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { CustomAttributesService } from '../services/custom-attributes.service';

@Directive({
  selector: '[appIsHidden]',
})
export class IsHiddenDirective implements OnInit {
  @Input('appIsHidden') public key: string | Array<string>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private customAttributeService: CustomAttributesService,
  ) {}

  public ngOnInit(): void {
    const customAttributesList = this.customAttributeService?.customAttributes;

    let keysArray = Array.isArray(this.key) ? this.key : [this.key];

    if (Array.isArray(customAttributesList) && customAttributesList.some(x => keysArray.includes(x.key) && x.isHidden)) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
