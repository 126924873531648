import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByAttributeTypeId',
})
export class FilterByAttributeTypeIdPipe implements PipeTransform {
  public transform(formArray: any[], filterTypeId: number): any[] {
    return formArray.filter(item => item.attributeTypeId === filterTypeId);
  }
}
