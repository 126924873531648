import { Pipe, PipeTransform } from '@angular/core';
import { CommonService } from '../services/common.service';

@Pipe({
  name: 'formattedDate',
})
export class FormattedDatePipe implements PipeTransform {
  constructor(private commonService: CommonService) {}

  transform(date: Date): string {
    return this.commonService.formatDateDDMMMYYYY(date);
  }
}
