import { BehaviorSubject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { LoggedInStatus } from './shared/models/rbac';
import { LoginStatusService } from './shared/services/login-status.service';
import { SessionService } from './shared/services/session.service';
import { SessionStorageService } from './core/services/session-storage.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isLoggedIn$: BehaviorSubject<LoggedInStatus>;
  public title: string = 'Data Catalogue Management Service';

  constructor(
    private loginStatusService: LoginStatusService,
    private sessionStorageService: SessionStorageService,
    public sessionService: SessionService    
  ) {
}

  public ngOnInit(): void {
    this.isLoggedIn$ = this.loginStatusService.userLoggedInStatus$;

    if (this.sessionStorageService.getItemAsObject<boolean>('isSessionActive')) {
      this.loginStatusService.userLoggedIn();
    }

    this.sessionService.initialize();
  }
}
