import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enabledModule',
})
export class EnabledModulePipe implements PipeTransform {
  transform(moduleToCheck: string, allModules: Array<string>): boolean {
    return allModules.includes(moduleToCheck);
  }
}
