import { APP_INITIALIZER, ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { HttpInterceptorService } from './interceptor/http-interceptor';
import { ProgressSpinnerInterceptor } from './interceptor/progress-spinner.interceptor';
import { SessionInterceptor } from './interceptor/session.interceptor';
import { CacheInterceptor } from './interceptor/cache.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalService } from '@azure/msal-angular';
import { HTTP_DYNAMIC_INTERCEPTORS } from '../shared/services/http.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { GlobalErrorHandler } from '../shared/services/error-handler.service';
import { SharedModule } from '../shared/shared.module';
import { InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../../environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { ToastrModule } from 'ngx-toastr';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../modules/translations/http-loader-factory';
import { ToastService } from '../shared/services/toast.service';
import { ApiService } from '../shared/services/api-service';
import { MissingAttributeTranslationHandler } from '../modules/translations/missing-attribute-translation-handler';
import { ToastrComponent } from './components/toastr/toastr.component';


export function msalInitializer(msalAuthService: MsalService): Function {  
  return () => msalAuthService.initialize();
}
export function loggerCallback(logLevel: LogLevel, message: string) {
}

@NgModule({
  declarations: [LoginComponent, LogoutComponent, ProgressSpinnerComponent, ToastrComponent],
  imports: [
    BackButtonDisableModule.forRoot(),
    FormsModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        // MSAL Configuration
        auth: {
          clientId: environment.config.clientId,
          authority: 'https://login.microsoftonline.com/' + environment.config.tenant,
          redirectUri: environment.config.redirectUri,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: true, // set to true for IE 11
        },
        system: {
          loggerOptions: {
            loggerCallback,
            logLevel: LogLevel.Info,
            piiLoggingEnabled: false,
          },
        },
      }),
      {
        interactionType: InteractionType.Redirect, // Msal Guard Configuration
        authRequest: {
          scopes: ['user.read'],
        },
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([['https://graph.microsoft.com/v1.0/me', ['user.read']]]),
      },
    ),
    NgbModule,
    SharedModule,
    ToastrModule.forRoot({
      toastComponent: ToastrComponent,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [ApiService, ToastService],
      },
      missingTranslationHandler: [
        {
          provide: MissingTranslationHandler,
          useClass: MissingAttributeTranslationHandler,
        },
      ],
    }),
  ],
  providers: [provideHttpClient(withInterceptorsFromDi()),
    { 
      provide: APP_INITIALIZER, 
      useFactory: msalInitializer, 
      deps: [MsalService], 
      multi: true 
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProgressSpinnerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_DYNAMIC_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    {
      provide: MAT_TABS_CONFIG,
      useValue: { animationDuration: '0ms' },
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  exports: [ProgressSpinnerComponent],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. You should only import Core modules in the AppModule only.');
    }
  }
}
