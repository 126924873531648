import { AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../base-component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchItemListComponent } from '../search-item-list/search-item-list.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { translateDefaultValue } from 'src/app/modules/translations/missing-attribute-translation-handler';
import { CommonService } from '../../services/common.service';
import { ServiceSubscriptionIrModel } from 'src/app/modules/portal/subscription/interfaces/service-subscription-ir.interface';
import { PeriodicityListModel } from 'src/app/modules/portal/subscription/interfaces/periodicity-list.interface';
import { SubscriptionService } from 'src/app/modules/portal/subscription/subscription.service';
import { filterNonSuccessfullAndEmptyResponses } from '../../operators/api-response-operators';
import { tap } from 'rxjs';

@Component({
  selector: 'app-select-list-irs-dialog',
  templateUrl: './select-list-irs-dialog.component.html',
  styleUrls: ['./select-list-irs-dialog.component.scss'],
})
export class SelectListIrsDialogComponent extends BaseComponent implements OnInit, AfterContentChecked {
  @Input() public list: Array<ServiceSubscriptionIrModel>;
  @Input() public disabled: boolean;
  @Input() public descriptionIcon: string[];
  @Input() public searchPlaceHolder: string;
  @Input() public keyList: Array<string>;
  @Input() public keyId: string;
  @Input() public removeable: boolean;
  @Input() public filterList: Array<ServiceSubscriptionIrModel>;
  @Input() public filterPlaceholder: string;
  @Input() public filterByField: string;
  @Input() public edit: boolean;
  @Input() public period: ServiceSubscriptionIrModel;

  public form: FormGroup;
  public frequencies: PeriodicityListModel[] = [];
  public translateDefaultValue = translateDefaultValue;
  public title: string;

  @ViewChild(SearchItemListComponent) public searchItemList: SearchItemListComponent;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private commonService: CommonService,
    private subscriptionService: SubscriptionService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.setTitle();
    this.createForm();
    this.getPeriodicityList();
    this.cdr.detach();
  }

  public ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  private setTitle(): void {
    this.title = this.edit ? 'Edit IR' : 'Add IR';
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      frequency: [null],
      period: new FormGroup({
        start: new FormControl(),
        end: new FormControl(),
      }),
    });
  }

  public close(): void {
    this.activeModal.close();
  }

  public addIr(): void {
    this.activeModal.dismiss(this.bindAdditionalData());
  }

  public editIr(): void {
    this.activeModal.dismiss(this.getIrDetails());
  }

  public bindAdditionalData(): ServiceSubscriptionIrModel[] {
    let selectedItems = this.searchItemList.selectList.selection.selectedOptions.selected.filter(i => i.value).map(o => o.value);
    selectedItems = JSON.parse(JSON.stringify(selectedItems));
    selectedItems.forEach(item => {
      Object.assign(item, this.getIrDetails());
    });

    return selectedItems;
  }

  public getIrDetails(): ServiceSubscriptionIrModel {
    let _frequency = this.form.value.frequency;
    let _periodStart = this.commonService.formatDate(this.form.value.period.start);
    let _periodEnd = this.commonService.formatDate(this.form.value.period.end);
    let _description = _periodStart && _periodEnd ? `${_periodStart} - ${_periodEnd}` : null;

    return { start: _periodStart, end: _periodEnd, frequency: _frequency, description: _description, details: _frequency?.name };
  }

  private getPeriodicityList(): void {
    this.subscriptionService
      .getPeriodicities()
      .pipe(
        filterNonSuccessfullAndEmptyResponses<PeriodicityListModel[]>(),
        tap(response => {
          this.frequencies = response.data;
        }),
      )
      .subscribe();
  }
}
