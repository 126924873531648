<div class="count-and-sort-container">
  <span class="dcms-mat-caption">Showing {{ gridCount }} of {{ totalCount }} results</span>
  <div
    *ngIf="isSortRequired"
    class="sort-wrapper"
  >
    <span
      [matMenuTriggerFor]="menu"
      class="dcms-mat-caption"
    >
      Sort By :
      <span class="sort_by">
        {{ !sortDefaultAction || sortDefaultAction === '' ? sortAction : sortDefaultAction }}
      </span>
      <i class="bi bi-caret-down-fill sort_by"></i>
    </span>

    <mat-menu #menu="matMenu">
      <button
        data-testid="count-sort-latest-button"
        (click)="setSortOrder(sortSelectionType.latest)"
        mat-menu-item
      >
        Latest
      </button>
      <button
        data-testid="count-sort-oldest-button"
        (click)="setSortOrder(sortSelectionType.oldest)"
        mat-menu-item
      >
        Oldest
      </button>
      <button
        data-testid="count-sort-A-Z-button"
        (click)="setSortOrder(sortSelectionType.atoz)"
        mat-menu-item
      >
        A-Z
      </button>
      <button
        data-testid="count-sort-Z-A-button"
        (click)="setSortOrder(sortSelectionType.ztoa)"
        mat-menu-item
      >
        Z-A
      </button>
    </mat-menu>
  </div>
</div>
