import { AttributeDisplayName } from '../../../core/model/attribute-display-name';
import { BaseComponent } from '../base-component';
import { ChangeRequest } from '../../models/request';
import { CommonService } from '../../services/common.service';
import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ContextMenuAction } from '../../../config/constant';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { ValidationPattern } from 'src/app/shared/constants/ValidationPattern';
import { map, takeUntil } from 'rxjs';
import { translateDefaultValue } from 'src/app/modules/translations/missing-attribute-translation-handler';

@Component({
  selector: 'app-cr-details',
  templateUrl: './cr-details.component.html',
  styleUrls: ['./cr-details.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CrDetailsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CrDetailsComponent),
      multi: true,
    },
  ],
})
export class CrDetailsComponent extends BaseComponent implements OnChanges, ControlValueAccessor {
  @Input() public actionType: ContextMenuAction;
  @Input() public alwaysDisabled: boolean = false;
  @Input() public defaultFields: Map<string, AttributeDisplayName>;
  @Input() public domain: string;
  @Input() public hideDescriptionField: boolean = false;
  @Input() public savedCRDetails: ChangeRequest;
  @Input() public showDetailsOn: ContextMenuAction[] = [ContextMenuAction.view];
  @Input() public touched: boolean;
  @Output() public crDetails: EventEmitter<ChangeRequest> = new EventEmitter<ChangeRequest>();

  public form: FormGroup = this.formBuilder.group({
    changeRequestNumberField: ['', [Validators.maxLength(14), Validators.pattern(/^CHG[0-9]*$/)]],
    crRequestedByField: [
      '',
      [Validators.required, Validators.maxLength(500)],
    ],
    changeRaisedOnField: ['', [Validators.required]],
    comment: ['', [Validators.maxLength(30)]],
  });
  public onTouched: any;
  public translateDefaultValue = translateDefaultValue;

  constructor(private commonService: CommonService, private formBuilder: FormBuilder) {
    super();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    void changes;
    if (this.actionType === ContextMenuAction.view) {
      this.form.disable();
    }

    if (this.showDetailsOn.includes(this.actionType) && this.savedCRDetails != null) {
      this.form.get('changeRequestNumberField').setValue(this.savedCRDetails.number ?? '');
      this.form.get('crRequestedByField').setValue(this.savedCRDetails.crRequestedBy ?? '');
      this.form.get('changeRaisedOnField').setValue(this.savedCRDetails.crCreatedOn ?? '');
      this.form.get('comment').setValue(this.savedCRDetails.comment ?? '');
    }

    if (this.touched) {
      this.form.markAllAsTouched();
    }
  }

  public ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map(formValue => ({
          number: formValue.changeRequestNumberField,
          crRequestedBy: formValue.crRequestedByField,
          crCreatedOn: this.commonService.formatDate(formValue.changeRaisedOnField),
          comment: formValue.comment,
        })),
      )
      .subscribe(data => this.crDetails.emit(data));
  }

  public registerOnChange(fn: any): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map(formValue => ({
          number: formValue.changeRequestNumberField,
          crRequestedBy: formValue.crRequestedByField,
          crCreatedOn: this.commonService.formatDate(formValue.changeRaisedOnField),
          comment: formValue.comment,
        })),
      )
      .subscribe(fn);
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  public validate() {
    return this.form.valid ? null : { invalid: true };
  }

  public writeValue(formValue: string): void {
    void formValue;
  }
}
