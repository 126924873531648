import { Pipe, PipeTransform } from '@angular/core';
import { DataAccessGroupSelectDataModel } from 'src/app/modules/admin/data-access-groups/models/data-access-group-select-data.model';
import { DataAccessGroupSelectGuidDataModel } from 'src/app/modules/admin/data-access-groups/models/data-access-group-select-guid-data.model';
import { ServiceSubscriptionIrModel } from 'src/app/modules/portal/subscription/interfaces/service-subscription-ir.interface';

@Pipe({
  name: 'filterList',
  pure: false,
})
export class FilterPipe implements PipeTransform {
  transform(
    list: Array<DataAccessGroupSelectDataModel | DataAccessGroupSelectGuidDataModel | ServiceSubscriptionIrModel>,
    input: string,
    keyList: Array<string>,
  ) {
    if (input) {
      return list.filter(item => {
        for (let key in keyList) {
          if (item[keyList[key]]?.toLowerCase().includes(input?.toLowerCase())) {
            return item;
          }
        }
      });
    }
    return list;
  }
}
