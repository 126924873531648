import { Injectable } from '@angular/core';
import { HttpBackend, HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PAANSService {
  constructor(private httpBackend: HttpBackend) {}

  checkPrivacyAcceptance(userId) {
    const httpClient = new HttpClient(this.httpBackend);
    const params = {
      region: environment.paanSetting.region,
      language: environment.paanSetting.language,
      appId: environment.paanSetting.appId,
      policyType: environment.paanSetting.policyType,
      userId: userId,
      acceptUrl: environment.paanSetting.acceptRedirectUrl,
      rejectUrl: environment.paanSetting.rejectRedirectUrl,
      includeContent: environment.paanSetting.includeContent,
      getContent: environment.paanSetting.getContent,
    };
    const token = btoa(params.appId + ':' + environment.paanSetting.secret);

    const headersPaan = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + token,
    });

    const options = { headers: headersPaan };
    return httpClient.post(environment.paanSetting.checkPrivacyAcceptanceUrl, params, options);
  }
}
