<div
  class="modal-header bulkupload-modal"
  id="extendSessionModal"
>
  <h4
    class="modal-title"
    id="modal-basic-title"
    >Your Session Is About To Expire!</h4
  >
  <button
    data-testid="es-modal-close-button"
    (click)="modal.dismiss()"
    aria-label="Close"
    class="close"
    id="close-button"
    type="button"
  >
    <i class="material-icons close-icon">close</i>
  </button>
</div>
<div class="modal-body">
  <p class="warning-message"> Note: If the session expires, all unsaved data will be lost. </p>
  <div class="session-expiry-message">
    Session will expire in <span>{{ secondsLeftForTimeout * 1000 | date : 'mm:ss' : 'UTC' }} mins</span>
  </div>
  <mat-progress-bar
    *ngIf="progressBarValue"
    [mode]="progressBarMode"
    [value]="progressBarValue"
    class="extend-session-progressbar"
  >
  </mat-progress-bar>
</div>
<div class="modal-footer">
  <button
    data-testid="es-modal-extend-session-button"
    (click)="extendSession()"
    color="accent"
    mat-flat-button
    type="button"
  >
    Extend Session
  </button>
</div>
