import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel, HttpTransportType, IHttpConnectionOptions } from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { StorageItem, TenantStorageItem, UserStorageItem } from 'src/app/core/model/storage-item';

@Injectable({
  providedIn: 'root',
})
export class SignalrService {
  private hubConnection: HubConnection;
  private newBulkUploads = new Subject<void>();
  private historicalBulkUploads = new Subject<void>();
  private newExports = new Subject<void>();
  private historicalExports = new Subject<void>();
  private newIntegrationRequests = new Subject<void>();
  private historicalIntegrationRequests = new Subject<void>();
  private notifications = new Subject<void>();
  newBulkUploads$ = this.newBulkUploads.asObservable();
  historicalBulkUploads$ = this.historicalBulkUploads.asObservable();
  newExports$ = this.newExports.asObservable();
  historicalExports$ = this.historicalExports.asObservable();
  newIntegrationRequests$ = this.newIntegrationRequests.asObservable();
  historicalIntegrationRequests$ = this.historicalIntegrationRequests.asObservable();
  notifications$ = this.notifications.asObservable();

  constructor(private localStorageService: LocalStorageService) {}

  public stop() {
    this.hubConnection.stop();
  }

  public init() {
    this.register();
    this.establishConnection();
    this.registerHandlers();
  }

  private hubOptions: IHttpConnectionOptions = {
    withCredentials: false,
    skipNegotiation: true,
    transport: HttpTransportType.WebSockets,
    accessTokenFactory: () => this.getRoleAccessToken(),
  };
  private register() {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(environment.key.BASE_API_URL + 'signalr-hub/notificationhub/?tenantName=' + this.getCurrentClientFromLocalStorage(), this.hubOptions)
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();
  }

  private establishConnection() {
    this.hubConnection
      .start()
      .then(() => {
        console.log('Hub connection started and state is: ', this.hubConnection.state);
      })
      .catch(e => {
        console.log('Error while establishing connection' + e);
      });
  }

  private registerHandlers() {
    this.hubConnection.on('newBulkUploads', msg => {
      console.log(`${msg.bulkUploadID} updated to ${msg.status}`);
      this.newBulkUploads.next();
    });

    this.hubConnection.on('historicalBulkUploads', msg => {
      console.log(`${msg.bulkUploadID} updated to ${msg.status}`);
      this.historicalBulkUploads.next();
    });

    this.hubConnection.on('newExports', msg => {
      console.log(`${msg.exportId} updated to ${msg.exportStatus}`);
      this.newExports.next();
    });

    this.hubConnection.on('historicalExports', msg => {
      console.log(`${msg.exportId} updated to ${msg.exportStatus}`);
      this.historicalExports.next();
    });

    this.hubConnection.on('newIntegrationRequests', msg => {
      console.log(`${msg.integrationRequestId} updated to ${msg.integrationRequestStatus}`);
      this.newIntegrationRequests.next();
    });

    this.hubConnection.on('historicalIntegrationRequests', msg => {
      console.log(`${msg.integrationRequestId} updated to ${msg.integrationRequestStatus}`);
      this.historicalIntegrationRequests.next();
    });

    this.hubConnection.on('notifications', msg => {
      this.notifications.next();
    });
  }

  private getCurrentClientFromLocalStorage(): string {
    const currentClient = this.localStorageService.getItemAsObject<TenantStorageItem>(StorageItem.Tenant)?.id;
    return currentClient || '';
  }

  private getRoleAccessToken(): string {
    return `${this.localStorageService.getItemAsObject<UserStorageItem>(StorageItem.User)?.roleAccessToken}`;
  }

  get signalRconnectionId(): string {
    return 'Hub connection state: ' + (this.hubConnection?.state ? this.hubConnection.state : null);
  }
}
