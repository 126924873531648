<ng-container *ngIf="options.length > 0; else exportBtn">
  <button
    data-testid="eb-button-1"
    class="export-button"
    color="accent"
    mat-flat-button
    [matMenuTriggerFor]="menu"
  >
    Export
    <i class="bi bi-caret-down-fill"></i>
  </button>
  <mat-menu #menu="matMenu">
    <ng-container *ngFor="let option of options">
      <app-export-button-option
        (optionChange)="onOptionChange($event)"
        [option]="option"
      >
      </app-export-button-option>
    </ng-container>
  </mat-menu>
</ng-container>
<ng-template #exportBtn>
  <button
    data-testid="eb-button-2"
    class="export-button"
    color="accent"
    mat-flat-button
    (click)="this.exportData.emit()"
  >
    Export
  </button>
</ng-template>
