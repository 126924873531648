import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  private encryptionKey: string;

  constructor() {}

  public encrypt = (text: string): string => {
    this.encryptionKey = atob(localStorage.getItem('encryptionKey'));
    let encryptedText: string;
    encryptedText = text ? CryptoJS.AES.encrypt(text, this.encryptionKey).toString() : text;
    return encryptedText;
  };

  public decrypt = (text: string): string => {
    this.encryptionKey = atob(localStorage.getItem('encryptionKey'));
    let decryptedText: string;
    decryptedText = text ? CryptoJS.AES.decrypt(text, this.encryptionKey).toString(CryptoJS.enc.Utf8) : text;
    return decryptedText;
  };
}
