<mat-form-field>
  <button
    [attr.data-testid]="testIdArea + '-' + testIdControlName + '-clear-button'"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    [appButtonClearOption]="formControl"
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-label [attr.data-testid]="testIdArea + '-' + testIdControlName + '-label'">
    {{ label }}
  </mat-label>
  <mat-select
    [attr.data-testid]="testIdArea + '-' + testIdControlName + '-value-select'"
    [formControl]="formControl"
    [required]="required"
  >
    <mat-option
      *ngIf="thresholdReached"
      [attr.data-testid]="testIdArea + '-' + testIdControlName + '-search-option'"
    >
      <ngx-mat-select-search
        noEntriesFoundLabel="No matching results"
        placeholderLabel="Search"
        [formControl]="searchControl"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option
      *ngFor="let option of options$ | async; trackBy: trackById"
      [attr.data-testid]="testIdArea + '-' + testIdControlName + '-option-' + option.title"
      [title]="option.title"
      [value]="option.value"
    >
      {{ option.title }}
    </mat-option>
  </mat-select>
  <mat-error>
    <app-validation-message [field]="formControl"></app-validation-message>
  </mat-error>
</mat-form-field>
