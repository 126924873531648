<mat-form-field appearance="fill">
  <mat-label data-testid="custom-attributes-chips-label">{{ label }}</mat-label>
  <mat-chip-grid
    #chipList
    aria-label="Accounts"
    [formControl]="formControl"
    [required]="required"
  >
    <mat-chip-row
      *ngFor="let chip of chipsArray"
      (removed)="remove(chip)"
      [class.disabled-chip]="formControl.disabled"
    >
      {{ chip.name }}
      <button
        matChipRemove
        [hidden]="formControl.disabled"
      >
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input
      *ngIf="!formControl.disabled || chipsArray?.length === 0"
      placeholder="{{ 'New ' + this.label }}"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)"
      (blur)="onTouched()"
    />
  </mat-chip-grid>
  <mat-error>
    <app-validation-message [field]="this.formControl"></app-validation-message>
  </mat-error>
</mat-form-field>
