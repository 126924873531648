<div class="row">
  <div class="col-md-12 search-list-item__container">
    <div class="search-list-item__search-box">
      <mat-form-field class="search-list-item__search">
        <mat-label data-testid="search-item-list-search-label">Search by {{ searchPlaceHolder }}</mat-label>
        <input
          data-testid="search-item-list-search-input"
          [formControl]="searchControl"
          matInput
          type="text"
        />
        <mat-icon
          *ngIf="!searchControl.value"
          data-testid="search-item-list-search-icon"
          matSuffix
        >
          search
        </mat-icon>
        <mat-icon
          (click)="reset()"
          data-testid="search-item-list-close-icon"
          *ngIf="searchControl.value"
          matSuffix
        >
          close
        </mat-icon>
        <mat-error>
          <app-validation-message [field]="searchControl"></app-validation-message>
        </mat-error>
      </mat-form-field>
      <mat-form-field
        class="search-list-item__filter"
        *ngIf="filterList?.length > 0"
      >
        <mat-label
          [translate]="filterPlaceholder"
          data-testid="search-item-list-filter-label"
          [translateParams]="translateDefaultValue(filterPlaceholder)"
        ></mat-label>
        <mat-select
          data-testid="search-item-list-filter-select"
          [formControl]="filterControl"
        >
          <mat-option
            *ngFor="let item of filterList"
            [attr.data-testid]="'search-item-list-filter-select-' + item[keyList[0]]"
            [title]="item[keyList[0]]"
            [value]="item[keyId]"
            (click)="filter()"
          >
            {{ item[keyList[0]] }}
          </mat-option>
        </mat-select>
        <button
          [appButtonClearOption]="filterControl"
          aria-label="Clear"
          data-testid="search-item-list-filter-select-clear-button"
          mat-icon-button
          matSuffix
          (click)="resetFilter()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field
        class="search-list-item__sorter"
        *ngIf="sortable"
      >
        <mat-label
          translate="Sort By"
          data-testid="search-item-list-sort-label"
          [translateParams]="translateDefaultValue('Sort By')"
        ></mat-label>
        <mat-select
          data-testid="search-item-list-sort-select"
          [formControl]="sortControl"
        >
          <mat-option
            *ngFor="let item of sortList | keyvalue"
            [attr.data-testid]="'search-item-list-sort-select-' + item.value"
            [value]="item.value"
            (click)="sort()"
          >
            {{ item.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <app-common-item-list
      *ngIf="!selection"
      [list]="list"
      [disabled]="disabled"
      [descriptionIcon]="descriptionIcon"
      [keyList]="keyList"
      [searchValue]="searchControl.value"
      [keyId]="keyId"
      [removeable]="removeable"
      (removeClick)="removeClick.emit()"
    ></app-common-item-list>
    <app-selection-item-list
      *ngIf="selection"
      [list]="list"
      [disabled]="disabled"
      [descriptionIcon]="descriptionIcon"
      [keyList]="keyList"
      [searchValue]="searchControl.value"
      [keyId]="keyId"
      [showStatus]="showStatus"
    ></app-selection-item-list>
  </div>
</div>
