import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SortBy, SortSelectionType } from '../../../config/constant';

@Component({
  selector: 'app-count-sort',
  templateUrl: './count-sort.component.html',
  styleUrls: ['./count-sort.component.scss'],
})
export class CountSortComponent {
  @Input() public gridCount: number;
  @Input() public isSortRequired: boolean = true;
  @Input() public sortDefaultAction: string;
  @Input() public totalCount: number;
  @Output() public selectedSortingDetails: EventEmitter<SortBy> = new EventEmitter<SortBy>();

  sortSelectionType: typeof SortSelectionType = SortSelectionType;
  public sortAction: string = SortSelectionType.latest;

  public setSortOrder(sortAction: SortSelectionType) {
    switch (sortAction) {
      case SortSelectionType.latest:
        this.sortAction = SortSelectionType.latest;
        this.selectedSortingDetails.emit(SortBy.createdDesc);
        break;
      case SortSelectionType.oldest:
        this.sortAction = SortSelectionType.oldest;
        this.selectedSortingDetails.emit(SortBy.createdAsc);
        break;
      case SortSelectionType.atoz:
        this.sortAction = SortSelectionType.atoz;
        this.selectedSortingDetails.emit(SortBy.nameAsc);
        break;
      case SortSelectionType.ztoa:
        this.sortAction = SortSelectionType.ztoa;
        this.selectedSortingDetails.emit(SortBy.nameDesc);
        break;
    }
  }
}
