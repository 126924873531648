import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserRolesService } from '../services/user-roles.service';
import { Permission } from '../models/rbac';

@Directive({
  selector: '[appHasPermission]',
})
export class HasPermissionDirective {
  @Input() appHasPermission: Array<Permission>;

  constructor(private viewContainerRef: ViewContainerRef, private templateRef: TemplateRef<any>, private userRolesService: UserRolesService) {}

  ngOnChanges(): void {
    this.apply();
  }

  ngOnInit(): void {
    this.apply();
  }

  private apply(): void {
    const isUserEnabled: boolean = !!this.userRolesService.userPermissions.filter((permission: Permission) =>
      this.appHasPermission.some(this.userRolesService.arePermissionsEqualPredicate(permission)),
    ).length;

    this.viewContainerRef.clear();
    if (isUserEnabled) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
