import { Component, Input } from '@angular/core';
import { interval } from 'rxjs';
import { LoginService } from '../../services/login.service';
import { SessionService } from '../../services/session.service';
import { BaseComponent } from '../base-component';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-extend-session-modal',
  templateUrl: './extend-session-modal.component.html',
  styleUrls: ['./extend-session-modal.component.scss'],
})
export class ExtendSessionModalComponent extends BaseComponent {
  private maxExtendIntervalInSeconds = environment.sessionSetting.extendSessionPopupBeforeTimeoutInMinutes * 60;

  @Input() public modal: any;

  public progressBarMode: string = 'determinate';
  public progressBarValue: number = 0;
  public secondsLeftForTimeout: number = 0;

  constructor(private loginService: LoginService, private sessionService: SessionService, private localStorageService: LocalStorageService) {
    super();

    const idleTimeoutDateTime = new Date(parseInt(this.localStorageService.getItem('lastAction')));
    idleTimeoutDateTime.setMinutes(idleTimeoutDateTime.getMinutes() + environment.sessionSetting.idleTimeoutInMinutes);

    const timerPerSecond$ = interval(1000);
    const progressBarTimer = timerPerSecond$.subscribe(() => {
      let currentDateTime = new Date();
      let timeLeftInSeconds = (idleTimeoutDateTime.getTime() - currentDateTime.getTime()) / 1000;
      this.progressBarValue = ((this.maxExtendIntervalInSeconds - timeLeftInSeconds) / this.maxExtendIntervalInSeconds) * 100;
      this.secondsLeftForTimeout = timeLeftInSeconds;

      if (currentDateTime > idleTimeoutDateTime) {
        progressBarTimer.unsubscribe();
        this.modal.dismiss();
      }
    });
  }

  public extendSession() {
    this.loginService
      .extendUserSession()
      .pipe(
        tap(() => {
          this.sessionService.triggerSessionExtensionPopup.next(false);
          this.modal.dismiss();
        }),
      )
      .subscribe();
  }
}
