import { ApiResponse } from 'src/app/shared/models/response/api-response';
import { AppLanguage } from 'src/app/config/constant';
import { AuthenticationResponse, Permission } from '../../../shared/models/rbac';
import { CacheKeys } from 'src/app/config/cache-keys';
import { Component, OnInit } from '@angular/core';
import { LoginStatusService } from '../../../shared/services/login-status.service';
import { ModalDialogClass } from '../../../shared/constants/modal-dialog-class.enum';
import { MsalService } from '@azure/msal-angular';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PAANSService } from '../../../shared/services/paans.service';
import { PrivilegesResponse } from '../../../shared/models/rbac';
import { ProgressSpinnerService } from '../../../shared/services/progress-spinner.service';
import { Router } from '@angular/router';
import { SessionModalComponent } from 'src/app/shared/components/session-modal/session-modal.component';
import { SignalrService } from 'src/app/shared/services/signalr.service';
import { TenantsResponse } from '../../../shared/models/rbac';
import { TenantsService } from '../../../shared/services/tenants.service';
import { TokenService } from '../../services/token-service';
import { TranslateService } from '@ngx-translate/core';
import { UserRolesService } from '../../../shared/services/user-roles.service';
import { ValidateSessionsResponse } from 'src/app/shared/models/rbac/validate-session.model';
import { catchError } from 'rxjs/operators';
import { dateToEpochTimeString } from 'src/app/shared/services/date.service';
import { environment } from 'src/environments/environment';
import { of, switchMap } from 'rxjs';
import { CustomAttributesService } from 'src/app/shared/services/custom-attributes.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { Modules } from 'src/app/shared/constants/modules.enum';
import { Privileges } from 'src/app/shared/constants/privileges.enum';
import { SessionStorageService } from '../../services/session-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public clientList: TenantsResponse[];
  clientID;
  public userExistsInCommonDatabase = true;
  public userHasTenants: boolean = true;
  public userExistsInTenantDatabase = true;
  user = '';
  isClientSelected = false;
  public heroText = environment.heroText;
  public heroImage = '../../../../assets/images/' + environment.heroImage;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    modalDialogClass: ModalDialogClass.ExtendSession,
  };
  private adToken: string;
  private userName: string;

  constructor(
    private customAttributeService: CustomAttributesService,
    private loginStatusService: LoginStatusService,
    private msalService: MsalService,
    private paansService: PAANSService,
    private tokenService: TokenService,
    private tenantsService: TenantsService,
    private signalrService: SignalrService,
    private userRolesService: UserRolesService,
    private router: Router,
    private modalService: NgbModal,
    private progressSpinnerService: ProgressSpinnerService,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
  ) {}

  ngOnInit(): void {
    this.progressSpinnerService.startLoading();
    this.clientList = [];
    this.clientID = '--select--';
    this.getClientList();
  }

  getClientList() {
    const requestObj = {
      scopes: ['user.read'],
      account: this.msalService.instance.getAllAccounts()[0],
    };
    this.msalService
      .acquireTokenSilent(requestObj)
      .pipe(
        switchMap(response => {
          this.userName = response.account.username;
          this.adToken = response.accessToken;
          this.localStorageService.setItem('msalToken', response.accessToken);
          return this.paansService.checkPrivacyAcceptance(this.userName);
        }),
        switchMap((data: any) => {
          if (!data.hasAccepted) {
            window.location.href = environment.paanSetting.baseUrl + data.redirectUrl;
            return of(data);
          } else {
            return this.tokenService.authorizeUser();
          }
        }),
        catchError(() => {
          this.progressSpinnerService.stopLoading();
          return of(null);
        }),
        switchMap((data: AuthenticationResponse | null) => {
          if (data && data.accessToken) {
            this.user = data.fullName;
            this.localStorageService.setItem('userFullName', data.fullName);
            this.clientList = data.allowedTenants;
            localStorage.setItem('encryptionKey', data.encryptionKey);
            this.localStorageService.setItem(CacheKeys.roleAccessToken, data.accessToken);
            this.localStorageService.setItem(CacheKeys.refreshToken, data.refreshToken);
            this.localStorageService.setItem('userID', data.id);
            this.localStorageService.setItem(CacheKeys.refreshTokenExpirationTime, dateToEpochTimeString(data.refreshTokenValidTo));
            this.tokenService.setUserProfile(data);
            this.tenantsService.setTenantsDictionary(data.allowedTenants);
          } else {
            this.userExistsInCommonDatabase = false;
          }

          this.userHasTenants = !!data?.allowedTenants?.length;

          return of(data);
        }),
      )
      .subscribe();
    this.clearIndexLoader();
  }

  clientSelected() {
    if (this.isClientSelected) {
      this.tenantsService.validateSessions({ userEmailAddress: this.userName }).subscribe((data: ApiResponse<ValidateSessionsResponse>) => {
        if (data.data.hasOpenSessions) {
          this.openPopUp();
        } else {
          this.login();
        }
      });
    }
  }

  login(): void {
    let selectedClient = this.clientList.filter(client => client.tenantId === this.clientID)[0];
    this.localStorageService.setItem('clientName', selectedClient.name);
    this.localStorageService.setItem('clientId', selectedClient.tenantId);

    this.tokenService
      .authorizeUserRoles(this.clientID)
      .pipe(
        switchMap((data: PrivilegesResponse) => {
          this.userRolesService.userModulesPrivileges = data.modulePrivileges;
          this.localStorageService.setItem('roleAccessToken', data.accessToken);
          this.sessionStorageService.setItem('IsSessionActive', true);
          this.loginStatusService.userLoggedIn();
          return this.translateService.use(AppLanguage.en);
        }),
      )
      .subscribe(_ => {
        this.customAttributeService.loadCustomization();
        this.router.navigate(['app/dashboard']);
      });
  }

  clientDropdownChange(value) {
    if (value !== '--select--') {
      this.isClientSelected = true;
      this.userExistsInTenantDatabase = true;
      this.signalrService.setSelectedClient = value;
      this.localStorageService.setItem('selectedClient', value);
    }
  }

  clearIndexLoader() {
    const loader = document.getElementById('indexLoader');
    if (loader) {
      loader.style.display = 'none';
    }
  }

  public openPopUp() {
    this.modalService.open(SessionModalComponent, this.modalOptions).closed.subscribe((data: boolean) => {
      if (data) {
        this.login();
      }
    });
  }
}
