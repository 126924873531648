import { ApiResponse } from 'src/app/shared/models/response/api-response';
import { AppLanguage } from 'src/app/config/constant';
import { ApplicationStorageItem, StorageItem, TenantStorageItem, UserStorageItem } from '../../model/storage-item';
import { AuthenticationResponse, PrivilegesResponse } from '../../../shared/models/rbac';
import { BehaviorSubject, of, switchMap } from 'rxjs';
import { catchError, filter, take, tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CookiesModalComponent } from 'src/app/shared/components/cookies-modal/cookies-modal.component';
import { CustomAttributesService } from 'src/app/shared/services/custom-attributes.service';
import { dateToEpochTimeString } from 'src/app/shared/services/date.service';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../../services/local-storage.service';
import { LoginStatusService } from '../../../shared/services/login-status.service';
import { ModalDialogClass } from '../../../shared/constants/modal-dialog-class.enum';
import { MsalService } from '@azure/msal-angular';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PAANSService } from '../../../shared/services/paans.service';
import { ProgressSpinnerService } from '../../../shared/services/progress-spinner.service';
import { Router } from '@angular/router';
import { SessionModalComponent } from 'src/app/shared/components/session-modal/session-modal.component';
import { SessionStorageService } from '../../services/session-storage.service';
import { TenantsService } from '../../../shared/services/tenants.service';
import { TokenService } from '../../services/token-service';
import { TranslateService } from '@ngx-translate/core';
import { UserRolesService } from '../../../shared/services/user-roles.service';
import { ValidateSessionsResponse } from 'src/app/shared/models/rbac/validate-session.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  private consentCookieName = 'dcms-cookie-consent';
  private cookieConsent$: BehaviorSubject<boolean>;
  private cookieModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    modalDialogClass: ModalDialogClass.CookieConsent,
  };
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    modalDialogClass: ModalDialogClass.ExtendSession,
  };
  private userName: string;

  public applicationData: Partial<AuthenticationResponse> = {
    allowedTenants: [],
  };
  public clientID;
  public heroText = environment.heroText;
  public isClientSelected = false;
  public userExistsInCommonDatabase = true;
  public userExistsInTenantDatabase = true;
  public userHasTenants$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private customAttributeService: CustomAttributesService,
    private loginStatusService: LoginStatusService,
    private msalService: MsalService,
    private paansService: PAANSService,
    private tokenService: TokenService,
    private tenantsService: TenantsService,
    private userRolesService: UserRolesService,
    private router: Router,
    private modalService: NgbModal,
    private progressSpinnerService: ProgressSpinnerService,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private cookieService: CookieService,
  ) {
    this.cookieConsent$ = new BehaviorSubject<boolean>(this.cookieService.check(this.consentCookieName));

    this.userHasTenants$
      .asObservable()
      .pipe(
        filter(hasTenants => !hasTenants),
        tap(() => {
          localStorageService.clear();
          sessionStorageService.clear();
        }),
        take(1),
      )
      .subscribe();
  }

  public clearIndexLoader() {
    const loader = document.getElementById('indexLoader');
    if (loader) {
      loader.style.display = 'none';
    }
  }

  public clientDropdownChange(value) {
    if (value !== '--select--') {
      this.isClientSelected = true;
      this.userExistsInTenantDatabase = true;

      const tenant = this.localStorageService.getItemAsObject<TenantStorageItem>(StorageItem.Tenant) || {};
      tenant.id = value.toString();
      this.localStorageService.setItem(StorageItem.Tenant, tenant);
    }
  }

  public clientSelected(): void {
    of({ isClientSelected: this.isClientSelected, userName: this.userName })
      .pipe(
        filter(payload => payload.isClientSelected),
        switchMap(payload => this.tenantsService.validateSessions({ userEmailAddress: payload.userName })),
        tap((data: ApiResponse<ValidateSessionsResponse>) => {
          if (data.data.hasOpenSessions) {
            this.concurrentSessionConfirmation();
          } else {
            this.storeApplicationData();
            this.login();
          }
        }),
      )
      .subscribe();
  }

  public concurrentSessionConfirmation() {
    this.modalService
      .open(SessionModalComponent, this.modalOptions)
      .closed.pipe(
        filter((isConfirmed: boolean) => isConfirmed),
        tap(() => {
          this.storeApplicationData();
          this.login();
        }),
      )
      .subscribe();
  }

  public getClientList() {
    const requestObj = {
      scopes: ['user.read'],
      account: this.msalService.instance.getAllAccounts()[0],
    };
    this.msalService
      .acquireTokenSilent(requestObj)
      .pipe(
        switchMap(response => {
          this.userName = response.account.username;
          this.localStorageService.setItem('msalToken', response.accessToken);
          return this.paansService.checkPrivacyAcceptance(this.userName);
        }),
        switchMap((data: any) => {
          if (!data.hasAccepted) {
            window.location.href = environment.paanSetting.baseUrl + data.redirectUrl;
            return of(data);
          } else {
            return this.tokenService.authorizeUser();
          }
        }),
        catchError(() => {
          this.progressSpinnerService.stopLoading();
          return of(null);
        }),
        switchMap((data: AuthenticationResponse | null) => {
          if (data && data.accessToken) {
            this.applicationData = data;
          } else {
            this.userExistsInCommonDatabase = false;
          }

          this.userHasTenants$.next(!!data?.allowedTenants?.length);

          return of(data);
        }),
      )
      .subscribe();
    this.clearIndexLoader();
  }

  public login(): void {
    let selectedClient = this.applicationData.allowedTenants.filter(client => client.tenantId === this.clientID)[0];

    const tenant = this.localStorageService.getItemAsObject<TenantStorageItem>(StorageItem.Tenant) || {};
    tenant.name = selectedClient.name;
    this.localStorageService.setItem(StorageItem.Tenant, tenant);

    this.tokenService
      .authorizeUserRoles(this.clientID)
      .pipe(
        switchMap((data: PrivilegesResponse) => {
          this.userRolesService.userModulesPrivileges = data.modulePrivileges;

          const user = this.localStorageService.getItemAsObject<UserStorageItem>(StorageItem.User) || {};
          user.roleAccessToken = data.accessToken;

          this.localStorageService.setItem(StorageItem.User, user);
          this.sessionStorageService.setItem('isSessionActive', true);
          this.loginStatusService.userLoggedIn();
          return this.translateService.use(AppLanguage.en);
        }),
      )
      .subscribe(_ => {
        this.customAttributeService.loadCustomization();
        this.router.navigate(['app/dashboard']);
      });
  }

  public ngOnInit(): void {
    this.clientID = '--select--';
    this.getClientList();

    this.cookieConsent$
      .pipe(
        filter(isConsentAlreadyAccepted => !isConsentAlreadyAccepted),
        switchMap(() => this.modalService.open(CookiesModalComponent, this.cookieModalOptions).closed),
        filter((isConsentAccepted: boolean) => isConsentAccepted),
        tap(() => {
          const oneYearFromNow = new Date();
          oneYearFromNow.setFullYear(oneYearFromNow.getUTCFullYear() + 1);
          this.cookieService.set(this.consentCookieName, new Date().toISOString(), oneYearFromNow, '/', null, true, 'Strict');
        }),
      )
      .subscribe();
  }

  private storeApplicationData() {
    localStorage.setItem('encryptionKey', this.applicationData.encryptionKey);

    const application = this.localStorageService.getItemAsObject<ApplicationStorageItem>(StorageItem.Application) || {};
    application.refreshToken = this.applicationData.refreshToken;
    application.refreshAccessTokenExpTime = dateToEpochTimeString(this.applicationData.refreshTokenValidTo);
    this.localStorageService.setItem(StorageItem.Application, application);

    const user = this.localStorageService.getItemAsObject<UserStorageItem>(StorageItem.User) || {};
    user.id = this.applicationData.id;
    user.roleAccessToken = this.applicationData.accessToken;
    user.fullName = this.applicationData.fullName;
    this.localStorageService.setItem(StorageItem.User, user);

    this.tokenService.setUserProfile(this.applicationData as AuthenticationResponse);
    this.tenantsService.setTenantsDictionary(this.applicationData.allowedTenants);
  }
}
