import { Injectable } from '@angular/core';
import { ApiEndPoints } from 'src/app/config/api-end-points';
import { UploadFileRequestModel } from 'src/app/shared/models/upload-file-model';
import { MsApiService } from './ms-api.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class UploadFileService {
  constructor(private apiService: MsApiService, private localStorageService: LocalStorageService) {}

  public uploadFile(selectedFile: any, featureName: string) {
    const formData: FormData = new FormData();

    if (selectedFile) {
      const bulkUploadDto: UploadFileRequestModel = {
        createdBy: this.localStorageService.getItem('profileId'),
        createdOn: new Date().toISOString(),
        featureName: featureName,
        fileExtension: 'xlsx',
        fileName: selectedFile.name,
        userCreatedByEmail: this.localStorageService.getItem('userEmail'),
      };

      formData.append('uploadedFile', selectedFile);
      formData.append('bulkUploadDto', JSON.stringify(bulkUploadDto));
    }

    const httpOptions = {
      headers: {
        dontSetContentType: 'true',
      },
    };

    return this.apiService.postFileData(ApiEndPoints.BULK_UPLOAD.upload, formData, httpOptions);
  }
}
