import {
  GeographicalFilterModel,
  GeographicalSearchFilterModel,
  GeographicalUnitFiltersDataModel,
  GeographicalUnitHierarchyDropdownContent,
  GeographicalUnitOverViewModel,
} from '../../../core/model/geographical-unit.model';
import {
  GetOrganizationalUnitsFiltersQueryResponse,
  GetOrganizationalUnitsQueryResponse,
  OrganizationalHierarchyListResponse,
  OrganizationalUnitCreateModel,
  OrganizationalUnitOverViewModel,
  OrganizationalUnitOverviewRequest,
  OrganizationUnitHierarchyDropdownContent,
} from '../../../core/model/organizational-unit';
import { ApiEndPoints } from '../../../config/api-end-points';
import { ApiResponse } from '../../../shared/models/response';
import { CustomAttributeModel } from '../../../shared/models/custom-attribute';
import { DataSourceRequestModel } from '../../../shared/models/request';
import { DataSourceResult } from '../../../shared/models/data-source-result';
import { DefaultSortDirection } from '../../../shared/enums/default-sort-direction.enum';
import { DropdownOption } from '../../../shared/models/searchable-dropdown/dropdown-option.model';
import { GeographicalUnitSortRules } from './geo-unit/enums/geographical-unit-sort-rules.enum';
import { GetGeographicalUnitsFiltersQueryResponse } from 'src/app/core/model/geographical-unit/get-geographical-units-filters-query-response.model';
import { GetGeographicalUnitsQueryResponse } from 'src/app/core/model/geographical-unit/get-geographical-units-query-response.model';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsApiService } from '../../../shared/services/ms-api.service';
import { Observable, shareReplay, Subject, tap } from 'rxjs';
import { OrganisationUnitListModel } from '../service-instance/interfaces/organisation-unit-list.interface';
import { OrganizationalUnitSortRules } from './organizational-unit/enums/organizational-unit-sort-rules.enum';
import { RequestModel } from '../../../shared/models/request-response.model';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private updatedDataSubject$ = new Subject<void>();

  public get finishedUpdatingData(): Observable<void> {
    return this.updatedDataSubject$.asObservable();
  }

  constructor(private msApiService: MsApiService) {}

  public addGeographicalUnit(requestModel: RequestModel) {
    const configUrl = ApiEndPoints.GEOGRAPHICAL_UNIT.geographicalUnitCreate;
    return this.msApiService.post(configUrl, requestModel).pipe(tap(response => this.updatedDataSubject$.next()));
  }

  public addOrganizationalUnit(requestModel: RequestModel) {
    const configUrl = ApiEndPoints.ORGANIZATIONAL_UNIT.organizationalUnitCreate;
    return this.msApiService.post(configUrl, requestModel).pipe(tap(response => this.updatedDataSubject$.next()));
  }

  public deleteGeographicalUnit(requestModel: RequestModel) {
    const configUrl = ApiEndPoints.GEOGRAPHICAL_UNIT.geographicalUnitDelete;
    return this.msApiService.post(configUrl, requestModel).pipe(tap(response => this.updatedDataSubject$.next()));
  }

  public deleteOrganizationalUnit(requestModel: RequestModel) {
    const configUrl = ApiEndPoints.ORGANIZATIONAL_UNIT.organizationalUnitDelete;
    return this.msApiService.post(configUrl, requestModel).pipe(tap(response => this.updatedDataSubject$.next()));
  }

  public downloadTemplateGeographicalUnit(param): Observable<HttpResponse<Blob>> {
    const configUrl = ApiEndPoints.GEOGRAPHICAL_UNIT.geographicalUnitDownloadTemplate;

    const httpOptions = {
      observe: 'response',
      responseType: 'blob',
    };

    return this.msApiService.getFileData(configUrl, param, httpOptions);
  }

  public downloadTemplateOrganizationUnit(param): Observable<HttpResponse<Blob>> {
    const configUrl = ApiEndPoints.ORGANIZATIONAL_UNIT.organizationUnitDownloadTemplate;

    const httpOptions = {
      observe: 'response',
      responseType: 'blob',
    };
    return this.msApiService.getFileData(configUrl, param, httpOptions);
  }

  public getGeographicalCustomAttributes(): Observable<ApiResponse<CustomAttributeModel[]>> {
    const configUrl = ApiEndPoints.GEOGRAPHICAL_UNIT.geographicalUnitCustomAttributes;
    return this.msApiService.post<CustomAttributeModel[]>(configUrl);
  }

  public getGeographicalFilters(geographicalFilterModel: GeographicalFilterModel) {
    const configUrl = ApiEndPoints.GEOGRAPHICAL_UNIT.geographicalUnitFilters;
    return this.msApiService.post<GeographicalUnitFiltersDataModel>(configUrl, geographicalFilterModel);
  }

  public getGeographicalUnitDetailsByID(id: number) {
    const configUrl = ApiEndPoints.GEOGRAPHICAL_UNIT.geographicalUnitDetailsByID;
    let geographicalUnitID: string = id?.toString();
    return this.msApiService.get(configUrl, { geographicalUnitID });
  }

  public getGeographicalUnitList(params) {
    const configUrl = ApiEndPoints.GEOGRAPHICAL_UNIT.geographicalUnitList;
    return this.msApiService.post<any[]>(configUrl, params);
  }

  public getGeographicalUnitOverview(
    geographicalSearchFilterModel: GeographicalSearchFilterModel,
  ): Observable<ApiResponse<GeographicalUnitOverViewModel[]>> {
    const configUrl = ApiEndPoints.GEOGRAPHICAL_UNIT.geographicalUnitOverview;
    return this.msApiService.post<GeographicalUnitOverViewModel[]>(configUrl, geographicalSearchFilterModel);
  }

  public getGeographicalUnitPotentialParentsForExistingUnit(param): Observable<ApiResponse<GeographicalUnitHierarchyDropdownContent[]>> {
    const configUrl = ApiEndPoints.GEOGRAPHICAL_UNIT.geographicalUnitPotentialParentsForExistingUnit;
    return this.msApiService.post<OrganizationUnitHierarchyDropdownContent[]>(configUrl, param);
  }

  public getGeographicalUnitType() {
    const configUrl = ApiEndPoints.GEOGRAPHICAL_UNIT.geographicalUnitType;
    return this.msApiService.get<any[]>(configUrl);
  }

  public getHierarchiesList(): Observable<ApiResponse<Array<OrganizationalHierarchyListResponse>>> {
    const configUrl = ApiEndPoints.GEOGRAPHICAL_UNIT.geographicalUnitHierarchyList;
    return this.msApiService.post<Array<OrganizationalHierarchyListResponse>>(configUrl);
  }

  public getOrgUnitDetailsByID(orgUnitID: number): Observable<ApiResponse<OrganizationalUnitCreateModel>> {
    const configUrl = ApiEndPoints.ORGANIZATIONAL_UNIT.organizationalUnitDetailsByID;
    return this.msApiService.get<OrganizationalUnitCreateModel>(configUrl, { orgUnitID: orgUnitID.toString() });
  }

  public getOrgUnitTypeList() {
    const configUrl = ApiEndPoints.ORG_UNIT_TYPE.orgUnitTypeList;
    return this.msApiService.get(configUrl);
  }

  public getOrganizationCustomAttributes(): Observable<ApiResponse<CustomAttributeModel[]>> {
    const configUrl = ApiEndPoints.ORGANIZATIONAL_UNIT.organizationUnitCustomAttributes;
    return this.msApiService.post<CustomAttributeModel[]>(configUrl);
  }

  public getOrganizationFilters(
    request: DataSourceRequestModel<OrganizationalUnitSortRules, DefaultSortDirection>,
  ): Observable<ApiResponse<GetOrganizationalUnitsFiltersQueryResponse>> {
    return this.msApiService.post<GetOrganizationalUnitsFiltersQueryResponse>(
      ApiEndPoints.ORGANIZATIONAL_UNIT.getOrganizationalUnitsFilters,
      request,
    );
  }

  public getOrganizationalUnits(
    request: DataSourceRequestModel<OrganizationalUnitSortRules, DefaultSortDirection>,
  ): Observable<ApiResponse<GetOrganizationalUnitsQueryResponse>> {
    return this.msApiService.post<GetOrganizationalUnitsQueryResponse>(ApiEndPoints.ORGANIZATIONAL_UNIT.getOrganizationalUnits, request);
  }

  public getOrganizationUnitDetails(
    organizationSearchFilterModel: OrganizationalUnitOverviewRequest,
  ): Observable<ApiResponse<OrganizationalUnitOverViewModel[]>> {
    const configUrl = ApiEndPoints.ORGANIZATIONAL_UNIT.organizationalUnitOverview;
    return this.msApiService.post<OrganizationalUnitOverViewModel[]>(configUrl, organizationSearchFilterModel);
  }

  public getOrganizationUnitPotentialParentsForExistingUnit(param): Observable<ApiResponse<OrganizationUnitHierarchyDropdownContent[]>> {
    const configUrl = ApiEndPoints.ORGANIZATIONAL_UNIT.organizationUnitPotentialParentsForExistingUnit;
    return this.msApiService.post<OrganizationUnitHierarchyDropdownContent[]>(configUrl, param).pipe(shareReplay(1));
  }

  public getOrganizationalHierarchyList(): Observable<ApiResponse<OrganizationalHierarchyListResponse[]>> {
    const configUrl = ApiEndPoints.ORGANIZATIONAL_HIERARCHY_LIST.organizationalHierarchyList;
    return this.msApiService.cacheGet<OrganizationalHierarchyListResponse[]>(configUrl);
  }

  public getOrganizationalUnitList(payload: { hierarchyId: number; orgUnitId?: number }): Observable<ApiResponse<OrganisationUnitListModel[]>> {
    return this.msApiService.cachePost<OrganisationUnitListModel[]>(ApiEndPoints.ORGANIZATIONAL_UNIT.organizationalUnitList, payload);
  }

  /**
   *
   * @deprecated calls to region api are deprecated
   */
  public getRegionDetails() {
    const configUrl = ApiEndPoints.REGION_DETAILS.regionList;
    return this.msApiService.get(configUrl);
  }

  /**
   *
   * @deprecated calls to State Province api are deprecated
   */
  public getStateProvinceDetails(countryID: string) {
    const configUrl = ApiEndPoints.STATE_PROVINCE_DETAILS.stateProvinceList;
    return this.msApiService.get(configUrl, { countryID: countryID });
  }

  public getSystemTypes() {
    const configUrl = ApiEndPoints.DATA_SOURCE_SINK.systemTypesList;
    return this.msApiService.get(configUrl);
  }

  public updateGeographicalUnit(requestModel: RequestModel) {
    const configUrl = ApiEndPoints.GEOGRAPHICAL_UNIT.geographicalUnitEdit;
    return this.msApiService.post(configUrl, requestModel).pipe(tap(response => this.updatedDataSubject$.next()));
  }

  public updateOrganizationalUnit(requestModel: RequestModel) {
    const configUrl = ApiEndPoints.ORGANIZATIONAL_UNIT.organizationalUnitEdit;
    return this.msApiService.post(configUrl, requestModel).pipe(tap(response => this.updatedDataSubject$.next()));
  }

  public validateGeographicalUnitCode(param): Observable<ApiResponse<boolean>> {
    const configUrl = ApiEndPoints.GEOGRAPHICAL_UNIT.geographicalUnitCodeValidation;
    return this.msApiService.post<boolean>(configUrl, param);
  }

  public validateGeographicalUnitName(param): Observable<ApiResponse<boolean>> {
    const configUrl = ApiEndPoints.GEOGRAPHICAL_UNIT.geographicalUnitNameValidation;
    return this.msApiService.post<boolean>(configUrl, param);
  }

  public validateOrganizationalUnitCode(param): Observable<ApiResponse<boolean>> {
    const configUrl = ApiEndPoints.ORGANIZATIONAL_UNIT.organizationUnitCodeValidation;
    return this.msApiService.post<boolean>(configUrl, param);
  }

  public getGeographicalUnits(
    request: DataSourceRequestModel<GeographicalUnitSortRules, DefaultSortDirection>,
  ): Observable<ApiResponse<GetGeographicalUnitsQueryResponse>> {
    return this.msApiService.post<GetGeographicalUnitsQueryResponse>(ApiEndPoints.GEOGRAPHICAL_UNIT.getgeographicalUnits, request);
  }

  public getGeographicalUnitsFilters(
    request: DataSourceRequestModel<GeographicalUnitSortRules, DefaultSortDirection>,
  ): Observable<ApiResponse<GetGeographicalUnitsFiltersQueryResponse>> {
    return this.msApiService.post<GetGeographicalUnitsFiltersQueryResponse>(ApiEndPoints.GEOGRAPHICAL_UNIT.getgeographicalUnitsFilters, request);
  }

  public getOrganizationalUnitByEngagement(param) {
    return this.msApiService.post(
      ApiEndPoints.SCOPE_PROFILES.engagements.concat(
        '/',
        param.engagementId,
        '/geographical-units/',
        param.geoUnitId,
        '' + '/organizational-unit-hierarchies/',
        param.orgUnitHierarchyId,
        '/organizational-units',
      ),
      param,
    );
  }

  public getOrganizationalUnitDropdownOptions(
    query: DataSourceRequestModel<any, DefaultSortDirection>,
  ): Observable<ApiResponse<DataSourceResult<DropdownOption>>> {
    return this.msApiService.post<DataSourceResult<DropdownOption>>(ApiEndPoints.ORGANIZATIONAL_UNIT.dropdownOptions, query);
  }

  public getLazyGeographicalUnitDropdownOptions(
    query: DataSourceRequestModel<any, DefaultSortDirection>,
  ): Observable<ApiResponse<DataSourceResult<DropdownOption>>> {
    return this.msApiService.post<DataSourceResult<DropdownOption>>(ApiEndPoints.GEOGRAPHICAL_UNIT.dropdownOptions, query);
  }
}
