import { BehaviorSubject, Observable } from 'rxjs';
import { Directive } from '@angular/core';

@Directive()
export abstract class ObservableDataSourceService<TType> {
  protected readonly subject: BehaviorSubject<Partial<TType>[]> = new BehaviorSubject<Partial<TType>[]>([]);

  public get dataSource$(): Observable<Partial<TType>[]> {
    return this.subject.asObservable();
  }

  public get value(): Partial<TType>[] {
    return this.subject.value;
  }

  public update(options: Partial<TType>[]): void {
    this.subject.next(options);
  }

  public abstract load(params?: {}): Observable<any>;
}
