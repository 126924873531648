<mat-form-field>
  <mat-label
    [attr.data-testid]="testIdArea + '-' + testIdControl + '-label'"
    [translate]="labelTranslate"
    [translateParams]="this.translateDefaultValue(labelTranslateParam)"
  ></mat-label>
  <mat-select
    (opened)="onOpened()"
    [attr.data-testid]="testIdArea + '-' + testIdControl + '-select'"
    [formControl]="control"
    [required]="required"
  >
    <mat-option [attr.data-testid]="testIdArea + '-' + testIdControl + '-disabled-search-option'">
      <ngx-mat-select-search
        noEntriesFoundLabel="No matching results"
        placeholderLabel="Search"
        [formControl]="searchControl"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option
      *ngFor="let option of dataSource.dataSource$ | async"
      [attr.data-testid]="testIdArea + '-' + testIdControl + '-option-' + option.label"
      [title]="option[labelProperty]"
      [value]="option[valueProperty]"
      [ngClass]="{ 'dcms-mat-option-disabled': option[dataSourceFields.GrayedOut] ?? false }"
    >
      {{ option.label }}
    </mat-option>
    <mat-option
      *ngIf="dataSource[dataSourceFields.TotalRecordCount] && dataSource[dataSourceFields.TotalRecordCount] > dataSource.value.length"
      [attr.data-testid]="testIdArea + '-' + testIdControl + '-option-list-end'"
      disabled
    >
      Use text search to get more result.
    </mat-option>
  </mat-select>
  <button
    [appButtonClearOption]="control"
    aria-label="Clear"
    [attr.data-testid]="testIdArea + '-' + testIdControl + '-clear-button'"
    mat-icon-button
    matSuffix
    type="button"
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-error>
    <app-validation-message [field]="control"></app-validation-message>
  </mat-error>
</mat-form-field>
