import { Directive } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS, ValidationErrors } from '@angular/forms';
import { InputValidationService } from 'src/app/shared/services/input-validation.service';

@Directive({
  selector: '[appUnicode]',
  providers: [{ provide: NG_VALIDATORS, useExisting: UnicodeValidatorDirective, multi: true }],
})
export class UnicodeValidatorDirective implements Validator {
  constructor(public inputValidationService: InputValidationService) {}

  public validate(control: AbstractControl): ValidationErrors | null {
    if (control.value && (control.value.indexOf('�') > -1 || control.value.indexOf('𑢝') > -1)) {
      return { pattern: true };
    } else {
      return null;
    }
  }
}
