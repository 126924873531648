import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dueDateText',
})
export class DueDateTextPipe implements PipeTransform {
  public transform(dueDate: Date): string {
    const dueDateOnly = new Date(dueDate.getTime());
    dueDateOnly.setHours(0, 0, 0, 0);

    const currentDateOnly = new Date();
    currentDateOnly.setHours(0, 0, 0, 0);

    const timeDiff = dueDateOnly.getTime() - currentDateOnly.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (daysDiff === 0) {
      return 'Due today';
    } else if (daysDiff === 1) {
      return 'Due in 1 day';
    } else if (daysDiff > 1 && daysDiff <= 6) {
      return `Due in ${daysDiff} days`;
    } else if (daysDiff === 7) {
      return 'Due in 1 week';
    } else {
      return 'Due in 1 month';
    }
  }
}
