import { HttpStatusCode } from '@angular/common/http';
import { filter, pipe } from 'rxjs';
import { ApiResponse } from '../models/response';

export function filterNonSuccessfullAndEmptyResponses<T>() {
  return pipe(
    filter(
      (response: ApiResponse<T>) =>
        response != null &&
        response.statusCode === HttpStatusCode.Ok &&
        response.data != null &&
        (!Array.isArray(response.data) || response.data.length > 0),
    ),
  );
}
