import { Pipe, PipeTransform } from '@angular/core';
import { ActiveInactiveStatus } from '../constants/active-inactive-status.enum';

@Pipe({
  name: 'activeInactive',
})
export class ActiveInactivePipe implements PipeTransform {
  transform(value: any): any {
    switch (value) {
      case '1':
      case 1:
      case 'true':
      case true:
        return ActiveInactiveStatus.Active;
      case '0':
      case 0:
      case 'false':
      case false:
        return ActiveInactiveStatus.Inactive;
      default:
        return value;
    }
  }
}
