<div
  [ngClass]="{
    'spinner--small': size === 'small',
    'spinner--medium': size === 'medium',
    'spinner--large': size === 'large'
  }"
  class="spinner"
>
  <div class="spinner__icon"></div>
</div>
