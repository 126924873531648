import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public formatDate(date) {
    if (date === '0001-01-01T00:00:00+00:00' || date === '0001-01-01T00:00:00') {
      return null;
    }

    if (date != null && date !== undefined && date !== '') {
      //DONT DELETE below commented tslint line. As it is needed for avoiding lint error
      // eslint-disable-next-line @typescript-eslint/dot-notation
      let d = new Date(date);
      //DONT DELETE below commented tslint line. As it is needed for avoiding lint error
      // eslint-disable-next-line @typescript-eslint/dot-notation
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }

      return [year, month, day].join('-');
    }
    return null;
  }

  public formatDateTime(dateTime) {
    if (dateTime === '0001-01-01T00:00:00+00:00' || dateTime === '0001-01-01T00:00:00') {
      return null;
    }

    if (dateTime != null && dateTime !== undefined && dateTime !== '') {
      //DONT DELETE below commented tslint line. As it is needed for avoiding lint error
      // eslint-disable-next-line @typescript-eslint/dot-notation
      let d = new Date(dateTime);
      //DONT DELETE below commented tslint line. As it is needed for avoiding lint error
      // eslint-disable-next-line @typescript-eslint/dot-notation
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }

      return [year, month, day].join('-') + ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds() + '.' + d.getMilliseconds();
    }
    return null;
  }

  public formatDateDDMMMYYYY(date) {
    if (date === '0001-01-01T00:00:00+00:00' || date === '0001-01-01T00:00:00') {
      return null;
    }

    if (date != null && date !== undefined && date !== '') {
      //DONT DELETE below commented tslint line. As it is needed for avoiding lint error
      // eslint-disable-next-line @typescript-eslint/dot-notation
      let d = new Date(date);
      //DONT DELETE below commented tslint line. As it is needed for avoiding lint error
      // eslint-disable-next-line @typescript-eslint/dot-notation
      let monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      let month = '' + monthList[d.getMonth()];
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (day.length < 2) {
        day = '0' + day;
      }

      return [day, month, year].join('-');
    }
    return null;
  }

  public getDateWithoutTime(dateString: string): Date {
    const date = new Date(dateString);
    date.setHours(0, 0, 0, 0);
    return date;
  }

  public hideViewEditPopUp(): void {
    document.querySelectorAll<HTMLElement>('.view-edit-action-div').forEach(e => {
      e.style.display = 'none';
    });
    document.querySelectorAll<HTMLElement>('.view-sort-action-div').forEach(e => {
      e.style.display = 'none';
    });
  }
}
