import { ApiEndPoints } from '../../config/api-end-points';
import { ApiResponse } from '../models/response';
import { Injectable } from '@angular/core';
import { MsApiService } from './ms-api.service';
import { Observable } from 'rxjs';
import { EntityStatusModel } from 'src/app/shared/models/entity-status.model';

@Injectable({
  providedIn: 'root',
})
export class EntityStatusService {
  constructor(private msApiService: MsApiService) {}

  public getEntityStatuses(referencedTableName: string): Observable<ApiResponse<EntityStatusModel[]>> {
    const configUrl = ApiEndPoints.ENTITY_STATUS.entityStatusesByTableName;
    const payload = { refTableName: referencedTableName };

    return this.msApiService.cachePost<EntityStatusModel[]>(`${configUrl}${referencedTableName}`, payload);
  }
}
