import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DataSourceValidationInput } from '../interfaces/data-source-validation-input.interface';

export function uniqueDataSourceNameValidator(input: DataSourceValidationInput): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let isSystemNameUnique = true;
    let itemNumber = input.itemNumber;
    let dataSourceList = [...input.dataSourceList];
    if (dataSourceList.length > 1) {
      dataSourceList.splice(itemNumber, 1);
      dataSourceList.map(function () {
        if (control.value && control.dirty) {
          var existItem = dataSourceList.find(x => x.systemName?.toLowerCase() === control.value.toLowerCase());
          if (existItem) {
            isSystemNameUnique = false;
          }
        }
      });
    }
    if (!isSystemNameUnique) {
      return { duplicate: true };
    }
    return null;
  };
}
