import { Directive, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, filter, switchMap, tap } from 'rxjs';
import { DialogDismissalReason, DialogDismissalReasonType } from 'src/app/config/constant';
import { ConfirmDeleteModalComponent } from 'src/app/shared/components/confirm-delete-modal/confirm-delete-modal.component';

@Directive({
  selector: '[appDeleteAction]',
})
export class DeleteActionDirective implements OnInit, OnDestroy {
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    backdropClass: 'customBackdrop',
  };
  private subscription: Subscription;
  private trigger: Subject<void> = new Subject();

  @Output() public confirmed: EventEmitter<void> = new EventEmitter();

  constructor(private modalService: NgbModal) {}

  @HostListener('click', ['$event'])
  public clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.trigger.next();
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public ngOnInit() {
    this.subscription = this.trigger
      .asObservable()
      .pipe(
        switchMap(() => this.modalService.open(ConfirmDeleteModalComponent, this.modalOptions).dismissed),
        filter((reason: DialogDismissalReasonType) => reason === DialogDismissalReason.confirm),
        tap(() => this.confirmed.emit()),
      )
      .subscribe();
  }
}
