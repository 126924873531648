export function dateToEpochTimeString(date: Date | string): string {
  try {
    if (date) {
      if (typeof date === 'string') {
        return Math.floor(new Date(date).getTime() / 1000).toString();
      }
      return Math.floor(date.getTime() / 1000).toString();
    }
  } catch (error) {
    return null;
  }
  return null;
}

export function epochTimeStringToDate(epochString: string): Date {
  if (epochString) {
    let num = Number(epochString);
    if (!isNaN(num)) {
      return new Date(Math.floor(num) * 1000);
    }
    return null;
  }
  return null;
}
