import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export abstract class BaseComponent implements OnDestroy {
  public ngUnsubscribe = new Subject<void>();

  public ngOnDestroy(): void {
    this.onNgOnDestroy();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onNgOnDestroy(): void {}
}
