<div
  class="toast-container__header"
  [ngClass]="'toast-container__header-' + (options.payload.type || 'error')"
>
  <mat-icon
    *ngIf="options.payload.type === toasterType.error"
    class="toast-container__header-icon"
    >error</mat-icon
  >
  <mat-icon
    *ngIf="options.payload.type === toasterType.success"
    class="toast-container__header-icon"
    >check_circle</mat-icon
  >
  <mat-icon
    *ngIf="options.payload.type === toasterType.warning"
    class="toast-container__header-icon"
    >warning</mat-icon
  >
</div>
<div class="toast-container__content">
  <div
    *ngIf="title"
    [class]="options.titleClass"
    [attr.aria-label]="title"
    class="toast-container__toast-title"
  >
    {{ title }}
  </div>
  <button
    *ngIf="displayViewDetailsButton && options.payload.type === toasterType.error && message"
    (click)="showDetails()"
    class="toast-container__button-link"
    mat-button
  >
    <span class="toast-err-msg">View Details</span>
  </button>

  <div
    *ngIf="message && displayDetails"
    role="alert"
    aria-live="polite"
    class="toast-container__toast-message"
    [class]="options.messageClass"
  >
    <div
      class="toast-container__toast-description toast-err-msg"
      [innerHtml]="message"
    >
    </div>
  </div>
</div>
<div class="toast-container__close">
  <div class="toast-container__close-wrapper">
    <button
      *ngIf="options.closeButton"
      (click)="remove()"
      mat-icon-button
      data-testid="toaster-close-button"
      aria-label="Close"
      type="button"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
