import { CustomAttributeRuleDefinitionModel, CustomAttributesOptionModel, CustomAttributesRuleModel } from '../models/custom-attribute';
import { CustomAttributesConstants } from '../constants/custom-attributes-constants';
import { CustomAttributesHelperService } from './custom-attributes-helper.service';
import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CustomAttributeRulesService {
  constructor(private customAttributesHelperService: CustomAttributesHelperService) {}

  public disableFieldBasedOnRules(controlFormGroup: FormGroup, formGroup: FormGroup): boolean {
    return controlFormGroup.get('rules').value ? this.processDisableRules(controlFormGroup, formGroup) : false;
  }

  public isRequired(controlFormGroup: FormGroup, formGroup: FormGroup): boolean {
    if (this.hasRequiredRule(controlFormGroup)) {
      return true;
    }

    if (!!this.getCrossFieldRequiredRules(controlFormGroup)?.length) {
      return this.isCrossFieldRequired(controlFormGroup, formGroup);
    }

    return false;
  }

  private buildRuleExpression(formGroup: FormGroup, ruleDefinition: CustomAttributeRuleDefinitionModel): string {
    const complexExpressions: string[] = [];

    for (const ruleComplex of ruleDefinition.ruleComplexes) {
      const ruleExpressions: string[] = [];

      for (const rule of ruleComplex.rules) {
        const ruleControl: FormGroup = formGroup.get(rule.field) as FormGroup;
        const controlPropertyAccessor: 'valueOptionId' | 'value' = this.customAttributesHelperService.getAttributePropertyAccessor(
          ruleControl.get('controlType').value,
          [CustomAttributesConstants.CONTROL_DROPDOWN_BOOLEAN],
        );

        let controlValue = ruleControl.get(controlPropertyAccessor).value;

        if (controlValue && controlPropertyAccessor === 'valueOptionId') {
          controlValue = ruleControl.get('options').value.find((option: CustomAttributesOptionModel) => option.id === controlValue)?.value;
        }

        const ruleValue = isNaN(rule.value) ? `'${rule.value}'` : rule.value;
        const fieldValue: string = controlValue ? `'${controlValue}'` : null;
        const subExpression: string = `(${fieldValue} ${this.customAttributesHelperService.getComparisonOperator(rule.operator)} ${ruleValue})`;
        ruleExpressions.push(subExpression);
      }

      const currentExpression = `(${ruleExpressions.join(
        ' ' + this.customAttributesHelperService.getLogicalOperator(ruleComplex.logicOperator) + ' ',
      )})`;
      complexExpressions.push(currentExpression);
    }

    return `(${complexExpressions.join(' ' + this.customAttributesHelperService.getLogicalOperator(ruleDefinition.logicOperator) + ' ')})`;
  }

  private clearDisabledFieldValue(controlFormGroup: FormGroup): void {
    const valuePropertyAccessor: string = this.customAttributesHelperService.getAttributePropertyAccessor(controlFormGroup.get('controlType').value);
    if (controlFormGroup.get(valuePropertyAccessor).value) {
      controlFormGroup.get(valuePropertyAccessor).reset();
    }
  }

  private getCrossFieldRequiredRules(controlFormGroup: FormGroup): CustomAttributesRuleModel[] {
    return controlFormGroup.get('rules').value?.filter((rule: CustomAttributesRuleModel): boolean => rule.name === 'crossFieldRequired');
  }

  private hasRequiredRule(controlFormGroup: FormGroup): boolean {
    return !!controlFormGroup
      .get('rules')
      .value?.filter(
        (rule: CustomAttributesRuleModel) =>
          rule.name === 'required' &&
          rule.ruleDefinition == '1' &&
          controlFormGroup.get('controlType').value.name != CustomAttributesConstants.CONTROL_DROPDOWN_BOOLEAN,
      )?.length;
  }

  private isCrossFieldRequired(controlFormGroup: FormGroup, formGroup: FormGroup): boolean {
    let isRequired: boolean = false;

    for (const rule of this.getCrossFieldRequiredRules(controlFormGroup)) {
      const expression: string = this.buildRuleExpression(formGroup, JSON.parse(rule.ruleDefinition));

      try {
        isRequired = eval(expression);
      } catch (exceptionVar) {
        isRequired = false;
      }
      isRequired = eval(expression);
    }

    return isRequired;
  }

  private processDisableRules(controlFormGroup: FormGroup, formGroup: FormGroup): boolean {
    let shouldDisable: boolean = !controlFormGroup.get('isEditable').value;

    if (!shouldDisable) {
      for (const ruleObject of controlFormGroup.get('rules').value.filter((rule: CustomAttributesRuleModel) => rule.name === 'disable')) {
        try {
          shouldDisable = eval(this.buildRuleExpression(formGroup, JSON.parse(ruleObject.ruleDefinition)));

          if (shouldDisable) {
            this.clearDisabledFieldValue(controlFormGroup);
            break;
          }
        } catch (exceptionVar) {
          shouldDisable = false;
        }
      }
    }

    //Disabled required fields should be also validate
    if (
      !controlFormGroup.value.isEditable &&
      controlFormGroup.value.controlType?.isRequired &&
      !controlFormGroup.value.value &&
      !controlFormGroup.value.valueId &&
      !controlFormGroup.value.valueOptionId
    ) {
      formGroup.setErrors({ required: true });
    }

    return shouldDisable;
  }
}
