import { ActiveInactiveStatus } from '../../constants/active-inactive-status.enum';
import { ChangeRequest } from '../../models/request';
import { ChangeStatusDialogResult } from 'src/app/config/constant';
import { Component, Input, OnInit } from '@angular/core';
import { EntityStatusDataSourceService } from '../../temp/entity-status-data-source.service';
import { EntityStatusModel } from '../../models/entity-status.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReferencedTableName } from '../../enums/referenced-table-name.enum';

export interface ChangeStatusModalModel {
  associateItemsWarning?: boolean;
  changeRequestDomain: string;
  crDetails: ChangeRequest;
  id: number;
  name: string;
  referencedTableName: ReferencedTableName;
  status: EntityStatusModel;
  statusGroupName: string;
  statusID: number;
  statusName: string;
  statusTransitionMapping?: { [key in string]: string[] };
}

@Component({
  selector: 'app-change-status-modal',
  templateUrl: './change-status-modal.component.html',
  styleUrls: ['./change-status-modal.component.scss'],
})
export class ChangeStatusModalComponent implements OnInit {
  @Input() public data: ChangeStatusModalModel;

  public activeInactiveStatus: typeof ActiveInactiveStatus = ActiveInactiveStatus;
  public form: FormGroup;

  public get canUpdate(): boolean {
    return !this.form.invalid && this.form.get('status').value?.id !== this.form.get('statusID').value;
  }

  public get canUpdateAndEdit(): boolean {
    return (
      !this.form.invalid &&
      this.form.get('status').value?.groupName === ActiveInactiveStatus.Active &&
      this.form.get('status').value?.id !== this.form.get('statusID').value
    );
  }

  public get isIrreversibleStatusChange(): boolean {
    return this.data.statusTransitionMapping?.[this.form.get('status').value.name]?.length === 0 ?? true;
  }

  public get isWarningVisible(): boolean {
    return !!this.data.associateItemsWarning && this.form.get('status')?.value?.groupName === ActiveInactiveStatus.Inactive;
  }

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    public entityStatusDataSourceService: EntityStatusDataSourceService,
  ) {
    this.createForm();
  }

  public close(): void {
    this.activeModal.close();
  }

  public ngOnInit(): void {
    this.loadDictionaries();
    this.data.status = { id: this.data.statusID, name: this.data.statusName, isDefault: undefined };
    this.form.reset(this.data);
  }

  public update(): void {
    this.updateAction(ChangeStatusDialogResult.update);
  }

  public updateAndEdit(): void {
    this.updateAction(ChangeStatusDialogResult.updateAndEdit);
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      id: undefined,
      name: undefined,
      crDetailsDomain: undefined,
      statusID: undefined,
      statusName: undefined,
      crDetails: [''],
      status: { id: undefined, name: undefined, groupName: undefined },
    });
  }

  private loadDictionaries(): void {
    this.entityStatusDataSourceService.load(this.data.referencedTableName).subscribe();
  }

  private updateAction(dialogResult: ChangeStatusDialogResult): void {
    const payload = {
      id: this.form.get('id').value,
      statusID: this.form.get('status')?.value.id,
      crDetails: this.form.get('crDetails').value,
    };

    this.activeModal.close({ dialogResult: dialogResult, data: payload });
  }
}
