<div class="cookie-modal-box">
  <div
    class="modal-header bulkupload-modal"
    id="cookiesModal"
  >
    <h4
      class="modal-title"
      id="modal-basic-title"
      >Cookie Consent</h4
    >
  </div>
  <div class="modal-body">
    <div class="row justify-content-center cookie-consent-message">
      <p
        >To provide the best experiences, we use technologies like cookies to store and access device information. Consenting to these technologies
        will allow us to process data such as browsing behavior.</p
      >
      <p>By clicking "Accept" you consent to our use of cookies to improve your experience and support our website's functionality.</p>
    </div>
    <div class="buttons-container">
      <button
        (click)="close(true)"
        class="cookies-modal__info-button"
        color="accent"
        data-testid="overview-modal-continue-button"
        mat-flat-button
        type="button"
      >
        Accept
      </button></div
    ></div
  >
  <div class="modal-footer"></div>
</div>
