import { Pipe, PipeTransform } from '@angular/core';
import { CommonFilter } from 'src/app/shared/models/filter/common-filter.model';

@Pipe({
  name: 'selectedFilters',
  pure: false,
})
export class SelectedFiltersPipe implements PipeTransform {
  public transform(filters: Array<CommonFilter>, showOnlySelected: boolean): Array<CommonFilter> {
    if (!filters) {
      return [];
    }
    return filters.filter((filter: CommonFilter) => {
      return !!filter.isSelected === showOnlySelected;
    });
  }
}
