import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ValidationPattern } from 'src/app/shared/constants/ValidationPattern';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-overview-search',
  templateUrl: './overview-search.component.html',
  styleUrls: ['./overview-search.component.scss'],
})
export class OverviewSearchComponent implements OnChanges, OnInit {
  @Input() public searchPlaceHolder: string;
  @Input() public searchString: string;
  @Input() public maxLength: number = 50;
  @Output() public searchByString = new EventEmitter<any>();

  public placeholder: string = null;
  public searchControl: FormControl;

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.searchString) {
      // check if searchString becomes null remove search text
      if (changes.searchString.currentValue === null) {
        this.searchControl.setValue('');
      }
    }
  }

  public ngOnInit(): void {
    this.searchControl = new FormControl('', {
      validators: [Validators.maxLength(this.maxLength)],
    });
  }

  public reset(): void {
    this.searchControl.setValue('');
    this.search();
  }

  public search(): void {
    if (this.searchControl.valid) {
      this.searchByString.emit(this.searchControl.value);
    }
  }
}
