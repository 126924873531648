import { Injectable } from '@angular/core';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root',
})
export class SuccessMessageService {
  constructor(private toastService: ToastService) {}

  public show(resultCode: string) {
    switch (resultCode) {
      case 'CREATED':
        this.toastService.showSuccess('Successfully Created.');
        break;
      case 'UPDATED':
        this.toastService.showSuccess('Successfully Updated.');
        break;
      case 'DELETED':
        this.toastService.showSuccess('Successfully Deactivated.');
        break;
      default:
    }
  }
}
