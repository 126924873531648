import { Component } from '@angular/core';
import { BaseComponent } from '../base-component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cookies-modal',
  templateUrl: './cookies-modal.component.html',
  styleUrls: ['./cookies-modal.component.scss'],
})
export class CookiesModalComponent extends BaseComponent {
  constructor(private activeModal: NgbActiveModal) {
    super();
  }

  close(result: boolean) {
    this.activeModal.close(result);
  }
}
