<mat-list class="common-item-list__list">
  <mat-list-item
    *ngFor="let item of list | filterList : searchValue : keyList"
    [disabled]="disabled"
  >
    <div class="common-item-list__row">
      <div class="common-item-list__item">
        <p matLine> {{ item[keyList[0]] }} </p>
        <div
          *ngIf="item[keyList[1]]"
          class="common-item-list__subline"
        >
          <mat-icon class="common-item-list__icon">{{ descriptionIcon }}</mat-icon>
          <span
            matLine
            [innerHtml]="item[keyList[1]]"
          ></span>
        </div>
      </div>
      <div *ngIf="removeable">
        <mat-icon
          matListIcon
          (click)="remove(item)"
          >delete</mat-icon
        >
      </div>
    </div>
  </mat-list-item>
</mat-list>
