<div class="modal-header search-list-dialog-modal">
  <h4
    class="modal-title"
    id="modal-basic-title"
    >{{ title }}</h4
  >
  <button
    data-testid="select-list-dialog-close-button"
    (click)="close()"
    aria-label="Close"
    class="close"
    id="close-button"
    type="button"
  >
    <i class="material-icons close-icon">close</i>
  </button>
</div>
<div class="modal-body">
  <form
    [formGroup]="form"
    class="col-12 controls-container"
  >
    <app-search-item-list
      *ngIf="!edit"
      [list]="list"
      [disabled]="disabled"
      [descriptionIcon]="descriptionIcon"
      [searchPlaceHolder]="searchPlaceHolder"
      [keyList]="keyList"
      [keyId]="keyId"
      [removeable]="removeable"
      [selection]="true"
      [filterList]="filterList"
      [filterPlaceholder]="filterPlaceholder"
      [filterByField]="filterByField"
      [sortable]="true"
    ></app-search-item-list>
    <div class="row">
      <div class="col-md-6">
        <mat-form-field>
          <button
            data-testid="service-subscription-irs-frequency-clear-button"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            [appButtonClearOption]="form.controls.frequency"
          >
            <mat-icon>close</mat-icon>
          </button>
          <mat-label
            data-testid="service-subscription-irs-frequency-label"
            [translate]="'Service_Subscription_Irs/Frequency'"
            [translateParams]="translateDefaultValue('Frequency')"
          ></mat-label>
          <mat-select
            data-testid="service-subscription-irs-frequency-select"
            [appDisableControl]="disabled"
            formControlName="frequency"
            [required]="!edit"
          >
            <ng-container *ngFor="let frequency of frequencies">
              <mat-option
                *ngIf="frequency.periodLength <= period.frequency.periodLength"
                [attr.data-testid]="'service-subscription-irs-frequency-option-' + frequency.name"
                [title]="frequency.name"
                [value]="frequency"
              >
                {{ frequency.name }}
              </mat-option>
            </ng-container>
          </mat-select>
          <mat-error>
            <app-validation-message [field]="form.get('frequency')"></app-validation-message>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field>
          <mat-label
            data-testid="service-subscription-irs-period-label"
            [translate]="'Service_Subscription/Subscription_Period'"
            [translateParams]="translateDefaultValue('Subscription Period')"
          ></mat-label>
          <mat-date-range-input
            [rangePicker]="periodDatePicker"
            data-testid="service-subscription-irs-period-date-picker-input"
            name="period"
            formGroupName="period"
            [required]="!edit"
            [min]="period.start"
            [max]="period.end"
          >
            <input
              (click)="periodDatePicker.open()"
              formControlName="start"
              data-testid="service-subscription-irs-start-period-date-picker-input"
              placeholder="Start date"
              name="periodStart"
              matStartDate
              [required]="!edit"
            />
            <input
              (click)="periodDatePicker.open()"
              formControlName="end"
              data-testid="service-subscription-irs-end-period-date-picker-input"
              placeholder="End date"
              name="periodEnd"
              matEndDate
              [required]="!edit || form.value.period.start"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            [for]="periodDatePicker"
            matSuffix
          >
          </mat-datepicker-toggle>
          <mat-date-range-picker #periodDatePicker></mat-date-range-picker>
          <mat-error>
            <app-validation-message [field]="form.get('period').get('start')"></app-validation-message>
            <app-validation-message
              *ngIf="form.get('period').get('start').value"
              [field]="form.get('period').get('end')"
            ></app-validation-message>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    *ngIf="!edit"
    data-testid="select-list-dialog-add-button"
    (click)="addIr()"
    color="accent"
    mat-flat-button
    type="button"
    [disabled]="form.invalid || (searchItemList ? searchItemList.selectList.selection.selectedOptions.selected.length === 0 : true)"
  >
    Add
  </button>
  <button
    *ngIf="edit"
    data-testid="select-list-dialog-edit-button"
    (click)="editIr()"
    color="accent"
    mat-flat-button
    type="button"
    [disabled]="form.invalid"
  >
    Edit
  </button>
  <button
    ngbAutofocus
    data-testid="select-list-dialog-cancel-button"
    (click)="close()"
    color="primary"
    mat-flat-button
    type="button"
    >Cancel
  </button>
</div>
