import { BehaviorSubject, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { LoggedInStatus } from '../models/rbac';
import { LoginService } from './login.service';
import { MsApiService } from './ms-api.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class LoginStatusService {
  userLoggedInStatus$ = new BehaviorSubject<LoggedInStatus>({
    loginStatus: false,
    cleanSession: true,
  });

  constructor(
    private localStorageService: LocalStorageService, 
    private loginService: LoginService, 
    private msApiService: MsApiService, 
    private sessionStorage: SessionStorageService
  ) {}

  userLoggedIn() {
    this.setUserLoggedInStatus({
      loginStatus: true,
      cleanSession: true,
    });

    this.userLoggedInStatus$
      .asObservable()
      .pipe(
        switchMap((loggedInStatus: LoggedInStatus) => {
          if (!loggedInStatus.loginStatus && loggedInStatus.cleanSession) {
            return this.loginService.logoutUserSession();
          } else {
            return of();
          }
        }),
      )
      .subscribe();
  }

  clearSessionInfo(): void {
    this.setUserLoggedInStatus({
      loginStatus: false,
      cleanSession: true,
    });
    this.clearStorage();
  }

  logoutUserWithoutSessionClean(): void {
    this.setUserLoggedInStatus({
      loginStatus: false,
      cleanSession: false,
    });
    this.clearStorage();
  }

  clearStorage(): void {
    this.localStorageService.clear();
    this.sessionStorage.clear();
    this.msApiService.cleanCache();
  }

  setUserLoggedInStatus(status: LoggedInStatus): void {
    this.userLoggedInStatus$.next(status);
  }
}
