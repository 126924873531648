import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { LoginStatusService } from './login-status.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Injectable({ providedIn: 'root' })
export class SessionService {
  private checkIdleIntervalInSeconds = environment.sessionSetting.checkIdleIntervalInSeconds;
  private checkTimeoutTimer: Subscription;
  private extendSessionBeforeIdleTimeoutInMinutes = environment.sessionSetting.extendSessionPopupBeforeTimeoutInMinutes;
  private idleTimeoutInMinutes = environment.sessionSetting.idleTimeoutInMinutes;
  private maxSessionDurationInHours = environment.sessionSetting.maxSessionDurationInHours;
  private maxSessionExpiryDateTime: Date;

  public triggerSessionExtensionPopup = new BehaviorSubject<boolean>(false);

  constructor(private router: Router, private loginStatusService: LoginStatusService, private modalService: NgbModal
    , private localStorageService: LocalStorageService
  ) {}

  private get lastAction() {
    return parseInt(this.localStorageService.getItem('lastAction'));
  }

  private set lastAction(value) {
    this.localStorageService.setItem('lastAction', value.toString());
  }

  public initialize() {
    this.initializeIdleCheck();
    this.maxSessionExpiryDateTime = new Date();
    this.maxSessionExpiryDateTime.setHours(this.maxSessionExpiryDateTime.getHours() + this.maxSessionDurationInHours);
  }

  public resetTimeout() {
    this.lastAction = Date.now();
  }

  private checkTimeout() {
    if (!this.loginStatusService.userLoggedInStatus$.getValue().loginStatus) {
      return;
    }

    const currentDateTime = new Date();
    const idleTimeout = new Date(this.lastAction);
    const extendSessionTimeout = new Date(this.lastAction);

    idleTimeout.setMinutes(idleTimeout.getMinutes() + this.idleTimeoutInMinutes);

    extendSessionTimeout.setMinutes(extendSessionTimeout.getMinutes() + (this.idleTimeoutInMinutes - this.extendSessionBeforeIdleTimeoutInMinutes));

    if (currentDateTime > idleTimeout || currentDateTime > this.maxSessionExpiryDateTime) {
      if (this.checkTimeoutTimer) {
        this.checkTimeoutTimer.unsubscribe();
      }
      this.loginStatusService.clearSessionInfo();
      this.router.navigate(['/logout']).then(() => {
        window.location.reload();
      });
      return;
    }

    if (currentDateTime > extendSessionTimeout && !this.triggerSessionExtensionPopup.getValue()) {
      this.triggerSessionExtensionPopup.next(true);
    }
  }

  private initializeIdleCheck() {
    this.lastAction = Date.now();
    const timer$ = interval(this.checkIdleIntervalInSeconds * 1000);
    this.checkTimeoutTimer = timer$.subscribe(() => this.checkTimeout());
  }
}
