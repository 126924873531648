<div class="form-group">
  <mat-form-field>
    <mat-label data-testid="overview-search-label">Search by {{ searchPlaceHolder }}</mat-label>
    <input
      data-testid="overview-search-input"
      [formControl]="searchControl"
      (keyup.enter)="search()"
      matInput
      type="text"
    />
    <mat-icon
      *ngIf="!searchControl.value"
      data-testid="overview-search-search-icon"
      matSuffix
    >
      search
    </mat-icon>
    <mat-icon
      (click)="reset()"
      data-testid="overview-search-close-icon"
      *ngIf="searchControl.value"
      matSuffix
    >
      close
    </mat-icon>
    <mat-error>
      <app-validation-message [field]="searchControl"></app-validation-message>
    </mat-error>
  </mat-form-field>
</div>
