import { ApiEndPoints } from 'src/app/config/api-end-points';
import { ApiResponse } from '../models/response';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { EncryptionService } from './encryption-service';
import { ITenantDictionary, Permission, TenantsResponse, ValidateSessionsResponse } from '../models/rbac';
import { Injectable } from '@angular/core';
import { MsApiService } from './ms-api.service';
import { Privileges } from '../constants/privileges.enum';
import { UserRolesService } from './user-roles.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { StorageItem, TenantStorageItem, UserStorageItem } from 'src/app/core/model/storage-item';

@Injectable({
  providedIn: 'root',
})
export class TenantsService {
  public tenantDictionarySubject: BehaviorSubject<ITenantDictionary[]> = new BehaviorSubject<ITenantDictionary[]>([]);

  constructor(
    private msApiService: MsApiService,
    private encryptionService: EncryptionService,
    private userRolesService: UserRolesService,
    private localStorageService: LocalStorageService,
  ) {}

  public get allTenantDictionary$(): Observable<ITenantDictionary[]> {
    const currentlySelectedClientId: string = this.localStorageService.getItemAsObject<TenantStorageItem>(StorageItem.Tenant)?.id;
    const userPrivilegesDetached: Privileges[] = this.userRolesService.userPermissions.map((privilege: Permission) => privilege.privilege);

    if (userPrivilegesDetached.includes(Privileges.allTenantsRead)) {
      return this.tenantDictionarySubject;
    } else {
      return this.tenantDictionarySubject.pipe(
        map((allTenants: ITenantDictionary[]) => allTenants.filter((tenant: ITenantDictionary) => tenant.id === currentlySelectedClientId)),
      );
    }
  }

  public integrateExternalProviderData(tenantId: string): Observable<any> {
    const configUrl = ApiEndPoints.TENANTS.integrateExternalProviderData;
    return this.msApiService.post(configUrl, { tenantId: tenantId });
  }

  public integrateExternalProviderUsers(tenantId: string): Observable<any> {
    const configUrl = ApiEndPoints.TENANTS.integrateExternalProviderUsers;
    return this.msApiService.post(configUrl, { tenantId: tenantId });
  }

  public loadTenantDictionaryFromStorage() {
    const value = this.localStorageService.getItemAsObject<UserStorageItem>(StorageItem.User)?.tenantDictionary;
    this.tenantDictionarySubject.next(JSON.parse(value));
  }

  public setTenantsDictionary(tenants: Array<TenantsResponse>): void {
    this.tenantDictionary(
      tenants.map((tenant: TenantsResponse) => ({
        id: tenant.tenantId,
        name: tenant.name,
      })),
    );
  }

  public validateSessions(param): Observable<ApiResponse<ValidateSessionsResponse>> {
    const configUrl = ApiEndPoints.LOGIN.validateSessions;
    const response = this.msApiService.post<ValidateSessionsResponse>(configUrl, param);
    return response;
  }

  private tenantDictionary(value: any) {
    const user = this.localStorageService.getItemAsObject<UserStorageItem>(StorageItem.User) || {};
    user.tenantDictionary = JSON.stringify(value);
    this.localStorageService.setItem(StorageItem.User, user);

    this.tenantDictionarySubject.next(value);
  }
}
