import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { ContextMenuAction } from 'src/app/config/constant';

@Directive({
  selector: '[appCancelAction]',
})
export class CancelActionDirective implements OnInit {
  @Input() public contextMenuAction: ContextMenuAction;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  public ngOnInit(): void {
    const content = this.contextMenuAction !== ContextMenuAction.view ? 'Cancel' : 'Close';
    this.renderer.setProperty(this.elementRef.nativeElement, 'innerText', content);
  }
}
