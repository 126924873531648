<form [formGroup]="form">
  <div class="col-12 controls-container custom-parameters__form">
    <div
      *ngIf="customParametersFormArray.length > 0"
      class="row"
      formArrayName="parameterItems"
    >
      <h5>{{ displayName }}</h5>
      <div
        *ngFor="let field of customParametersFormArray; index as i"
        [formGroupName]="i"
        class="col-md-6"
      >
        <div class="margin-top">
          <mat-form-field>
            <mat-label data-testid="custom-parameters-name-label">{{ field.name }}</mat-label>
            <input
              data-testid="custom-parameters-name-input"
              formControlName="value"
              matInput
              [appDisableControl]="isReadonly"
            />
            <mat-error>
              <app-validation-message [field]="form.get('parameterItems.' + i + '.value')"></app-validation-message>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</form>
