import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExportOption } from '../../models/export-file';

@Component({
  selector: 'app-export-button',
  templateUrl: './export-button.component.html',
  styleUrls: ['./export-button.component.scss'],
})
export class ExportButtonComponent {
  @Input()
  public options?: Array<ExportOption> = [];

  @Output()
  public exportData = new EventEmitter<ExportOption | any>();

  public onOptionChange(option: ExportOption) {
    this.exportData.emit(option);
  }
}
