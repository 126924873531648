import { ApiResponse } from '../models/response';
import { EntityStatusModel } from '../models/entity-status.model';
import { EntityStatusService } from '../services/entity-status.service';
import { Injectable } from '@angular/core';
import { ObservableDataSourceService } from './observable-data-source-service';
import { ReferencedTableName } from '../enums/referenced-table-name.enum';
import { filterNonSuccessfullAndEmptyResponses } from '../operators/api-response-operators';
import { map, Observable, take, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EntityStatusDataSourceService extends ObservableDataSourceService<EntityStatusModel> {
  constructor(private entityStatusService: EntityStatusService) {
    super();
  }

  public load(table: ReferencedTableName): Observable<any> {
    return this.entityStatusService.getEntityStatuses(table).pipe(
      filterNonSuccessfullAndEmptyResponses(),
      map((response: ApiResponse<EntityStatusModel[]>) => response.data),
      tap((data: EntityStatusModel[]) => this.subject.next(data)),
      take(1),
    );
  }
}
