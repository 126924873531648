<mat-list class="common-item-list__list">
  <mat-list-item
    *ngFor="let item of list | filterList : searchValue : keyList; let i = index"
    [disabled]="disabled"
    (click)="select(item, i)"
  >
    <div
      class="common-item-list__row"
      [class.common-item-list__row__active]="activeIndex === i"
    >
      <div class="common-item-list__item">
        <p matLine> {{ ellipsis && item[keyList[0]].length > ellipsis ? (item[keyList[0]] | slice : 0 : ellipsis) + ' ...' : item[keyList[0]] }} </p>
        <div
          *ngIf="item[keyList[1]]"
          class="common-item-list__subline"
        >
          <mat-icon
            *ngIf="descriptionIcon"
            class="common-item-list__icon"
            >{{ descriptionIcon }}</mat-icon
          >
          <span
            matLine
            [innerHtml]="item[keyList[1]]"
          ></span>
        </div>
      </div>
      <div *ngIf="showStatus && item.status">
        <mat-chip-listbox data-testid="common-item-list-chip-list">
          <mat-chip-option
            [class]="'common-item-list__status-' + item.statusColor"
            data-testid="common-item-list-chip"
            disabled
          >
            {{ item.status }}
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
      <div *ngIf="removeable">
        <mat-icon
          matListIcon
          (click)="remove(item)"
          >delete</mat-icon
        >
      </div>
    </div>
  </mat-list-item>
</mat-list>
