export const DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM YYYY',
  },
};

export const PopUpType = {
  bulkUpload: 'BULK UPLOAD',
  view: 'VIEW',
  edit: 'EDIT',
  delete: 'DELETE',
};

export enum ContextMenuAction {
  create = 'create',
  edit = 'edit',
  delete = 'delete',
  view = 'view',
  changeStatus = 'changeStatus',
}

export enum SortSelectionType {
  latest = 'Latest',
  oldest = 'Oldest',
  atoz = 'A-Z',
  ztoa = 'Z-A',
}

export enum SortBy {
  createdAsc,
  createdDesc,
  nameAsc,
  nameDesc,
}

export const FilterTypesLabels = {
  serviceType: 'Service Type',
  status: 'Status',
  serviceOffering: 'Service Offering',
  periodicity: 'Periodicity',
  connectorType: 'Connector Type',
  serviceInstanceDate: 'Service Instance Date',
  orgUnitType: 'Org Unit Type',
  geoUnit: 'Geo Unit',
  geoUnitType: 'Geo Unit Type',
  sourceSystemName: 'Source System Name',
  userGroupName: 'User Group',
  userStatus: 'Status',
  userTenant: 'Client',
  role: 'Role',
  privilege: 'Privilege',
  application: 'Application',
  applicationModule: 'Application Module',
  requestType: 'Request Type',
  requestStatus: 'Request Status',
  requestedBy: 'Requested By',
};

export const FilterType = {
  serviceType: 'serviceType',
  engagement: 'engagement',
  status: 'status',
  serviceOffering: 'serviceOffering',
  periodicity: 'periodicity',
  connectorType: 'connectorType',
  serviceInstanceDate: 'serviceInstanceDate',
  orgUnitType: 'orgUnitType',
  geoUnitType: 'geoUnitType',
  geoUnit: 'geoUnit',
  sourceSystemName: 'sourceSystemName',
  user: 'user',
  userGroupName: 'userGroupName',
  userStatus: 'userStatus',
  clientStatus: 'clientStatus',
  userTenant: 'userTenant',
  role: 'role',
  applicationModule: 'applicationModule',
  application: 'application',
  privilege: 'privilege',
  requestType: 'requestType',
  requestStatus: 'requestStatus',
  accountingPeriod: 'accountingPeriod',
  accountingYear: 'accountingYear',
  sdiDueDate: 'sourceDataItemDueDate',
  instanceType: 'instanceType',
};

export const NavigationMenuItem = {
  admin: 'admin',
  categoryTemplate: 'categoryTemplate',
  clientManagement: 'clientmanagement',
  serviceInstance: 'serviceInstance',
  dashboard: 'dashboard',
  dataAccessGroup: 'dataAccessGroup',
  engagement: 'engagement',
  geoUnit: 'geounit',
  organization: 'organization',
  organizationUnit: 'organizationunit',
  queueRequests: 'queueRequests',
  role: 'roleManagement',
  serviceAggrement: 'serviceagreement',
  serviceOffering: 'serviceoffering',
  services: 'services',
  serviceSubscription: 'subscription',
  serviceType: 'servicetype',
  sourceDataItem: 'sourcedataitem',
  user: 'user',
  userGroupManagement: 'userGroupManagement',
  userManagement: 'userManagement',
};

export const OverviewScreens = {
  dashboard: 'dashboard',
  organizationUnit: 'organization',
  services: 'services',
  serviceOffering: 'serviceoffering',
  sourceDataItem: 'sourcedataitem',
  serviceType: 'servicetype',
  serviceAggrement: 'serviceagreement',
  serviceSubscription: 'subscription',
  serviceInstance: 'serviceInstance',
  ruleManagement: 'rulemanagement',
  admin: 'admin',
  clientManagement: 'clientmanagement',
  userManagement: 'userManagement',
  roleManagement: 'roleManagement',
  engagement: 'engagement',
  scopeProfile: 'scopeProfile',
};

export const UploadFeatureNames = {
  serviceOffering: 'Service Offerings',
  organizationUnit: 'Organisational Units',
  geographicalUnit: 'Geographical Units',
  sourceDataItem: 'Source Data Items',
  sourceDataItemParameters: 'Source Data Item Parameters',
  serviceSubscription: 'Service Subscriptions',
  serviceInstance: 'Service Instances',
  serviceInstanceParameters: 'Service Instance Parameters',
  engagements: 'Engagements',
  serviceType: 'Service Type',
};

export const ToolTipMessage = {
  1: 'The file you have uploaded does not match the required capability. Please upload a file that matches the required capability',
  2: 'The file you have uploaded is corrupted and cannot be processed. Please upload a valid .xlsx file',
  3: 'The file you have uploaded is blank and cannot be processed. Please upload a file with data in it',
  4: 'The file you have uploaded is not the latest version and does not match the current set of columns in the system. Please upload the latest version of the file',
  5: 'The file you have uploaded contains malicious code and cannot be processed. Please upload a safe and valid file',
};

export enum ChangeStatusDialogResult {
  update = 'update',
  updateAndEdit = 'updateAndEdit',
  cancel = 'cancel',
}

export const DialogDismissalReason = {
  confirm: 'confirm',
  cancel: 'cancel',
};

export type DialogDismissalReasonType = (typeof DialogDismissalReason)[keyof typeof DialogDismissalReason];

export enum ReferenceTableName {
  serviceType = 'ServiceType',
  serviceOffering = 'ServiceOffering',
  serviceInstance = 'ServiceInstance',
}

export enum AppLanguage {
  en = 'EN',
}

export const Pagination = {
  pageSize: 2000,
  skip: 0,
};

export enum ToasterType {
  success = 'success',
  error = 'error',
  warning = 'warning',
}

export const UserValidationMessage = {
  DuplicateUserName: 'User email already exists.',
};
