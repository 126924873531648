import { Injectable } from '@angular/core';
import { IRecordInfo } from '../record-info.model';
import { ModelAdapter } from 'src/app/shared/models/model.adapter';
import { CommonService } from 'src/app/shared/services/common.service';

export interface IServiceSubscriptionQueryResponse extends IRecordInfo {
  calendarTypeName?: string;
  dueDate?: string;
  id?: any;
  isActive: boolean;
  organizationalUnit?: string;
  periodicity?: string;
  serviceOfferingName?: string;
  sourceDataItemName?: string;
  subscriptionEndDate?: string;
  subscriptionStartDate?: string;
  statusGroupName?: string;
  statusName?: string;
  statusID?: number;
}

export class ServiceSubscriptionQueryResponse implements IServiceSubscriptionQueryResponse {
  public calendarTypeName?: string;
  public dueDate?: string;
  public id?: any;
  public isActive: boolean;
  public organizationalUnit?: string;
  public periodicity?: string;
  public serviceOfferingName?: string;
  public sourceDataItemName?: string;
  public subscriptionEndDate?: string;
  public subscriptionStartDate?: string;
  public totalRecordCount: number;
  public statusGroupName: string;
  public statusName: string;
  public statusID: number;

  constructor(obj?: IServiceSubscriptionQueryResponse) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class ServiceSubscriptionQueryResponseAdapter implements ModelAdapter<ServiceSubscriptionQueryResponse> {
  constructor(private commonService: CommonService) {}

  public adapt(source: any): ServiceSubscriptionQueryResponse {
    const target: ServiceSubscriptionQueryResponse = new ServiceSubscriptionQueryResponse(source);

    target.subscriptionStartDate = this.commonService.formatDateDDMMMYYYY(source.subscriptionStartDate);
    target.subscriptionEndDate = this.commonService.formatDateDDMMMYYYY(source.subscriptionEndDate);
    target.dueDate = this.commonService.formatDate(source.dueDate);

    return target;
  }
}
