import {
  CustomAttributeFormGroupSchemaModel,
  CustomAttributeModel,
  CustomAttributeOptionModel,
  CustomAttributeSchemaModel,
  CustomAttributesOptionModel,
  CustomAttributesRuleModel,
} from '../../models/custom-attribute';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { ApiResponse } from '../../models/response';
import { BaseComponent } from '../base-component';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ContextMenuAction } from '../../../config/constant';
import { CustomAttributeRulesService } from '../../services/custom-attribute-rules.service';
import { CustomAttributesConstants } from '../../constants/custom-attributes-constants';
import { CustomAttributesHelperService } from '../../services/custom-attributes-helper.service';
import { CustomAttributesService } from '../../services/custom-attributes.service';
import { DropdownOption } from '../../models/searchable-dropdown/dropdown-option.model';
import { ValidationPattern } from '../../constants/ValidationPattern';
import { debounceTime, map, of, switchMap, take, tap } from 'rxjs';
import { filterNonSuccessfullAndEmptyResponses } from '../../operators/api-response-operators';
import { pairwise, startWith, takeUntil } from 'rxjs/operators';

interface IExternalRule {
  domainID: number;
  field: string;
  formParentObjectKey: string;
  formParentObjectName: string;
}

@Component({
  selector: 'app-custom-attributes-accessor',
  templateUrl: './custom-attributes-accessor.component.html',
  styleUrls: ['./custom-attributes-accessor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomAttributesAccessorComponent),
      multi: true,
    },
  ],
})
export class CustomAttributesAccessorComponent extends BaseComponent implements ControlValueAccessor, OnInit {
  @Input() public readonly actionType: ContextMenuAction;
  @Input() public readonly parentForm: FormGroup;

  public readonly customAttributesConstants: typeof CustomAttributesConstants = CustomAttributesConstants;

  @Input() public customAttributeSchemas: CustomAttributeSchemaModel[];
  @Input() public forceDisable: boolean;

  public customAttributesFormGroup: FormGroup;

  constructor(
    private customAttributeRulesService: CustomAttributeRulesService,
    private customAttributesHelperService: CustomAttributesHelperService,
    private customAttributesService: CustomAttributesService,
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  public getDropdownOptions(options: CustomAttributeOptionModel[]): DropdownOption[] {
    return options
      ? options.map(
          (option: CustomAttributeOptionModel): DropdownOption => ({
            title: option.value,
            value: option.id,
          }),
        )
      : [];
  }

  public isRequired(schema: CustomAttributeSchemaModel): boolean {
    return (
      schema.isMandatory ||
      this.customAttributesFormGroup
        ?.get(schema.label)
        .get(this.customAttributesHelperService.getAttributePropertyAccessor(schema.controlType))
        .hasValidator(Validators.required)
    );
  }

  public ngOnInit(): void {
    this.customAttributeSchemas = structuredClone<CustomAttributeSchemaModel[]>(this.customAttributeSchemas);
    this.createFormFromSchemas();
    this.registerParentFormValueChangeEvent();

    this.forceDisable ??= [ContextMenuAction.view, ContextMenuAction.delete].includes(this.actionType);
    this.setDisabledState(this.forceDisable);

    if (this.actionType === ContextMenuAction.create) {
      this.customAttributesFormGroup.valueChanges.pipe(startWith(true), takeUntil(this.ngUnsubscribe)).subscribe(() => this.validateControlRules());
    }
  }

  public registerOnChange(fn: any): void {
    this.setDisabledState(this.forceDisable);

    this.customAttributesFormGroup.valueChanges
      .pipe(
        switchMap(() => of(this.customAttributesFormGroup.getRawValue())), //Needed to get values from disabled controls
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((data: { [key: string]: CustomAttributeFormGroupSchemaModel }) => {
        for (let attributeAccessor in data) {
          switch (data[attributeAccessor].controlType?.name) {
            case CustomAttributesConstants.CONTROL_DROPDOWN_BOOLEAN:
              const propertyControl: FormControl = this.customAttributesFormGroup
                .get(attributeAccessor)
                .get(CustomAttributesConstants.DICTIONARY_VALUE) as FormControl;

              if (data[attributeAccessor].value !== null && data[attributeAccessor].options) {
                const castedValue: number = this.customAttributesHelperService.convertBooleanDropdownValue(
                  data[attributeAccessor].value,
                  data[attributeAccessor].options,
                  'toResponse',
                ) as number;

                data[attributeAccessor].valueOptionId = castedValue;
                data[attributeAccessor].value = null;

                if (propertyControl.value != castedValue) {
                  propertyControl.patchValue(castedValue);
                }
              }
              break;
            case CustomAttributesConstants.CONTROL_DATE_PICKER:
              let controlValue = data[attributeAccessor][CustomAttributesConstants.STANDARD_VALUE];
              data[attributeAccessor][CustomAttributesConstants.STANDARD_VALUE] =
                typeof controlValue?.format === 'function' ? controlValue?.format('YYYY-MM-DD') : controlValue;
              break;
            default:
              break;
          }

          delete data[attributeAccessor].options;
          delete data[attributeAccessor].rules;
        }

        fn(Object.values(data));
      });
  }

  public registerOnTouched(fn: any): void {
    void fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.customAttributesFormGroup.disable() : this.customAttributesFormGroup.enable();
  }

  public writeValue(obj: { [key: string]: CustomAttributeModel }): void {
    if (obj) {
      Object.keys(obj).forEach(attributeAccessor => {
        const propertyAccessor: string = this.customAttributesHelperService.getAttributePropertyAccessor(obj[attributeAccessor].controlType);
        const value: number = obj[attributeAccessor][CustomAttributesConstants.DICTIONARY_VALUE];
        const attributeFormGroup: FormGroup = this.customAttributesFormGroup.get(obj[attributeAccessor].label) as FormGroup;

        switch (obj[attributeAccessor].controlType.name) {
          case CustomAttributesConstants.CONTROL_DROPDOWN_BOOLEAN:
            if (value) {
              attributeFormGroup
                .get(CustomAttributesConstants.STANDARD_VALUE)
                .setValue(
                  this.customAttributesHelperService.convertBooleanDropdownValue(
                    value,
                    attributeFormGroup.get(CustomAttributesConstants.OPTIONS_ACCESS_OPERATOR).value,
                    'fromRequest',
                  ) as boolean,
                );
            }
            break;
          default:
            this.customAttributesFormGroup
              .get(obj[attributeAccessor].label)
              ?.get(propertyAccessor)
              .setValue(obj[attributeAccessor][propertyAccessor]);
            break;
        }

        this.customAttributesFormGroup
          .get(obj[attributeAccessor].label)
          ?.get(CustomAttributesConstants.VALUE_ID)
          .setValue(obj[attributeAccessor][CustomAttributesConstants.VALUE_ID]);
      });

      if (this.actionType !== ContextMenuAction.create) {
        this.customAttributesFormGroup.valueChanges.pipe(startWith(true), takeUntil(this.ngUnsubscribe)).subscribe(() => this.validateControlRules());
      }
    }
  }

  private createFormFromSchemas(): void {
    this.customAttributesFormGroup = this.formBuilder.group(
      this.customAttributeSchemas.reduce((target: { [key: string]: FormGroup }, schema: CustomAttributeSchemaModel) => {
        target[schema.label] = this.formBuilder.group(
          {
            attributeTypeId: undefined,
            controlType: undefined,
            currentValue: undefined,
            customAttributeId: undefined,
            dataTypeName: undefined,
            isEditable: undefined,
            label: undefined,
            name: undefined,
            options: undefined,
            rules: undefined,
            value: undefined,
            valueId: undefined,
            valueOptionId: undefined,
          },
          {},
        );

        this.initializeAttributeControl(target[schema.label], schema);

        return target;
      }, {}),
    );
  }

  private initializeAttributeControl(control: FormGroup, schema: CustomAttributeSchemaModel): void {
    control.reset(schema);

    this.setInitialValue(schema.controlType['name'], control);

    if (schema.isMandatory) {
      control.get(this.customAttributesHelperService.getAttributePropertyAccessor(schema.controlType)).addValidators(Validators.required);
    }

    let maxLengthRule: CustomAttributesRuleModel;
    if (schema.rules?.length && (maxLengthRule = schema.rules.find(rule => rule.name === CustomAttributesConstants.RULE_MAX_LENGTH))) {
      control
        .get(this.customAttributesHelperService.getAttributePropertyAccessor(schema.controlType))
        .addValidators(Validators.maxLength(Number(maxLengthRule.ruleDefinition)));
    }

    switch (schema.controlType.name) {
      case CustomAttributesConstants.CONTROL_COMBO_BOX:
      case CustomAttributesConstants.CONTROL_CHIPS:
        control
          .get(this.customAttributesHelperService.getAttributePropertyAccessor(schema.controlType));
        break;

      case CustomAttributesConstants.CONTROL_INPUT_TEXT:
        control
          .get(this.customAttributesHelperService.getAttributePropertyAccessor(schema.controlType));
        break;
    }
  }

  private processExternalService(controlAccessor: string, attributeFormGroup: FormGroup, rule: any, shouldClear: boolean): void {
    const mappedRules: IExternalRule = rule as IExternalRule;
    const parentFormObject = this.parentForm.get(mappedRules.formParentObjectName).value;
    const value = parentFormObject?.[mappedRules.formParentObjectKey];

    if ((parentFormObject === null || value === null) && this.actionType !== ContextMenuAction.view && shouldClear) {
      attributeFormGroup.get(CustomAttributesConstants.DICTIONARY_VALUE).setValue(null);
      attributeFormGroup.get(CustomAttributesConstants.OPTIONS_ACCESS_OPERATOR).setValue([]);
      this.customAttributeSchemas.find((schema: CustomAttributeSchemaModel) => schema.label === controlAccessor).options = [];
      return;
    }

    this.customAttributesService
      .getCustomDictionaryFields({
        erf: {
          ParentName: value,
          DomainID: mappedRules.domainID,
        },
      })
      .pipe(
        filterNonSuccessfullAndEmptyResponses(),
        map((response: ApiResponse<CustomAttributesOptionModel[]>) => response.data),
        tap((data: CustomAttributesOptionModel[]) => {
          this.customAttributeSchemas.find((schema: CustomAttributeSchemaModel) => schema.label === controlAccessor).options = data;
          attributeFormGroup.get(CustomAttributesConstants.OPTIONS_ACCESS_OPERATOR).setValue(data);

          if (shouldClear) {
            switch (this.actionType) {
              case ContextMenuAction.create:
              case ContextMenuAction.edit:
                attributeFormGroup.get(CustomAttributesConstants.DICTIONARY_VALUE).setValue(null);
                break;
            }
          }
        }),
        take(1),
      )
      .subscribe();
  }

  private registerParentFormValueChangeEvent(): void {
    for (const controlAccessor in this.customAttributesFormGroup.controls) {
      const control: AbstractControl = this.customAttributesFormGroup.get(controlAccessor);

      for (const rule of control.get(CustomAttributesConstants.RULE_ACCESS_OPERATOR).value) {
        if (rule.name === CustomAttributesConstants.RULE_OPERATOR_LOOKUP || rule.name === CustomAttributesConstants.RULE_OPERATOR_EXTERNAl_SERVICE) {
          const ruleDefinition = JSON.parse(rule.ruleDefinition);

          this.parentForm.controls[ruleDefinition.field]?.valueChanges
            .pipe(
              startWith(null),
              pairwise(),
              tap(([previousValue, changeValue]) => {
                const shouldClear = previousValue !== changeValue;
                this.resolveRules(controlAccessor, ruleDefinition, changeValue, rule.name, shouldClear);
              }),
              debounceTime(500),
              takeUntil(this.ngUnsubscribe),
            )
            .subscribe();
        }
      }
    }
  }

  private resolveRules(
    controlAccessor: string,
    ruleDefinition: any,
    changeValue: number,
    ruleName: CustomAttributesConstants,
    shouldClear: boolean,
  ): void {
    const control: FormGroup = this.customAttributesFormGroup.get(controlAccessor) as FormGroup;
    const optionsControl: AbstractControl = control.get(CustomAttributesConstants.OPTIONS_ACCESS_OPERATOR);
    const schemaOptions: CustomAttributeOptionModel[] = this.customAttributeSchemas.find(
      (schema: CustomAttributeSchemaModel) => schema.label === controlAccessor,
    )[CustomAttributesConstants.OPTIONS_ACCESS_OPERATOR];

    switch (ruleName) {
      case CustomAttributesConstants.RULE_OPERATOR_LOOKUP:
        optionsControl.setValue(
          optionsControl.value.filter((el: CustomAttributeOptionModel) =>
            el.value.match(new RegExp(ruleDefinition.values.filter(el => el.valueFrom == changeValue)[0]?.filter, 'gm')),
          ),
        );

        ruleDefinition?.match == CustomAttributesConstants.RULE_MATCH_MODE && optionsControl.value.length == schemaOptions.length
          ? control.disable()
          : control.enable();
        return;
      case CustomAttributesConstants.RULE_OPERATOR_EXTERNAl_SERVICE:
        this.processExternalService(controlAccessor, control, ruleDefinition, shouldClear);
        return;
    }
  }

  private setInitialValue(controlTypeName: string, controlFormGroup: FormGroup): void {
    if (
      controlTypeName === CustomAttributesConstants.CONTROL_DROPDOWN_BOOLEAN &&
      (this.actionType === ContextMenuAction.create || this.actionType === ContextMenuAction.edit)
    ) {
      const options = controlFormGroup.get(CustomAttributesConstants.OPTIONS_ACCESS_OPERATOR)?.value;
      if (options?.length) {
        const falseOption = options.find((item: { value: string; id: any }) => item.value === '0');
        const falseOptionId = falseOption?.id;
        if (falseOptionId != null) {
          controlFormGroup.get(CustomAttributesConstants.DICTIONARY_VALUE)?.setValue(falseOptionId);
        }
      }
    }
  }

  private validateControlRules(): void {
    if (this.customAttributesFormGroup) {
      for (let controlAccessor in this.customAttributesFormGroup.controls) {
        const controlFormGroup: FormGroup = this.customAttributesFormGroup.get(controlAccessor) as FormGroup;

        if (!controlFormGroup.get(CustomAttributesConstants.RULE_ACCESS_OPERATOR).value) {
          return;
        }
        const attributeValueControl: FormControl = controlFormGroup.get(
          this.customAttributesHelperService.getAttributePropertyAccessor(controlFormGroup.get('controlType').value),
        ) as FormControl;

        const isDisabled: boolean = this.customAttributeRulesService.disableFieldBasedOnRules(controlFormGroup, this.customAttributesFormGroup);

        if (!this.forceDisable && isDisabled != controlFormGroup.disabled) {
          isDisabled ? controlFormGroup.disable({ emitEvent: false }) : controlFormGroup.enable({ emitEvent: false });
          attributeValueControl.reset();
        }

        const isRequired: boolean = this.customAttributeRulesService.isRequired(controlFormGroup, this.customAttributesFormGroup);

        if (isRequired != attributeValueControl.hasValidator(Validators.required)) {
          isRequired ? attributeValueControl.addValidators(Validators.required) : attributeValueControl.removeValidators(Validators.required);
          attributeValueControl.updateValueAndValidity();
        }
      }
    }
  }
}
