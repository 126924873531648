import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ParameterItem } from '../../models/custom-attribute';
import { CommonService } from '../../services/common.service';
import { ValidationPattern } from '../../constants/ValidationPattern';

@Component({
  selector: 'app-custom-parameters',
  templateUrl: './custom-parameters.component.html',
  styleUrls: ['./custom-parameters.component.scss'],
})
export class CustomParametersComponent implements OnChanges {
  @Input() public displayName: string;
  @Input() public operationType: 'create' | 'delete' | 'edit' | 'view';
  @Input() public parameterId: number;
  @Input() public parameterItems: Array<ParameterItem> = [];

  public customParametersFormArray: FormArray;
  public form: FormGroup;
  public isNew: boolean = false;

  public get ParameterItemsFormArray(): FormArray {
    return this.form.get('parameterItems') as FormArray;
  }

  public get isReadonly() {
    return this.operationType === 'view' || this.operationType === 'delete';
  }

  constructor(private formBuilder: FormBuilder, private commonService: CommonService) {}

  public createForm(current: ParameterItem[]) {
    const items = this.isNew ? null : current.map(() => this.createParameterItemFormGroup());
    const parameterItemsArray = this.isNew ? this.formBuilder.array([]) : this.formBuilder.array(items);

    this.form = this.formBuilder.group({
      parameterItems: parameterItemsArray,
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.parameterItems != null) {
      this.createForm(this.parameterItems);

      const data = { parameterItems: this.parameterItems };
      this.form.reset(data);

      this.customParametersFormArray = this.ParameterItemsFormArray.value;
    }
  }

  private createParameterItemFormGroup(): FormGroup {
    return this.formBuilder.group({
      name: undefined,
      value: [undefined, [Validators.maxLength(500)]],
    });
  }
}
