<div class="filter">
  <div class="filter__heading">Filters</div>
  <div class="filter__action">
    <button
      data-testid="filter-button"
      (click)="clearFilter()"
      [disabled]="!isFilterSelected"
      class="button--link"
      mat-button
      >{{ isFilterContentEmpty ? '' : 'Clear All' }}
    </button>
  </div>
</div>

<div
  *ngIf="isFilterContentEmpty"
  class="filter__no-content"
>
  No Filters Available
</div>
<div
  *ngIf="!isFilterContentEmpty"
  class="filter__content"
>
  <mat-accordion [multi]="true">
    <ng-container *ngFor="let data of filterData; let i = index">
      <ng-container *ngIf="!data.isHidden">
        <mat-expansion-panel
          *ngIf="data.label !== filterTypes.serviceInstanceDate"
          [attr.data-testid]="'filter-item-' + i"
        >
          <mat-expansion-panel-header [attr.data-testid]="data.domain + '_' + data.label + '-header'">
            <mat-panel-title>
              <span
                [translate]="data.domain + '/' + data.label"
                [translateParams]="this.translateDefaultValue(data.defaultValue)"
              ></span>
              <span class="filter__selected-filters-counter">
                {{ data.data | filterCountTitle }}
              </span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div
            *ngIf="data.isSearch"
            class="filter__search-container"
          >
            <mat-form-field class="filter__search-input">
              <mat-label [attr.data-testid]="data.label + '_' + data.label + '-search-label'">Search</mat-label>
              <input
                [attr.data-testid]="data.label + '_' + data.label + '-search-input'"
                (keyup)="filterOnKeyPress(data.type, i)"
                [formControl]="getSearchInput(i)"
                id="searchField_{{ data.type }}"
                matInput
                type="text"
              />
              <button
                [attr.data-testid]="data.label + '_' + data.label + '-clear-button'"
                (click)="removeFilterText(data.type, i)"
                mat-icon-button
                matSuffix
              >
                <mat-icon>
                  <ng-container *ngIf="!getSearchInput(i)?.value; else crossIcon"> search </ng-container>

                  <ng-template #crossIcon> close </ng-template>
                </mat-icon>
              </button>
            </mat-form-field>
          </div>

          <div
            [ngClass]="{ 'filter__options-container--is-search-box': data.isSearch }"
            class="filter__options-container"
          >
            <div
              class="filter__checkbox-wrapper filter__checkbox-wrapper--top"
              *ngIf="(data.data | selectedFilters : true)?.length"
              [attr.data-testid]="'filter-checkbox-' + i"
            >
              <div *ngFor="let subData of data.data | selectedFilters : true; trackBy: trackFilterData">
                <div
                  *ngIf="!subData.isHidden"
                  class="filter__item"
                >
                  <mat-checkbox
                    (change)="createFilterItems($event.checked, data, subData)"
                    [(ngModel)]="subData.isSelected"
                    [attr.data-testid]="'filter-checkbox-input-' + subData.filterCatalog"
                    [checked]="subData.isSelected"
                    [disableRipple]="true"
                    class="filter__checkbox"
                    color="primary"
                  >
                  </mat-checkbox>
                  <span
                    class="filter__item-label"
                    [matTooltip]="subData.filterCatalog | activeInactive"
                    [matTooltipClass]="'dcms-mat-tooltip--without-elipse'"
                    >{{ subData.filterCatalog | activeInactive }}</span
                  >
                </div>
              </div>
              <div id="snap"></div>
            </div>

            <div
              class="filter__checkbox-wrapper"
              *ngIf="(data.data | selectedFilters : false)?.length"
            >
              <div *ngFor="let subData of data.data | selectedFilters : false; trackBy: trackFilterData">
                <div
                  *ngIf="!subData.isHidden"
                  class="filter__item"
                >
                  <mat-checkbox
                    (change)="createFilterItems($event.checked, data, subData)"
                    [(ngModel)]="subData.isSelected"
                    [attr.data-testid]="'filter-checkbox-input-' + subData.filterCatalog"
                    [checked]="subData.isSelected"
                    class="filter__checkbox"
                    color="primary"
                    [disableRipple]="true"
                  >
                  </mat-checkbox>
                  <span
                    class="filter__item-label"
                    [matTooltip]="subData.filterCatalog | activeInactive"
                    [matTooltipClass]="'dcms-mat-tooltip--without-elipse'"
                    >{{ subData.filterCatalog | activeInactive }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="data.label === filterTypes.serviceInstanceDate || data.isDate">
          <mat-expansion-panel-header [attr.data-testid]="data.label + '_' + data.label + '-header'">
            <mat-panel-title
              [translate]="data.domain + '/' + data.label"
              [translateParams]="this.translateDefaultValue(data.defaultValue)"
            ></mat-panel-title>
          </mat-expansion-panel-header>
          <div class="options-container">
            <mat-form-field>
              <mat-label data-testid="filter-select-dates-label">Select Dates</mat-label>
              <mat-date-range-input
                [rangePicker]="dateRangePicker"
                class="input-cr-raised-on"
              >
                <input
                  #startDate
                  data-testid="'filter-'+ data.label +'-start-date-input'"
                  (click)="dateRangePicker.open()"
                  (dateChange)="dateFilterChange(startDate.value, data.defaultValue, 'start')"
                  matStartDate
                  placeholder="Start date"
                />
                <input
                  #endDate
                  data-testid="filter-'+ data.label +'-end-date-input"
                  (click)="dateRangePicker.open()"
                  (dateChange)="dateFilterChange(endDate.value, data.defaultValue, 'end')"
                  matEndDate
                  placeholder="End date"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                [for]="dateRangePicker"
                matSuffix
              >
              </mat-datepicker-toggle>
              <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </ng-container>
    </ng-container>

    <ng-container *ngFor="let data of customAttributesFilterData; let i = index">
      <mat-expansion-panel
        *ngIf="!data.isDate"
        (opened)="panelOpened(data, i)"
      >
        <mat-expansion-panel-header [attr.data-testid]="data.label + '_' + data.label + '-header'">
          <mat-panel-title>
            <span>
              {{ data.label }}
            </span>
            <span class="filter__selected-filters-counter">
              {{ data.data | filterCountTitle }}
            </span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div
          *ngIf="data.isSearch"
          class="filter__search-container"
        >
          <mat-form-field class="filter__search-input">
            <mat-label [attr.data-testid]="data.label + '_' + data.label + '-label'">Search</mat-label>
            <input
              [attr.data-testid]="data.label + '_' + data.label + '-input'"
              (keyup)="filterCustomAttributesOnKeyPress(data, i)"
              [formControl]="getSearchInput(i)"
              id="searchField_{{ data.type }}"
              matInput
              type="text"
            />
            <button
              [attr.data-testid]="data.label + '_' + data.label + '-button'"
              (click)="clearCustomAttributesFilterText(data, i)"
              mat-icon-button
              matSuffix
            >
              <mat-icon>
                <ng-container *ngIf="!getSearchInput(i).value; else crossIcon"> search </ng-container>

                <ng-template #crossIcon> close </ng-template>
              </mat-icon>
            </button>
          </mat-form-field>
        </div>

        <div
          [ngClass]="{ 'filter__options-container--is-search-box': data.isSearch }"
          class="filter__options-container"
        >
          <div
            class="filter__checkbox-wrapper filter__checkbox-wrapper--top"
            *ngIf="(data.data | selectedFilters : true).length"
          >
            <div *ngFor="let subData of data.data | selectedFilters : true; index as selectedSubI; trackBy: trackFilterData">
              <div
                *ngIf="!subData.isHidden"
                class="filter__item"
              >
                <mat-checkbox
                  [attr.data-testid]="data.label + '_checkbox_' + selectedSubI"
                  (change)="createCustomAttributesFilterItems($event.checked, data, subData)"
                  [(ngModel)]="subData.isSelected"
                  [checked]="subData.isSelected"
                  [disableRipple]="true"
                  class="filter__checkbox"
                  color="primary"
                >
                </mat-checkbox>
                <span
                  class="filter__item-label"
                  [matTooltip]="
                    data.type === customAttributesConstants.CONTROL_INPUT_TEXT ? subData.filterCatalog : (subData.filterCatalog | activeInactive)
                  "
                >
                  {{ data.type === customAttributesConstants.CONTROL_INPUT_TEXT ? subData.filterCatalog : (subData.filterCatalog | activeInactive) }}
                </span>
              </div>
            </div>
          </div>

          <div
            class="filter__checkbox-wrapper"
            *ngIf="(data.data | selectedFilters : false).length"
          >
            <div *ngFor="let subData of data.data | selectedFilters : false; index as subI; trackBy: trackFilterData">
              <div
                *ngIf="!subData.isHidden"
                class="filter__item"
              >
                <mat-checkbox
                  [attr.data-testid]="data.label + '_checkbox_' + subI"
                  (change)="createCustomAttributesFilterItems($event.checked, data, subData)"
                  [(ngModel)]="subData.isSelected"
                  [checked]="subData.isSelected"
                  class="filter__checkbox"
                  color="primary"
                  [disableRipple]="true"
                >
                </mat-checkbox>
                <span
                  class="filter__item-label"
                  [matTooltip]="
                    data.type === customAttributesConstants.CONTROL_INPUT_TEXT ? subData.filterCatalog : (subData.filterCatalog | activeInactive)
                  "
                >{{ data.type === customAttributesConstants.CONTROL_INPUT_TEXT ? subData.filterCatalog.trim() : (subData.filterCatalog.trim() | activeInactive) }}</span>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="data.isDate">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ data.label }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="options-container">
          <mat-form-field>
            <mat-label data-testid="filter-select-dates-label2">Select Dates</mat-label>
            <mat-date-range-input
              [rangePicker]="dateRangePicker"
              class="input-cr-raised-on"
            >
              <input
                [attr.data-testid]="'filter_' + data.label + '_start-date-input'"
                (click)="dateRangePicker.open()"
                (dateChange)="customAttributeDateFilterChange(data)"
                [(ngModel)]="data.customAttributeStartDate"
                matStartDate
                placeholder="Start date"
              />
              <input
                [attr.data-testid]="'filter_' + data.label + '_end-date-input'"
                (click)="dateRangePicker.open()"
                (dateChange)="customAttributeDateFilterChange(data)"
                [(ngModel)]="data.customAttributeEndDate"
                matEndDate
                placeholder="End date"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              [for]="dateRangePicker"
              matSuffix
            >
            </mat-datepicker-toggle>
            <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
          </mat-form-field>
        </div>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</div>

<div
  *ngIf="!isFilterContentEmpty"
  class="filter__footer"
>
  <button
    (click)="applyFilter()"
    [disabled]="canApplyFilter"
    class="filter__footer-apply-filter"
    color="primary"
    data-testid="filter_button_apply-filters"
    mat-flat-button
    type="button"
    >Apply Filters
  </button>
</div>
