<div
  class="confirmation-dialog"
  id="confirmationDialog"
>
  <div class="modal-header">
    <h4
      class="modal-title"
      id="modal-basic-title"
      >{{ options.title }}</h4
    >
    <ng-container *ngIf="options.showCloseButton">
      <button
        data-testid="confirmtion-close-button"
        (click)="modal.dismiss('Cross click')"
        aria-label="Close"
        class="material-icons close-icon"
        type="button"
      >
        <mat-icon>close</mat-icon>
      </button>
    </ng-container>
  </div>
  <div class="modal-body confirmation-dialog-body">
    <p>
      <span class="confirmation-dialog-info">{{ options.message }}</span>
      <span
        *ngIf="options.remarks"
        class="confirmation-dialog-remarks"
        >{{ options.remarks }}</span
      >
    </p>
    <p
      *ngIf="options.note"
      class="confirmation-dialog-warn-message"
    >
      Note: {{ options.note }}
    </p>
  </div>
  <div class="modal-footer confirmation-dialog-footer">
    <button
      data-testid="confirmtion-confirm-button"
      (click)="onConfirmClick()"
      color="accent"
      mat-flat-button
      type="button"
    >
      Yes, I am sure
    </button>
    <button
      data-testid="confirmtion-cancel-button"
      (click)="modal.dismiss('Cross click')"
      color="primary"
      mat-flat-button
      type="button"
    >
      Cancel
    </button>
  </div>
</div>
