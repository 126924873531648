<div
  class="center bulkupload-modal"
  id="sessionModal"
>
</div>
<div class="modal-body">
  <span class="session-text-title"
    >Another session already exists for the logged in user ID. If you proceed, other session would be inactivated.</span
  >
</div>
<div class="modal-footer">
  <button
    data-testid="overview-modal-continue-button"
    (click)="close(true)"
    class="session-modal__info-button"
    color="accent"
    mat-flat-button
    type="button"
  >
    Yes, Continue
  </button>

  <button
    data-testid="overview-modal-cancel-button"
    (click)="close(false)"
    color="primary"
    mat-flat-button
    type="button"
  >
    Cancel
  </button>
</div>
