import { ApiResponse } from '../../models/response';
import { CommonFilter } from 'src/app/shared/models/filter/common-filter.model';
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChildren, OnInit } from '@angular/core';
import { CustomAttributeDateFilterParameters, CustomAttributeFilterResponseCollection } from '../../models/filter';
import { CustomAttributeFilterParameters } from '../../models/filter';
import { CustomAttributesConstants } from '../../constants/custom-attributes-constants';
import { CustomAttributesService } from '../../services/custom-attributes.service';
import { Subject, debounceTime, distinctUntilChanged, iif, map, of, switchMap, tap } from 'rxjs';
import { FilterData } from '../../models/filter';
import { FilteredDateItemsDataModel } from 'src/app/core/model/filtered-date-items-data.model';
import { FilteredItemsDataModel } from '../../../core/model/filtered-items-data.model';
import { FilterService } from '../../services/filter.service';
import { FilterTypesLabels } from '../../../config/constant';
import { FormArray, FormControl } from '@angular/forms';
import { MatEndDate, MatStartDate } from '@angular/material/datepicker';
import { translateDefaultValue } from 'src/app/modules/translations/missing-attribute-translation-handler';
import { ISearchTermFilteredDataModel } from '../../interfaces/search-term-filtered-data-model.interface';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnChanges, OnInit {
  private searchTerms = new Subject<ISearchTermFilteredDataModel>();

  public readonly customAttributesConstants: typeof CustomAttributesConstants = CustomAttributesConstants;

  @Input() public customAttributesFilterData: Array<FilterData>;
  @Input() public filterData: Array<FilterData> = [];
  @Input() public handleDate: boolean = false;
  @Input() public isFilterContentEmpty: boolean;
  @Input() public preventResetOnChange: boolean = false;
  @Input() public referencedTableName: string = '';
  @Output() public applySelectedFilters: EventEmitter<FilteredItemsDataModel> = new EventEmitter<FilteredItemsDataModel>();
  @ViewChildren('endDate', { read: MatEndDate }) public endDateInputs: MatEndDate<any>[];
  @ViewChildren('startDate', { read: MatStartDate }) public startDateInputs: MatStartDate<any>[];

  public canApplyFilter: boolean = false;
  public customAttributeEndDate: Date;
  public customAttributeStartDate: Date;
  public filterTypes = FilterTypesLabels;
  public filteredItemsData: FilteredItemsDataModel = {
    filteredStandardCustomAttributes: {},
    filteredDateCustomAttributes: {},
    filteredItems: {},
  };
  public initialLoadedfreeTextFilters: Set<number> = new Set();
  public isFilterSelected: boolean = false;
  public searchInputsArray: FormArray<any> = new FormArray([]);
  public searchText: string;
  public translateDefaultValue = translateDefaultValue;

  constructor(private customAttributesService: CustomAttributesService, private filterService: FilterService) {}

  public applyFilter(): void {
    this.applySelectedFilters.emit(this.filteredItemsData);
  }

  public clearCustomAttributesFilterText(refData: FilterData, controlIndex: number) {
    this.getSearchInput(controlIndex).setValue('');
    this.searchTerms.next(<ISearchTermFilteredDataModel>{ term: '', refData: refData, controlIndex: controlIndex, isInitialLoad: true });
  }

  public clearFilter() {
    this.initialLoadedfreeTextFilters.clear();
    this.isFilterSelected = false;

    this.startDateInputs.forEach(input => input.writeValue(null));
    this.endDateInputs.forEach(input => input.writeValue(null));

    this.filteredItemsData = {
      filteredStandardCustomAttributes: {},
      filteredDateCustomAttributes: {},
      filteredItems: {},
    };

    this.filterData.map((data: FilterData) => {
      data.data?.map(item => {
        item.isHidden = false;
        item.isSelected = false;
      });
    });

    if (this.customAttributesFilterData != null) {
      this.customAttributesFilterData.map((data: FilterData, controlIndex) => {
        data.customAttributeEndDate = null;
        data.customAttributeStartDate = null;

        data.data?.map(item => {
          item.isHidden = false;
          item.isSelected = false;
        });

        if (data.isSearch) {
          this.clearCustomAttributesFilterText(data, controlIndex);
        }
      });
    }

    this.applySelectedFilters.emit(this.filteredItemsData);
  }

  public createCustomAttributesFilterItems(checked, data: FilterData, subData: CommonFilter) {
    const filteredStandardCustomAttributes = this.filteredItemsData.filteredStandardCustomAttributes;
    const filterType: string = data.label;

    const filterParameters: CustomAttributeFilterParameters = {
      customAttributeID: subData.customAttributeID,
      customAttributeValueID: subData.filterID,
    };

    if (!filteredStandardCustomAttributes[filterType]) {
      filteredStandardCustomAttributes[filterType] = [];
    }

    if (checked) {
      filteredStandardCustomAttributes[filterType].push(filterParameters);
    } else {
      filteredStandardCustomAttributes[filterType].splice(
        filteredStandardCustomAttributes[filterType].findIndex(element => JSON.stringify(element) === JSON.stringify(filterParameters)),
        1,
      );
    }
    this.enableClearAllButton();
  }

  public createFilterItems(checked, data: FilterData, subData: CommonFilter) {
    const filteredItems = this.filteredItemsData.filteredItems;
    const filterType: string = data.type;

    if (!filteredItems[filterType]) {
      filteredItems[filterType] = [];
    }

    if (checked) {
      filteredItems[filterType].push(subData.filterID);
    } else {
      filteredItems[filterType].splice(filteredItems[filterType].indexOf(subData.filterID), 1);
    }
    this.enableClearAllButton();
  }

  public customAttributeDateFilterChange(data: any) {
    let filteredDateCustomAttributes = this.filteredItemsData.filteredDateCustomAttributes;

    if (!filteredDateCustomAttributes[data.label]) {
      filteredDateCustomAttributes[data.label] = [];
      filteredDateCustomAttributes[data.label].push({
        filterType: data.label,
        startDate: data.customAttributeStartDate,
        endDate: data.customAttributeEndDate,
      });
    } else {
      filteredDateCustomAttributes[data.label].pop();
      filteredDateCustomAttributes[data.label].push({
        filterType: data.label,
        startDate: data.customAttributeStartDate,
        endDate: data.customAttributeEndDate,
      });
    }
    this.enableApplyFilterButton(this.filteredItemsData.filteredDateCustomAttributes);
    this.enableClearAllButton();
  }

  public dateFilterChange(date: string, label: string, dateType: string) {
    if (this.handleDate && date != null) {
      const newDate: string = date.split('/').reverse().join('-');
      if (dateType === 'start') {
        this.filteredItemsData.filteredItems[label] = {
          ...(this.filteredItemsData.filteredItems[label] ?? {}),
          startDate: newDate,
        };
      } else if (dateType === 'end') {
        this.filteredItemsData.filteredItems[label] = {
          ...(this.filteredItemsData.filteredItems[label] ?? {}),
          endDate: newDate,
        };
      }
    }
    this.enableClearAllButton();
  }

  public filterCustomAttributesOnKeyPress(refData: FilterData, controlIndex: number) {
    const searchInput = this.getSearchInput(controlIndex);
    const searchTerm: string = searchInput.value.trim() ?? '';

    if (searchTerm?.length) {
      this.searchTerms.next(<ISearchTermFilteredDataModel>{ term: searchTerm, refData: refData, controlIndex: controlIndex });
    } else {      
      this.clearCustomAttributesFilterText(refData, controlIndex); 
    }
  }

  public filterOnKeyPress(filterType: string, controlIndex: number) {    
    this.searchText = this.getSearchInput(controlIndex).value;
    if (this.searchText) {
      this.filterData.map((data: FilterData) => {
        if (data.type === filterType) {
          data.data.map((item: CommonFilter) => {
            item.isHidden = !item.filterCatalog?.toLowerCase().includes(this.searchText.toLowerCase());
          });
        }
      });
    } else {         
      this.filterData.map(data => {
        if (data.type === filterType) {
          data.data.map(item => {
            item.isHidden = false;
          });
        }
      });
    }
  }

  public getSearchInput(index: number): FormControl {
    return this.searchInputsArray.at(index) as FormControl;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.preventResetOnChange) {
      this.initialLoadedfreeTextFilters.clear();

      this.filteredItemsData = {
        filteredStandardCustomAttributes: {},
        filteredDateCustomAttributes: {},
        filteredItems: {},
      };
      this.isFilterSelected = false;
    }
    this.insertSearchRequiredStatus();

    if (this.customAttributesFilterData?.length) {
      this.customAttributesFilterData.forEach((_, index) => {
        const control = new FormControl('');
        this.searchInputsArray.insert(index, control);
      });
    }
  }

  public ngOnInit(): void {
    this.searchTerms
      .pipe(
        switchMap((event: ISearchTermFilteredDataModel) =>
          iif(
            () => !!event.isInitialLoad,
            of(event),
            of(event).pipe(
              debounceTime(300),
              distinctUntilChanged((prev, curr) => prev.term === curr.term && prev.controlIndex === curr.controlIndex),
            ),
          ),
        ),
        switchMap((event: ISearchTermFilteredDataModel) =>
          this.customAttributesService.getCustomAttributeFreeTextFilters({
            referencedTableName: this.referencedTableName,
            filterCatalogGroup: event.refData.label,
            searchText: event.term,
          }),
        ),
        map((response: ApiResponse<{ collection: CustomAttributeFilterResponseCollection<number> }>) => response.data.collection),
        map(collection => {
          return {
            type: collection.customAttributeControlType,
            label: collection.filterCatalogGroup,
            data: collection.customAttributeFilterResponses.map(response => this.filterService.filterResponseToCommonFilter(response)),
          } as FilterData;
        }),
        tap(filterData => {
          const index = this.customAttributesFilterData.findIndex(data => data.type === filterData.type && data.label === filterData.label);
          if (index !== -1) {
            this.customAttributesFilterData[index].isSearch = true;
            this.customAttributesFilterData[index].data = filterData.data;
          }
        }),
      )
      .subscribe();
  }

  public panelOpened(refData: FilterData, controlIndex: number) {
    if (refData.type === this.customAttributesConstants.CONTROL_INPUT_TEXT) {
      if (!this.initialLoadedfreeTextFilters.has(controlIndex)) {
        this.initialLoadedfreeTextFilters.add(controlIndex);
        this.searchTerms.next(<ISearchTermFilteredDataModel>{ term: '', refData: refData, controlIndex: controlIndex, isInitialLoad: true });
      }
    }
  }

  public removeFilterText(filterType: string, controlIndex: number) {
    if (this.searchInputsArray.at(controlIndex).value) {
      this.searchText = '';
      this.getSearchInput(controlIndex).reset();
      this.filterData.map(data => {
        if (data.type === filterType) {
          data.data.map(item => {
            item.isHidden = false;
          });
        }
      });
    }
  }

  public trackFilterData(index, item) {
    return item.filterID !== null && item.filterID !== undefined ? item.filterID : item.filterCatalog;
  }

  private enableApplyFilterButton(dateFilterItems: FilteredDateItemsDataModel): void {
    this.canApplyFilter = false;

    for (const displayNameKey in dateFilterItems) {
      const attributeParameters: CustomAttributeDateFilterParameters[] = dateFilterItems[displayNameKey];

      for (let item of attributeParameters) {
        if (item.startDate === null && item.endDate === null) {
          continue;
        }
        this.canApplyFilter = this.canApplyFilter || item.startDate == null || item.endDate == null || item.startDate > item.endDate;
        if (this.canApplyFilter) {
          break;
        }
      }

      if (this.canApplyFilter) {
        break;
      }
    }
  }

  private enableClearAllButton() {
    let filteredItems = this.filteredItemsData.filteredItems;
    let filteredStandardCustomAttributes = this.filteredItemsData.filteredStandardCustomAttributes;
    let filteredDateCustomAttributes = this.filteredItemsData.filteredDateCustomAttributes;

    this.isFilterSelected = false;

    for (let key in filteredItems) {
      if (filteredItems[key] && (filteredItems[key].length || typeof filteredItems[key] === 'object')) {
        this.isFilterSelected = true;
      }
    }

    for (let key in filteredStandardCustomAttributes) {
      if (filteredStandardCustomAttributes[key] && filteredStandardCustomAttributes[key].length) {
        this.isFilterSelected = true;
      }
    }

    for (let key in filteredDateCustomAttributes) {
      filteredDateCustomAttributes[key].map((filter: CustomAttributeDateFilterParameters) => {
        if (!!filter.endDate || !!filter.startDate) {
          this.isFilterSelected = true;
        }
      });
    }
  }

  private insertSearchRequiredStatus(): void {
    this.removeAllSearchInputs();

    this.filterData?.forEach((data: FilterData) => {
      data.isSearch = data?.data?.length > 3 || false;
      this.searchInputsArray.push(new FormControl(''));
    });
  }

  private removeAllSearchInputs(): void {
    this.customAttributesFilterData?.forEach((data: FilterData) => {
      if (data.data && data?.type === this.customAttributesConstants.CONTROL_INPUT_TEXT) {
        data.data = [];
        data.isSearch = false;
      }
    });

    this.searchInputsArray = new FormArray([]);
  }
}
