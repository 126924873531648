<ng-template
  #customAttributeControlsTemplate
  let-title="title"
  let-customAttributeTypeFilterValue="customAttributeTypeFilterValue"
>
  <ng-container [formGroup]="customAttributesFormGroup">
    <div
      *ngIf="!!(customAttributeSchemas | filterByAttributeTypeId : customAttributeTypeFilterValue).length"
      class="row input-grid"
    >
      <h5>{{ title }}</h5>
      <ng-container *ngFor="let schema of customAttributeSchemas | filterByAttributeTypeId : customAttributeTypeFilterValue">
        <div
          [ngSwitch]="schema.controlType.name"
          class="col-md-6 margin-top"
          [formGroupName]="schema.label"
        >
          <ng-container *ngSwitchCase="customAttributesConstants.CONTROL_INPUT_TEXT">
            <mat-form-field>
              <mat-label data-testid="custom-attributes-free-text-label">{{ schema.name }}</mat-label>
              <input
                [attr.data-testid]="'custom-attributes_text_' + schema.label"
                [formControlName]="customAttributesConstants.STANDARD_VALUE"
                aria-label="Text attribute"
                matInput
              />
              <mat-error>
                <app-validation-message
                  [field]="customAttributesFormGroup.get(schema.label).get(customAttributesConstants.STANDARD_VALUE)"
                ></app-validation-message>
              </mat-error>
            </mat-form-field>
          </ng-container>
          <ng-container *ngSwitchCase="customAttributesConstants.CONTROL_INPUT_EMAIL">
            <mat-form-field>
              <mat-label data-testid="custom-attributes-email-text-label">{{ schema.name }}</mat-label>
              <input
                [attr.data-testid]="'custom-attributes_email_' + schema.label"
                [formControlName]="customAttributesConstants.STANDARD_VALUE"
                aria-label="Text attribute"
                matInput
              />
              <mat-error>
                <app-validation-message
                  [field]="customAttributesFormGroup.get(schema.label).get(customAttributesConstants.STANDARD_VALUE)"
                ></app-validation-message>
              </mat-error>
            </mat-form-field>
          </ng-container>
          <ng-container *ngSwitchCase="customAttributesConstants.CONTROL_DATE_PICKER">
            <mat-form-field>
              <mat-label data-testid="custom-attributes-date-label">{{ schema.name }}</mat-label>
              <mat-datepicker-toggle
                [for]="customAttributeDatePicker"
                matSuffix
              ></mat-datepicker-toggle>
              <input
                (click)="customAttributeDatePicker.open()"
                [attr.data-testid]="'custom-attributes_date_' + schema.label"
                [formControlName]="customAttributesConstants.STANDARD_VALUE"
                [matDatepicker]="customAttributeDatePicker"
                aria-label="Date attribute"
                class="date-picker"
                matInput
              />
              <mat-datepicker #customAttributeDatePicker></mat-datepicker>
              <mat-error>
                <app-validation-message
                  [field]="customAttributesFormGroup.get(schema.label).get(customAttributesConstants.STANDARD_VALUE)"
                ></app-validation-message>
              </mat-error>
            </mat-form-field>
          </ng-container>
          <ng-container
            *ngSwitchCase="
              schema.controlType.name === customAttributesConstants.CONTROL_DROPDOWN_MULTI_OPTION ||
              schema.controlType.name === customAttributesConstants.CONTROL_DROPDOWN_TWO_OPTION
                ? schema.controlType.name
                : 'notincase'
            "
          >
            <app-searchable-dropdown
              [formControlName]="customAttributesConstants.DICTIONARY_VALUE"
              [label]="schema.name"
              [required]="isRequired(schema)"
              [testIdControlName]="'attribute' + schema.label"
              [dropdownOptions]="getDropdownOptions(schema.options)"
              testIdArea="custom-attributes"
            ></app-searchable-dropdown>
          </ng-container>
          <ng-container *ngSwitchCase="customAttributesConstants.CONTROL_DROPDOWN_BOOLEAN">
            <mat-slide-toggle
              [attr.data-testid]="'custom-attributes-boolean-toggle_' + schema.label"
              [formControlName]="customAttributesConstants.STANDARD_VALUE"
              >{{ schema.name }}
            </mat-slide-toggle>
          </ng-container>
          <ng-container *ngSwitchCase="customAttributesConstants.CONTROL_COMBO_BOX">
            <app-combobox-input
              [label]="schema.name"
              [options]="schema.options"
              [required]="isRequired(schema)"
              [validators]="this.customAttributesFormGroup.get(schema.label).get(customAttributesConstants.STANDARD_VALUE).validator"
              [formControlName]="customAttributesConstants.STANDARD_VALUE"
            >
            </app-combobox-input>
          </ng-container>
          <ng-container *ngSwitchCase="customAttributesConstants.CONTROL_CHIPS">
            <app-chips-input
              [label]="schema.name"
              [formControlName]="customAttributesConstants.STANDARD_VALUE"
              [required]="isRequired(schema)"
            >
            </app-chips-input>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<form [formGroup]="customAttributesFormGroup">
  <ng-container
    [ngTemplateOutlet]="customAttributeControlsTemplate"
    [ngTemplateOutletContext]="{
      title: 'Extended Fields',
      customAttributeTypeFilterValue: 3
    }"
  ></ng-container>
  <ng-container
    [ngTemplateOutlet]="customAttributeControlsTemplate"
    [ngTemplateOutletContext]="{
      title: 'Custom Fields',
      customAttributeTypeFilterValue: 1
    }"
  ></ng-container>
</form>
