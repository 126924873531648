<div
  *ngIf="isVisible"
  class="row"
>
  <div class="col-md-6">
    <div class="row left-side-container">
      <img src="../../../../assets/images/landingWindow-min.png" />
    </div>
  </div>
  <div class="col-md-5 right-side-container">
    <div class="col-md-11 user-name-welcome-container">
      <img
        width="70px"
        src="../../../../assets/images/ey-logo-beam.png"
      />
      <span>
        Thank you! You have successfully logged out of the application. To relogin <a (click)="navigateToLandingPage()"><b>click here</b></a>
      </span>
    </div>
  </div>
</div>
