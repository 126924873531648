import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { ProgressSpinnerService } from 'src/app/shared/services/progress-spinner.service';

const SKIP_PROGRESS_SPINNER = 'SkipProgressSpinner';

@Injectable()
export class ProgressSpinnerInterceptor implements HttpInterceptor {
  public activeRequests = 0;
  /**
   * URLs for which the loading screen should not be enabled
   */
  public skipWebApiUrls = [];

  constructor(private progressSpinnerService: ProgressSpinnerService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let displayLoadingScreen = true;
    if (request.headers.has(SKIP_PROGRESS_SPINNER) && Boolean(request.headers.get(SKIP_PROGRESS_SPINNER))) {
      displayLoadingScreen = false;
    }

    if (displayLoadingScreen) {
      for (const skipUrl of this.skipWebApiUrls) {
        if (new RegExp(skipUrl).test(request.url)) {
          displayLoadingScreen = false;
          break;
        }
      }
    }

    if (displayLoadingScreen) {
      if (this.activeRequests === 0) {
        this.progressSpinnerService.startLoading();
      }
      this.activeRequests++;

      return next.handle(request).pipe(
        finalize(() => {
          this.activeRequests--;
          if (this.activeRequests === 0) {
            this.progressSpinnerService.stopLoading();
          }
        }),
      );
    } else {
      return next.handle(request);
    }
  }
}
