import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { SignalrService } from './shared/services/signalr.service';
import { CoreModule } from './core/core.module';
import { SpreadsheetModule } from '@progress/kendo-angular-spreadsheet';



@NgModule({
  bootstrap: [AppComponent, MsalRedirectComponent],
  declarations: [AppComponent],
  imports: [AppRoutingModule, BrowserAnimationsModule, BrowserModule, CoreModule, SharedModule, SpreadsheetModule],
  providers: [CookieService, SignalrService],
})
export class AppModule {}
