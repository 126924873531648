import { BaseComponent } from '../base-component';
import { Component, forwardRef, Inject, Injector, INJECTOR, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl, Validators } from '@angular/forms';
import { DataSourceRequestModel, QueryFilter, Sort } from '../../models/request';
import { DefaultSortDirection } from '../../enums/default-sort-direction.enum';
import { DropdownOption } from '../../models/searchable-dropdown/dropdown-option.model';
import { FilterLogicOperator } from '../../enums/filter-logic-operator.enum';
import { FilterOperator } from '../../enums/filter-operator.enum';
import { FilterService } from '../../services/filter.service';
import { ITranslateDefaultValue } from '../../../modules/translations/translate-default-value';
import { ObservableDataSourceService } from '../../temp/observable-data-source-service';
import { debounceTime, Subscription, switchMap, takeUntil, tap } from 'rxjs';
import { translateDefaultValue } from 'src/app/modules/translations/missing-attribute-translation-handler';

@Component({
  selector: 'app-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownListComponent),
      multi: true,
    },
  ],
})
export class DropdownListComponent extends BaseComponent implements OnInit, ControlValueAccessor {
  private initialValue: Partial<any>;
  private requestModel: DataSourceRequestModel<any, DefaultSortDirection> = {
    request: {
      take: 20,
      skip: 0,
    } as {
      take: number;
      skip: number;
      sort: Sort<any, DefaultSortDirection>;
      filter: QueryFilter;
    },
  };

  @Input() public readonly labelProperty: string = 'label';
  @Input() public readonly labelTranslate: string = '';
  @Input() public readonly labelTranslateParam: string = '';
  @Input() public readonly testIdArea: string = 'area-not-provided';
  @Input() public readonly testIdControl: string = 'undefined';
  @Input() public readonly valueProperty: string = 'value';

  public readonly control: FormControl = new FormControl('');
  public readonly dataSourceFields: typeof OptionalDataSourceFields = OptionalDataSourceFields;
  public readonly searchControl: FormControl = new FormControl(null);
  public readonly translateDefaultValue: (defaultValue: string) => ITranslateDefaultValue = translateDefaultValue;

  @Input() public dataSource: ObservableDataSourceService<any>;
  @Input() public filter: QueryFilter = {
    filterComplexes: [],
    logicOperator: FilterLogicOperator.And,
  };
  @Input() public sort: Sort<any, DefaultSortDirection> = {
    sortRules: [
      {
        field: 'Label',
        direction: DefaultSortDirection.Ascending,
      },
    ],
  };

  constructor(private filterService: FilterService, @Inject(INJECTOR) private injector: Injector) {
    super();
  }

  public ngOnInit(): void {
    if (this.dataSource === null) {
      throw new Error('DataSource has to be provided.');
    }

    //We need to keep saved value somewhere, otherwise field will be empty if selected option is not in the search results
    const subscription: Subscription = this.dataSource.dataSource$.subscribe(data => {
      if (data.length == 1) {
        this.initialValue = data[0];
      } else if (data.length > 1) {
        subscription.unsubscribe();
      }
    });

    this.requestModel.request.filter = this.filter;
    this.requestModel.request.sort = this.sort;

    this.searchControl.valueChanges
      .pipe(
        debounceTime(300),
        tap((text: string) => {
          this.requestModel.request.skip = 0;
          this.filterService.switchOrSaveSingleRuleValue(this.requestModel.request.filter, 'Name', [text], FilterOperator.Contains);
        }),
        switchMap(() => this.dataSource.load(this.requestModel)),
        tap((data: Partial<DropdownOption>[]) => {
          if (this.initialValue && data.findIndex((option: Partial<DropdownOption>) => option.value === this.initialValue.value) < 0) {
            data.unshift(this.initialValue);
          }
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  public get required(): boolean {
    return this.injector.get(NgControl).control?.hasValidator(Validators.required);
  }

  public onOpened(): void {
    this.searchControl.setValue('');
  }

  public registerOnChange(callback: (data: number) => void): void {
    this.control.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selected: number) => {
      this.initialValue = this.dataSource.value.find((option: DropdownOption) => option.value === selected);
      callback(selected);
    });
  }

  public registerOnTouched(callback: (data: any) => void): void {
    this.control.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => callback(this.control.touched));
  }

  public setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable({ emitEvent: false });
      this.initialValue = null;
      this.dataSource.update([]);
      this.control.reset();
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  public writeValue(id: number): void {
    this.control.setValue(id);
  }
}

export enum OptionalDataSourceFields {
  TotalRecordCount = 'totalCount',
  GrayedOut = 'grayedOut',
}
