import { Component } from '@angular/core';
import { BaseComponent } from '../base-component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogDismissalReason } from 'src/app/config/constant';

@Component({
  selector: 'app-confirm-delete-modal',
  templateUrl: './confirm-delete-modal.component.html',
  styleUrls: ['./confirm-delete-modal.component.scss'],
})
export class ConfirmDeleteModalComponent extends BaseComponent {
  constructor(private activeModal: NgbActiveModal) {
    super();
  }

  public confirm() {
    this.activeModal.dismiss(DialogDismissalReason.confirm);
  }

  public cancel() {
    this.activeModal.dismiss(DialogDismissalReason.cancel);
  }
}
