import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Params } from '@angular/router';

export interface IButtonLink {
  queryParams?: Params | null;
  queryParamsHandling?: 'merge' | 'preserve' | '' | null;
  routerLink: any[] | string | null | undefined;
}

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent {
  @Input() public backButtonLink: IButtonLink = { queryParams: null, routerLink: null, queryParamsHandling: '' };
  @Input() public isBackButtonVisible: boolean = false;
  @Input() public title: string;
  @Input() public useRouterLink: boolean = false;
  @Output() public backButtonClick = new EventEmitter<any>();

  public onBackButtonClicked($event: any) {
    this.backButtonClick.emit($event);
  }
}
