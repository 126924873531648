import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ValidatorFn } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { BaseComponent } from '../base-component';
import { CustomAttributesOptionModel } from '../../models/custom-attribute';

type OnChangeFn<T> = (value: T) => void;
type OnTouchFn = () => void;

@Component({
  selector: 'app-combobox-input',
  templateUrl: './combobox-input.component.html',
  styleUrls: ['./combobox-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ComboboxInputComponent,
      multi: true,
    },
  ],
})
export class ComboboxInputComponent extends BaseComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() public label: string;
  @Input() public validators: ValidatorFn;
  @Input() public options: CustomAttributesOptionModel[];
  @Input() public required: boolean;

  public filteredOptions$: Observable<CustomAttributesOptionModel[]>;
  public formControl: FormControl = new FormControl(null);

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  public filter = (search: String): CustomAttributesOptionModel[] => {
    if (!search) {
      return this.options;
    }
    return this.options?.filter((x: CustomAttributesOptionModel) => x.value && x.value.toLowerCase().includes(search.toLowerCase()));
  };

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['required']) {
      this.cdRef.detectChanges();
    }
  }

  public ngOnInit() {
    this.filteredOptions$ = this.formControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value)),
    );

    if (this.validators) {
      this.formControl.addValidators(this.validators);
    }
  }

  public registerOnChange(fn: OnChangeFn<number>): void {
    this.formControl.valueChanges.subscribe((valueChange: string) => {
      fn(this.formControl.value);
    });
  }

  public registerOnTouched(fn: OnTouchFn): void {
    void fn;
  }

  public setDisabledState(disabled: boolean): void {
    disabled ? this.formControl.disable({ emitEvent: false }) : this.formControl.enable();
  }

  public trackById(item: any) {
    return item.id;
  }

  public writeValue(obj: string): void {
    this.formControl.setValue(obj);
  }
}
