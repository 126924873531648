export enum ReferencedTableName {
  DataSource = 'DataSource',
  Engagement = 'Engagement',
  GeographicalUnit = 'GeographicalUnit',
  OrganizationalUnit = 'OrganizationalUnit',
  ScopeProfile = 'EngagementLocationAndOrganization',
  ServiceInstance = 'ServiceInstance',
  ServiceOffering = 'ServiceOffering',
  ServiceSubscription = 'ServiceSubscription',
  ServiceType = 'ServiceType',
  SourceDataItemVariant = 'SourceDataItemVariant',
  ServiceSubscriptionItem = 'ServiceSubscriptionItem',
}
