import { NgControl } from '@angular/forms';
import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appInputPrefix]',
})
export class InputPrefixDirective {
  @Input() public appInputPrefix: string;

  constructor(private ngControl: NgControl) {}

  @HostListener('blur')
  public onBlur() {
    if (this.ngControl.control.value === this.appInputPrefix) {
      this.ngControl.control.setValue('');
    }
  }

  @HostListener('focus')
  public onFocus() {
    if (!this.ngControl.control.value) {
      this.ngControl.control.setValue(this.appInputPrefix);
    }
  }

  @HostListener('input', ['$event'])
  public onInput(event) {
    if (!event.target.value.startsWith(this.appInputPrefix)) {
      event.target.value = this.appInputPrefix + event.target.value.replace(/[A-Za-z]/g, '');
      this.ngControl.control.setValue(event.target.value);
    }
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(event) {
    return event.keyCode === 8 || RegExp('^[0-9]*$').test(event.key);
  }
}
