import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { DropdownOption } from '../../models/searchable-dropdown/dropdown-option.model';
import { iif, Observable, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-searchable-dropdown',
  templateUrl: './searchable-dropdown.component.html',
  styleUrls: ['./searchable-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchableDropdownComponent),
      multi: true,
    },
  ],
})
export class SearchableDropdownComponent implements ControlValueAccessor, AfterViewInit, OnChanges {
  private readonly optionCountThreshold: number = 7;

  private initialValue: any;

  public readonly formControl: FormControl = new FormControl<number>(null);
  public readonly options$: Observable<DropdownOption[]>;
  public readonly searchControl: FormControl = new FormControl<string>('');

  @Input() public dropdownOptions: DropdownOption[] = [];
  @Input() public isClearButtonVisible: boolean;
  @Input() public label: string;
  @Input() public required: boolean = false;
  @Input() public testIdArea: string = 'area-not-provided';
  @Input() public testIdControlName: string = 'control-name-not-provided';

  public get thresholdReached(): boolean {
    return this.dropdownOptions?.length >= this.optionCountThreshold;
  }

  constructor(private cdRef: ChangeDetectorRef) {
    this.options$ = this.searchControl.valueChanges.pipe(
      switchMap((searchText: string) =>
        iif(
          () => !!searchText.length,
          of(this.dropdownOptions.filter((option: DropdownOption) => option.title.toLowerCase().includes(searchText.toLowerCase()))),
          of(this.dropdownOptions),
        ),
      ),
    );
  }

  public ngAfterViewInit(): void {
    if (this.required) {
      this.formControl.addValidators(Validators.required);
    }
    this.searchControl.setValue('');
    this.cdRef.detectChanges();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.initialValue = this.formControl?.getRawValue() ?? null;
    if (
      changes.dropdownOptions?.previousValue?.map((option: DropdownOption) => option.value).toString() !==
      changes.dropdownOptions?.currentValue.map((option: DropdownOption) => option.value).toString()
    ) {
      this.dropdownOptions = changes.dropdownOptions.currentValue;
      this.formControl.setValue(this.initialValue);
      this.searchControl.setValue('');
    }
  }

  public registerOnChange = (fn: any): void => {
    this.formControl.valueChanges.subscribe(fn);
  };

  public registerOnTouched = (fn: any): void => {
    void fn;
  };

  public setDisabledState = (isDisabled: boolean): void => {
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  };

  public trackById(option: number): number {
    return option;
  }

  public writeValue(obj: any): void {
    obj ? this.formControl.setValue(obj) : this.formControl.reset();
  }
}
