import { Pipe, PipeTransform } from '@angular/core';
import { CommonFilter } from 'src/app/shared/models/filter/common-filter.model';

@Pipe({
  name: 'filterCountTitle',
  pure: false,
})
export class FilterCountTitlePipe implements PipeTransform {
  public transform(filters: Array<CommonFilter>): string {
    if (filters) {
      const selectedFiltersCount = filters.filter((filter: CommonFilter) => filter.isSelected).length;

      if (selectedFiltersCount > 0) {
        return `(${selectedFiltersCount})`;
      }
    }
  }
}
