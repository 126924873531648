// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  heroImage: 'landingWindow-min.png',
  heroText: 'Welcome to DCMS',
  config: {
    tenant: '5b973f99-77df-4beb-b27d-aa0c70b8482c',
    clientId: '426e4eaf-4a87-408f-be11-58e2b299bca1',
    redirectUri: window.location.origin + '/',
    endpoints: {
      'http://localhost:4200/': '5b973f99-77df-4beb-b27d-aa0c70b8482c',
    },
    navigateToLoginRequestUrl: false,
    cacheLocation: 'localStorage',
  },
  key: {
    BASE_API_URL: 'https://dev-dcms.ey.com/api/',
    MICROSERVICE_API_URL: 'https://dev-dcms.ey.com/api/',
    OCP_API_SUBSCRIPTION_KEY: '8611767efde34743b5149e58d00efd07',
  },
  expiryPopupSetting: { sessionTimout: 120, sessionCutOffTime: 1680, pingTime: 600, showExpiryPopup: true },
  paanSetting: {
    appId: '24C3B59F-01D0-44B1-9325-FDDA3D58F350',
    secret: '4273F6A3-493F-46AA-8755-AC5B9645402F',
    policyType: 1,
    region: 'Global',
    language: 'EN',
    includeContent: false,
    getContent: false,
    acceptRedirectUrl: window.location.origin,
    rejectRedirectUrl: window.location.origin,
    baseUrl: 'https://paans-ui-uat.ey.com',
    checkPrivacyAcceptanceUrl: 'https://paans-ui-uat.ey.com/api/v1/policy/check',
    getPrivacyNoticeUrl: 'https://paans-ui-uat.ey.com/viewnotice?a=24C3B59F-01D0-44B1-9325-FDDA3D58F350&r=Global&l=EN&pt=1',
  },
  insights: {
    instrumentationKey: 'c6ea00da-0c15-4298-8fdf-93fb1d4a30fe',
  },
  sessionSetting: {
    idleTimeoutInMinutes: 30, //Session Timeout in case of Inactivity (In Minutes)
    extendSessionPopupBeforeTimeoutInMinutes: 5, //Extend Session Popup option before Inactivity Timeout (In Minutes)
    maxSessionDurationInHours: 10, //Maximum Session Duration irrespective of user activity (In Hours)
    checkIdleIntervalInSeconds: 2, //Polling to check for Inactivity timeout (In Seconds)
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
