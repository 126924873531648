export class DataSourceAndSinkModel {
  createdBy?: string;
  createdOn?: string;
  geoUnitId?: number;
  modifiedBy?: String;
  modifiedOn?: string;
  public id: number;
  public isActive: boolean;
  public orgUnitId: number;
  sinkName?: string;
  systemCode?: string;
  systemName?: string;
  systemTypeId?: number;
  statusID?: number;
}
