<div [class.toggle--disabled]="toggleControl.disabled">
  <p class="toggle__title">{{ title }}</p>

  <div class="toggle__container">
    <span
      [class.toggle__label--active]="!toggleControl.value"
      class="toggle__label dcms-mat-caption"
    >
      {{ leftLabel }}
    </span>
    <mat-slide-toggle
      [formControl]="toggleControl"
      data-testid="custom-fields-modal-mandatory-input"
    >
      <span
        [class.toggle__label--active]="toggleControl.value"
        class="toggle__label dcms-mat-caption"
      >
        {{ rightLabel }}
      </span>
    </mat-slide-toggle>
  </div>
</div>
