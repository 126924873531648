<div class="modal-header search-list-dialog-modal">
  <h4
    class="modal-title"
    id="modal-basic-title"
    >{{ title }}</h4
  >
  <button
    data-testid="select-list-dialog-close-button"
    (click)="close()"
    aria-label="Close"
    class="close"
    id="close-button"
    type="button"
  >
    <i class="material-icons close-icon">close</i>
  </button>
</div>
<div class="modal-body">
  <div class="col-12 controls-container">
    <app-search-item-list
      [list]="list"
      [disabled]="disabled"
      [descriptionIcon]="descriptionIcon"
      [searchPlaceHolder]="searchPlaceHolder"
      [keyList]="keyList"
      [keyId]="keyId"
      [removeable]="removeable"
      [selection]="true"
      [filterList]="filterList"
      [filterPlaceholder]="filterPlaceholder"
      [filterByField]="filterByField"
    ></app-search-item-list>
  </div>
</div>
<div class="modal-footer">
  <button
    data-testid="select-list-dialog-add-button"
    (click)="add()"
    color="accent"
    mat-flat-button
    type="button"
    [disabled]="searchItemList ? searchItemList.selectList.selection.selectedOptions.selected.length === 0 : true"
    >Add
  </button>
  <button
    data-testid="select-list-dialog-cancel-button"
    (click)="close()"
    color="primary"
    mat-flat-button
    type="button"
    >Cancel
  </button>
</div>
