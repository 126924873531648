import { ChangeRequest } from '../../../shared/models/request';
import { CustomAttributeModel } from '../../../shared/models/custom-attribute';
import { PeriodicityListModel } from 'src/app/modules/portal/subscription/interfaces/periodicity-list.interface';
import { EntityStatusModel } from 'src/app/shared/models/entity-status.model';

export interface ServiceSubscriptionRequestModel {
  accountingPeriodID?: number;
  crDetails?: ChangeRequest;
  changeRequestID?: string;
  createdBy?: string;
  createdOn?: string;
  customAttributes?: Array<CustomAttributeModel>;
  dueDate?: string;
  plannedCompletionDate?: string;
  engagementID?: number;
  engagementName?: string;
  geoUnitID?: number;
  geoUnitName?: string;
  orgUnitHierarchy?: number;
  id?: any;
  isActive?: boolean;
  modifiedBy?: string;
  modifiedOn?: string;
  name?: string;
  organizationalUnitID?: number;
  organizationalUnitName?: string;
  periodicityID?: PeriodicityListModel;
  reportingCalendarTypeID?: number;
  reportingCalendarTypeName?: string;
  serviceAgreementItemID?: string;
  serviceAgreementTypeID?: string;
  serviceOfferingID?: number;
  serviceOfferingName?: string;
  serviceSubscriptionID?: string;
  serviceTypeID?: number;
  serviceTypeName?: string;
  sourceDataItemName?: string;
  sourceDataItemVariantID?: number;
  subscriptionEndDate?: string;
  subscriptionStartDate?: string;
  subscriptionTypeID?: string;
  status?: EntityStatusModel;
  workProductName?: string;
  workProductID?: number;
}
