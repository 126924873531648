<mat-selection-list
  class="selection-item-list__list"
  #selection
>
  <div class="results"> Showing {{ (list | filterList : searchValue : keyList).length }} of {{ list?.length }} results </div>
  <mat-list-option
    *ngIf="list?.length > 0"
    [disabled]="disabled"
    checkboxPosition="before"
    (click)="onClickAll()"
    #all
  >
    <div class="selection-item-list__row">
      <div class="selection-item-list__item">
        <p matLine> Select All </p>
      </div>
    </div>
  </mat-list-option>
  <mat-list-option
    *ngFor="let item of list | filterList : searchValue : keyList"
    [disabled]="disabled"
    checkboxPosition="before"
    (click)="onClickOption(listItem)"
    [value]="item"
    #listItem
  >
    <div class="selection-item-list__row">
      <div class="selection-item-list__item">
        <p matLine> {{ item[keyList[0]] }} </p>
        <div
          *ngIf="item[keyList[1]]"
          class="selection-item-list__item__subline"
        >
          <div
            *ngIf="showStatus"
            class="selection-item-list__item__subline__status"
          >
            <mat-icon
              class="selection-item-list__icon"
              [ngClass]="'status__' + (item.status?.name ?? defaultStatusName | lowercase)"
              [class.icon-disabled]="disabled"
            >
              {{ item.status?.name === activeInactiveStatus.Inactive ? 'cancel' : 'check_circle' }}
            </mat-icon>
            <span
              [innerHtml]="item.status?.name ?? defaultStatusName"
              matLine
            ></span>
          </div>
          <div
            *ngFor="let key of keyList | slice : 1; let i = index"
            class="selection-item-list__item__subline__value"
          >
            <mat-icon
              class="selection-item-list__icon"
              [class.icon-disabled]="disabled"
            >
              {{ descriptionIcon[i] }}
            </mat-icon>
            <span
              [innerHtml]="item[key]"
              matLine
            ></span>
          </div>
        </div>
      </div>
    </div>
  </mat-list-option>
</mat-selection-list>
