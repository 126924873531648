import { ErrorHandler, Injectable } from '@angular/core';
import { ErrorMessageService } from './error-message.service';
import { ErrorModel } from '../models/error-model';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { LoginStatusService } from './login-status.service';
import { MonitoringService } from './logging.service';
import { Router } from '@angular/router';
import { ToastService } from './toast.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private monitoringService: MonitoringService,
    private toastService: ToastService,
    private loginStatusService: LoginStatusService,
    private router: Router,
    private errorMessageService: ErrorMessageService,
  ) {}

  handleError(error: Error): void {
    if (!environment.production) {
      console.log(error);
    }

    this.monitoringService.logException(error);

    if (error instanceof HttpErrorResponse) {
      if (error.status === HttpStatusCode.Unauthorized && this.router.url !== '/') {
        this.loginStatusService.logoutUserWithoutSessionClean();
        void this.router.navigate(['/logout']);
      } else {
        const message: ErrorModel = this.errorMessageService.getServerErrorMessage(error);
        this.toastService.showError(message);
      }
    } else if (this.router.url !== '/') {
      const message: string = this.errorMessageService.getClientErrorMessage(error);
      this.toastService.showError(message);
    }
  }
}
