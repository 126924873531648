import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { ITranslateDefaultValue } from './translate-default-value';

export class MissingAttributeTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return params.interpolateParams?.['defaultValue'] || params.key;
  }
}

export const translateDefaultValue = (defaultValue: string): ITranslateDefaultValue => {
  return { defaultValue };
};
