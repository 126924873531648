import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BaseComponent } from '../base-component';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent extends BaseComponent {
  @Input() public modal: any;
  @Output() deleteCurrentItem = new EventEmitter<any>();

  constructor() {
    super();
  }

  public deleteItem() {
    this.deleteCurrentItem.emit();
    this.modal.dismiss('Cross click');
  }
}
