import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {MatChipInputEvent} from '@angular/material/chips';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-chips-input',
  templateUrl: './chips-input.component.html',
  styleUrls: ['./chips-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ChipsInputComponent,
      multi: true
    }
  ]
})

export class ChipsInputComponent implements ControlValueAccessor, OnChanges {
  @Input() public label: string;
  public formControl: FormControl = new FormControl(null);
  @Input() public required: boolean = false;

  onChange: OnChangeFn<string> = () => {};
  onTouched: OnTouchFn = () => {};

  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  addOnBlur = true;
  chipsArray: Chip[] = [];

  constructor(private cdRef:ChangeDetectorRef) { }

  private createChips(separatedValues: string) {
      if (separatedValues?.length) {
        return separatedValues.split(',').map(chip => ({ name: chip }));
      }

      return [];
  }

  private onUpdate() {
    this.onChange(this.chipsToSeparatedString());
    this.onTouched()
  }

  public add(event: MatChipInputEvent): void {
    let value = (event.value || '').trim();

    value = value.replace(new RegExp(',', 'g'), '');

    if (value) {
      this.chipsArray.push({name: value});
      this.onUpdate();
    }

    event.chipInput!.clear();
  }

  public chipsToSeparatedString(): string {
    return this.chipsArray.map(chip => chip.name).join(',');
  }

  public registerOnChange(fn: OnChangeFn<string>): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: OnTouchFn): void {
    this.onTouched = fn;
  }

  public remove(Chip: Chip): void {
    const index = this.chipsArray.indexOf(Chip);

    if (index >= 0) {
      this.chipsArray.splice(index, 1);
      this.onUpdate();
    }
  }

  public setDisabledState(disabled: boolean): void {
    disabled ? this.formControl.disable({ emitEvent: false }) : this.formControl.enable();
  }

  public writeValue(separatedValues: string): void {
    this.formControl.setValue(separatedValues);
    this.chipsArray = this.createChips(separatedValues);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['required'])
    {
      this.cdRef.detectChanges();
    }
  }
}

type OnChangeFn<T> = (value: T) => void;
type OnTouchFn = () => void;

interface Chip {
  name: string;
}
