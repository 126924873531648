import { Inject, Injectable, Injector } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { ErrorModel } from '../models/error-model';
import { ToasterType } from '../../config/constant';
import { ErrorsContainer } from '../constants/errors-ui-mapper';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private successConfiguration: Partial<IndividualConfig> = {
    enableHtml: true,
    timeOut: 7000,
    closeButton: true,
    tapToDismiss: true,
  };

  private errorConfiguration: Partial<IndividualConfig> = {
    enableHtml: true,
    closeButton: true,
    tapToDismiss: false,
    disableTimeOut: true,
  };

  constructor(@Inject(Injector) private readonly injector: Injector) {}

  private get toastrService() {
    return this.injector.get(ToastrService);
  }

  public showError(err: ErrorModel | string) {
    this.errorConfiguration.payload = {
      type: ToasterType.error,
    };

    if (this.toastrService.findDuplicate(ErrorsContainer.errGeneric.message, '', false, true)) {
      return;
    }

    return this.toastrService.error(this.getMsg(err), this.getTitle(err), this.errorConfiguration);
  }

  public showSuccess(err: ErrorModel | string) {
    this.successConfiguration.payload = {
      type: ToasterType.success,
    };
    return this.toastrService.success(this.getMsg(err), this.getTitle(err), this.successConfiguration);
  }

  public showWarning(err: ErrorModel | string) {
    this.successConfiguration.payload = {
      type: ToasterType.warning,
    };
    return this.toastrService.warning(this.getMsg(err), this.getTitle(err), this.successConfiguration);
  }

  private getTitle(err: any) {
    return typeof err == 'object' ? err.message || ErrorsContainer.errGeneric.message : err;
  }

  private getMsg(err: any) {
    let e = typeof err == 'object' ? err.description.join(err.separator) : err;
    return e != err ? e : '';
  }
}
